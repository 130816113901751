export const Designations = [
    { value: 'chairman', label: 'Chairman' },
    { value: 'ceo', label: 'CEO' },
    { value: 'technical-lead', label: 'Technical Lead' },
    { value: 'business-analyst', label: 'Business Analyst' },
    { value: 'qa-engineer', label: 'QA Engineer' },
    { value: 'software-engineer', label: 'Software Engineer' },
    { value: 'ui-engineer', label: 'UI Engineer' },
    { value: 'ui-designer', label: 'UI Designer' },
    { value: 'devops', label: 'DevOps' },
    { value: 'intern', label: 'Intern' },
    { value: 'system-admin', label: 'System Admin' },
    { value: 'client', label: 'Client' },
];