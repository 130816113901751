import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import Header from '../../../layouts/Header';
import upload from './../../../assets/icons/logo-upload.svg';
import FormDropdown from '../../common/form/FormDropdown';
import FloatingTimer from '../../common/FloatingTimer';

const AddProject = () => {
    const hiddenFileInput = useRef(null);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [clientList, setClientList] = useState([]);
    const [image, setImage] = useState([]);
    const [selectedImage, setSelectedImage] = useState();
    const [errorList, setError] = useState([]);
    const [pending, setPending] = useState(false);
    const [dateRange, setDateRange] = useState([]);
    const [projectManagers, setProjectManagers] = useState([]);
    const [qaManagers, setQaManagers] = useState([]);
    const [projectStatuses, setProjectStatuses] = useState([]);
    const [projectPriorities, setProjectPriorities] = useState([]);
    const [projectSupportPlans, setProjectSupportPlans] = useState([]);
    const [projectPhases, setProjectPhases] = useState([]);
    const [projectCategories, setProjectCategories] = useState([]);
    const [projectInput, setProject] = useState({
        client_id: '',
        name: '',
        description: '',
        staging_url: '',
        production_url: '',
        spec_url: '',
        design_url: '',
        max_hours_limit: '',
        phases: '',
        project_manager_id: '',
        qa_manager_id: '',
        project_status_id: '',
        project_status_reason: '',
        priority_id: '',
        categories: '',
        start_date: '',
        deadline: '',
        support_plan_id: ''
    });
    const [selectedCategories, setSelectedCategories] = useState({
        categories: [],
    });
    const [selectedPhases, setSelectedPhases] = useState({
        phases: [],
    });

    const setPageData = useCallback(async () => {
        setLoading(true);
        let isMounted = true;
        if (isMounted) {
            let endpoints = [
                `/api/all-clients`,
                `/api/all-users`,
                `/api/all-qa-users`,
                `/api/all-project-statuses`,
                `/api/all-project-priorities`,
                `/api/all-project-support-plans`,
                `/api/all-project-phases`,
                `/api/all-project-categories`
            ];
            Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
            .then(([
                {data: allClients},
                {data: allUsers}, 
                {data: allQaUsers}, 
                {data: projectStatuses}, 
                {data: projectPriorities}, 
                {data: projectSupportPlans}, 
                {data: projectPhases}, 
                {data: projectCategories}
            ]) => {
                if (allClients.status === 200) {
                    setClientList(allClients.clients);
                }
                if (allUsers.status === 200) {
                    setProjectManagers(allUsers.users);
                }
                if (allQaUsers.status === 200) {
                    setQaManagers(allQaUsers.users);
                }
                if (projectStatuses.status === 200) {
                    setProjectStatuses(projectStatuses.projectStatuses);
                }
                if (projectPriorities.status === 200) {
                    setProjectPriorities(projectPriorities.projectPriorities);
                }
                if (projectSupportPlans.status === 200) {
                    setProjectSupportPlans(projectSupportPlans.projectSupportPlans);
                }
                if (projectPhases.status === 200) {
                    setProjectPhases(projectPhases.projectPhases);
                }
                if (projectCategories.status === 200) {
                    setProjectCategories(projectCategories.projectCategories);
                }
                setLoading(false);
            });
        }
        return () => {
            isMounted = false
        }
    }, []);

    useEffect(() => {
        setPageData();
    }, [setPageData]);

    const resetForm = () => {
        setProject({
            client_id: '',
            name: '',
            description: '',
            staging_url: '',
            production_url: '',
            spec_url: '',
            design_url: '',
            max_hours_limit: '',
            phases: '',
            project_manager_id: '',
            qa_manager_id: '',
            project_status_id: '',
            project_status_reason: '',
            priority_id: '',
            categories: '',
            start_date: '',
            deadline: '',
            support_plan_id: ''
        });
        setSelectedCategories({
            categories: []
        });
        setSelectedPhases({
            phases: []
        });
        setImage([]);
    }

    const handleInput = (e) => {
        setProject({ ...projectInput, [e.target.name]: e.target.value });
    }

    const handleLogo = (e) => {
        setImage({ logo: e.target.files[0] });
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0]);
        }
    }

    const handleUploadClick = event => {
        hiddenFileInput.current.click();
    };

    const handleCategoriesCheckboxes = (e) => {
        const { value, checked } = e.target;
        const { categories } = selectedCategories;
        if (checked) {
            setSelectedCategories({
                categories: [...categories, value],
            });
        } else {
            setSelectedCategories({
                categories: categories.filter((e) => e !== value),
            });
        }
    };

    const handlePhasesCheckboxes = (e) => {
        const { value, checked } = e.target;
        const { phases } = selectedPhases;
        if (checked) {
            setSelectedPhases({
                phases: [...phases, value],
            });
        } else {
            setSelectedPhases({
                phases: phases.filter((e) => e !== value),
            });
        }
    }

    const handleDropdown = (item, action) => {
        setProject({ ...projectInput, [action.name]: item.value });
    }

    const submitProject = (e) => {
        e.preventDefault();
        setPending(true);

        let maxHoursLimit = projectInput.max_hours_limit ? parseInt(projectInput.max_hours_limit)*60 : 0;
        let startingDate = '';
        let endingDate = '';

        let isValid = true;
        if (dateRange && dateRange.length > 0) {
            let newStartDate = dateRange[0];
            let newEndDate = dateRange[1];
            if (newStartDate !== null && newEndDate !== null) {
                startingDate = `${newStartDate.getFullYear()}-${newStartDate.getMonth()+1}-${newStartDate.getDate()}`;
                endingDate = `${newEndDate.getFullYear()}-${newEndDate.getMonth()+1}-${newEndDate.getDate()}`;
            } else {
                setError({...errorList, project_duration : "Please Select Project Duration"});
                isValid = false;
                setPending(false);
            }
        } else {
            setError({...errorList, project_duration : "Please Select Project Duration"});
            isValid = false;
            setPending(false);
        }

        const formData = new FormData();
        formData.append('client_id', projectInput.client_id);
        formData.append('logo', image.logo);
        formData.append('name', projectInput.name);
        formData.append('description', projectInput.description);
        formData.append('staging_url', projectInput.staging_url);
        formData.append('production_url', projectInput.production_url);
        formData.append('spec_url', projectInput.spec_url);
        formData.append('design_url', projectInput.design_url);
        formData.append('project_manager_id', projectInput.project_manager_id);
        formData.append('qa_manager_id', projectInput.qa_manager_id);
        formData.append('project_status_id', projectInput.project_status_id);
        formData.append('project_status_reason', projectInput.project_status_reason);
        formData.append('priority_id', projectInput.priority_id);
        formData.append('support_plan_id', projectInput.support_plan_id);
        formData.append('phases', selectedPhases.phases);
        formData.append('categories', selectedCategories.categories);
        formData.append('max_hours_limit', maxHoursLimit);
        formData.append('start_date', startingDate);
        formData.append('deadline', endingDate);

        if (isValid) {
            axios.get('/sanctum/csrf-cookie').then(response => {
                axios({
                    method: "post",
                    url: `/api/store-project`,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then(res => {
                    if (res.data.status === 200) {
                        swal({
                            title: "Success",
                            text: res.data.message,
                            icon: "success",
                            className: "success-box",
                            timer: 3000,
                            buttons: false,
                        });
                        resetForm();
                        setError([]);
                        history.push('/all-projects');
                    } else if (res.data.status === 422) {
                        swal({
                            title: "Error",
                            text: "All Fields are mandatory",
                            icon: "error",
                            className: "error-box",
                            timer: 3000,
                            buttons: false,
                        });
                        setError(res.data.errors);
                    }
                });
                setPending(false);
            });
        }
    }

    const getDropdownOptions = (dataList) => {
        return dataList.map(item => ({
            value: item.id,
            label: item.name
        }));
    }

    return (
        <div className={`${loading ? 'page-loading' : ''}`}>
            <Header
                title="Add a new Project"
                type="variation-1"
            />
            <div className='main-content add-project'>
                <div className="form-wrp">
                    <form encType='multipart/form-data' onSubmit={submitProject} id="PROJECT_FORM">
                        <div className='form-inner-wrp'>
                            <div className='form-content-wrp'>
                                <div className='form-left-col'>
                                    <div className='form-group'>
                                        <label htmlFor="logo" className="form-label">Logo</label>
                                        <div className={`logo-wrp ${selectedImage ? 'project-logo-wrp': ''}`} onClick={handleUploadClick}>
                                            <img
                                                src={selectedImage ? URL.createObjectURL(selectedImage) : upload}
                                                className={selectedImage ? 'project-logo': ''}
                                                alt=""
                                            />
                                        </div>
                                        <input type='file' name='logo' ref={hiddenFileInput} onChange={handleLogo} style={{display:'none'}} className='form-control' id='logo' />
                                        <small className='text-danger'>{errorList.logo}</small>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor="project_duration" className="form-label">Project Duration</label>
                                        <DateRangePicker 
                                            onChange={setDateRange} 
                                            value={dateRange} 
                                            format='yy-MM-dd'
                                            yearPlaceholder='yy'
                                            monthPlaceholder='mm'
                                            dayPlaceholder='dd'
                                            className='my-calender'
                                            id='project_duration'
                                        />
                                        <small className='text-danger'>{errorList.project_duration}</small>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor="max_hours_limit" className="form-label">Max hours Cap</label>
                                        <div className='max-hour-input-wrp'>
                                            <input type="number" name="max_hours_limit" onChange={handleInput} value={projectInput.max_hours_limit} min="0" max="9999" step="1" id='max_hours_limit' className="form-control" aria-label=""/>
                                        </div>
                                        <small className='text-danger'>{errorList.max_hours_limit}</small>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor="support_plan_id" className="form-label">Support Plan</label>
                                        <FormDropdown
                                            value={projectInput.support_plan_id}
                                            name="support_plan_id"
                                            id="support_plan_id"
                                            options={getDropdownOptions(projectSupportPlans)}
                                            isSearchable={true}
                                            isDisabled={pending}
                                            onChange={handleDropdown}
                                            placeholder="Support Plan"
                                        />
                                        <small className='text-danger'>{errorList.support_plan_id}</small>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor="project_manager_id" className="form-label">Project Manager</label>
                                        <FormDropdown
                                            value={projectInput.project_manager_id}
                                            name="project_manager_id"
                                            id="project_manager_id"
                                            options={getDropdownOptions(projectManagers)}
                                            isSearchable={true}
                                            isDisabled={pending}
                                            onChange={handleDropdown}
                                            placeholder="Project Manager"
                                        />
                                        <small className='text-danger'>{errorList.project_manager_id}</small>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor="qa_manager_id" className="form-label">QA Manager</label>
                                        <FormDropdown
                                            value={projectInput.qa_manager_id}
                                            name="qa_manager_id"
                                            id="qa_manager_id"
                                            options={getDropdownOptions(qaManagers)}
                                            isSearchable={true}
                                            isDisabled={pending}
                                            onChange={handleDropdown}
                                            placeholder="QA Manager"
                                        />
                                        <small className='text-danger'>{errorList.qa_manager_id}</small>
                                    </div>
                                </div>
                                <div className='form-right-col'>
                                    <div className='title-wrp'>
                                        <h2>Project Details</h2>
                                        <p>General Information about the project</p>
                                    </div>
                                    <div className='project-detail-row pd-first'>
                                        <div className="form-group col col-4">
                                            <div className='form-group-wrp'>
                                                <div className="form-group">
                                                    <label htmlFor="name" className="form-label">Project Name</label>
                                                    <input type="text" name="name" onBlur={handleInput} defaultValue={projectInput.name} className='form-control' id='name' />
                                                    <small className='text-danger'>{errorList.name}</small>
                                                </div>
                                                <div className='form-group'>
                                                    <FormDropdown
                                                        value={projectInput.client_id}
                                                        name="client_id"
                                                        id="client_id"
                                                        options={getDropdownOptions(clientList)}
                                                        isSearchable={true}
                                                        isDisabled={pending}
                                                        onChange={handleDropdown}
                                                        placeholder="Select a Client"
                                                    />
                                                    <small className='text-danger'>{errorList.client_id}</small>
                                                </div>
                                                <div className='form-group'>
                                                    <FormDropdown
                                                        value={projectInput.project_status_id}
                                                        name="project_status_id"
                                                        id="project_status_id"
                                                        options={getDropdownOptions(projectStatuses)}
                                                        isSearchable={true}
                                                        isDisabled={pending}
                                                        onChange={handleDropdown}
                                                        placeholder="Project Status"
                                                    />
                                                    <small className='text-danger'>{errorList.project_status_id}</small>
                                                </div>
                                                <div className='form-group'>
                                                    <FormDropdown
                                                        value={projectInput.priority_id}
                                                        name="priority_id"
                                                        id="priority_id"
                                                        options={getDropdownOptions(projectPriorities)}
                                                        isSearchable={true}
                                                        isDisabled={pending}
                                                        onChange={handleDropdown}
                                                        placeholder="Project Priority"
                                                    />
                                                    <small className='text-danger'>{errorList.priority_id}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group col col-8 description-col'>
                                            <div className='form-group'>
                                                <label htmlFor="description" className="form-label">Brief Description about the Project</label>
                                                <textarea name="description" onChange={handleInput} value={projectInput.description} className='form-control' id='description' style={{ height: "245px" }}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='project-detail-row pd-second m-0'>
                                        <div className="form-group col col- m-0">
                                            <div className='form-row'>
                                                <div className='form-group col col-6 m-0'>
                                                    <label htmlFor="categories" className="form-label">Project Categories</label>
                                                    {
                                                        projectCategories && projectCategories.map(category => 
                                                            <div className="form-check" key={`category-${category.id}`}>
                                                                <input type="checkbox" name="category" onChange={handleCategoriesCheckboxes} value={category.id} className="form-check-input" id={`category-${category.id}`} />
                                                                <label htmlFor={`category-${category.id}`} className="form-check-label">{category.name}</label>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className='form-group col col-6 m-0'>
                                                    <label htmlFor="phases" className="form-label">Project Phases</label>
                                                    {
                                                        projectPhases && projectPhases.map(phase =>
                                                            <div className="form-check" key={`category-${phase.id}`}>
                                                                <input type="checkbox" name="phase" onChange={handlePhasesCheckboxes} value={phase.id} className="form-check-input" id={`phase-${phase.id}`} />
                                                                <label htmlFor={`phase-${phase.id}`} className="form-check-label">{phase.name}</label>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col col-7 p-0">
                                            <div className="form-group">
                                                <div className='input-label-left-variation'>
                                                    <label htmlFor="staging_url" className="form-label">Staging URL</label>
                                                    <input type="text" name="staging_url" onBlur={handleInput} defaultValue={projectInput.staging_url} className='form-control' id='staging_url' />
                                                    <small className='text-danger'>{errorList.staging_url}</small>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='input-label-left-variation'>
                                                    <label htmlFor="production_url" className="form-label">Production URL</label>
                                                    <input type="text" name="production_url" onBlur={handleInput} defaultValue={projectInput.production_url} className='form-control' id='production_url' />
                                                    <small className='text-danger'>{errorList.production_url}</small>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='input-label-left-variation'>
                                                    <label htmlFor="spec_url" className="form-label">Spec URL</label>
                                                    <input type="text" name="spec_url" onBlur={handleInput} defaultValue={projectInput.spec_url} className='form-control' id='spec_url' />
                                                    <small className='text-danger'>{errorList.spec_url}</small>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='input-label-left-variation'>
                                                    <label htmlFor="design_url" className="form-label">Design URL</label>
                                                    <input type="text" name="design_url" onBlur={handleInput} defaultValue={projectInput.design_url} className='form-control' id='design_url' />
                                                    <small className='text-danger'>{errorList.design_url}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='button-wrp'>
                                <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary'>
                                    <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                    Create Project
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <FloatingTimer />
        </div>
    );
}

export default AddProject;