import DateDiff from "date-diff";

export function debounce(fn) {
  let timeout;
  return function (...args) {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      fn(...args);
    }, 300);
  };
}

export function getTimeDifferenceLabel(date) {
  let displayTime = '';
  let timeDiff = new DateDiff(new Date(), new Date(date));
  if (timeDiff.years() >= 1) {
    displayTime = `${timeDiff.years().toFixed(0)} years ago`;
  } else if (timeDiff.months() >= 1) {
    displayTime = `${timeDiff.months().toFixed(0)} months ago`;
  } else if (timeDiff.days() >= 1) {
    displayTime = `${timeDiff.days().toFixed(0)} days ago`;
  } else if (timeDiff.weeks() >= 1) {
    displayTime = `${timeDiff.weeks().toFixed(0)} weeks ago`;
  } else if (timeDiff.hours() >= 1) {
    displayTime = `${timeDiff.hours().toFixed(0)} hours ago`;
  } else if (timeDiff.minutes() >= 1) {
    displayTime = `${timeDiff.minutes().toFixed(0)} minutes ago`;
  } else if (timeDiff.seconds() >= 1) {
    displayTime = `${timeDiff.seconds().toFixed(0)} seconds ago`;
  } else {
    displayTime = `just now`;
  }
  return displayTime;
}
