import React, { useEffect, useState, useCallback, useRef } from 'react';
import Select from 'react-select';
import Header from '../../../layouts/Header';
import LeavesCard from "../../common/LeavesCard";
import axios from "axios";

const Leaves = () => {
  const [loading, setLoading] = useState(false);
  const [myLeaves, setMyLeaves] = useState([]);
  const [scrollUpButtonDIsable, setScrollUpButtonDIsable] = useState(true);
  const [scrollDownButtonDIsable, setScrollDownButtonDIsable] = useState(false);
  const horizontalScrollElement = useRef(null);
  const verticalScrollElement = useRef(null);
  const AssigneeId = localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_id`);

  const setPageData = useCallback(async () => {
    setLoading(true);
    let isMounted = true;

    if (isMounted) {
      axios.get(`/api/user-leaves/${AssigneeId}`).then(res => {
        if (res.data.status === 200) {
          setMyLeaves(res.data.userLeaves)
        }
      });
      setLoading(false);
    }
    return () => {
      isMounted = false
    }
  }, [AssigneeId]);

  useEffect(() => {
    setPageData();
  }, [setPageData]);

  const monthName = (mon) => {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon];
  };

  const handleScroll = (event) => {
    if (event.deltaY > 0) {
      handleHorizantalScroll(horizontalScrollElement.current, 25, 100, 10);
    } else {
      handleHorizantalScroll(horizontalScrollElement.current, 25, 100, -10);
    }
  };

  const handleClickDown = () => {
    handleVerticallScroll(verticalScrollElement.current, 25, 100, 10);
  };

  const handleClickUp = () => {
    handleVerticallScroll(verticalScrollElement.current, 25, 100, -10);
  };

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const handleVerticallScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollTop += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }

      if (element.scrollTop === 0) {
        setScrollUpButtonDIsable(true);
      } else {
        setScrollUpButtonDIsable(false);
      }

      if (element.scrollTop + element.offsetHeight + 1 > element.scrollHeight) {
        setScrollDownButtonDIsable(true);
      } else {
        setScrollDownButtonDIsable(false);
      }

    }, speed);
  };

  const colourOptions = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
    { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
  ];

  return (
    <div className={`${loading ? 'page-loading' : ''} h-full`}>
      <Header
        title="Leaves"
        type="variation-2"
        headerButton={true}
        headerButtonType="button"
        headerButtonUrl="/add-client"
        headerButtonTitle="Request a leave"
      />

      <div className="main-content my-leaves-wrp">
        <div className="middle-content">
          <div className="form-wrp top-form-wrp">
            <form>
              <div className="form-row">
                <div className="form-group col">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={colourOptions[0]}
                    isSearchable
                    isClearable
                    isDisabled={false}
                    name="client_id"
                    id="client_id"
                    placeholder="year"
                    options={colourOptions}
                  />
                </div>
                <div className="form-group col">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={colourOptions[1]}
                    isSearchable
                    isClearable
                    isDisabled={false}
                    name="client_id"
                    id="client_id"
                    placeholder="Select Leave Type"
                    options={colourOptions}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="leaves-wrp">
            <div id="leave-container">
              <div className="leaves-inner-wrp" onWheel={handleScroll}  ref={horizontalScrollElement}>
                {
                  myLeaves && myLeaves.map((leave, index) =>
                    <div className='leaves-column' key={index}>
                      { leave.length !== 0 ?
                        <>
                          <div className='leave-month-counter'>
                            <span>{monthName(index)}</span>
                            <span className='counter'>{leave.length}</span>
                          </div>
                          <div className='leave-item-wrp' ref={verticalScrollElement}>
                            {
                              leave.map((singleLeave, index2) =>
                                <LeavesCard
                                  key={index2}
                                  leaveType="Annual Leave"
                                  leaveStatus="Approved"
                                  leaveDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent semper dapibus purus, non porta elit..."
                                  leaveStartDate="12/05/2024"
                                  leaveEndDate="12/06/2024"
                                />
                              )
                            }
                            <button className='scroll-down-button' onClick={handleClickDown} disabled={scrollDownButtonDIsable}>
                              Scroll Down
                            </button>
                            <button className='scroll-up-button' onClick={handleClickUp} disabled={scrollUpButtonDIsable}>
                              Scroll Up
                            </button>
                          </div>
                        </>
                        :
                        <>
                          <div className='leave-month-counter'>
                            <span>{monthName(index)}</span>
                            <span className='counter'>{leave.length}</span>
                          </div>
                          <div className='leave-item-wrp'>
                            <div className="card">
                              <div className="card-header">
                                <div className='leave-title large-para ex-bold'>
                                  No Leaves
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Header
        title="Leaves"
        type="variation-2"
        headerButton={true}
        headerButtonType="button"
        headerButtonUrl="/add-client"
        headerButtonTitle="Request a leave"
      />
      <div className='main-content my-leaves-wrp'>
        <div className='middle-content'>
          <div className="form-wrp top-form-wrp">
            <form>
              <div className="form-row">
                <div className="form-group col">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={colourOptions[0]}
                    isSearchable
                    isClearable
                    isDisabled={false}
                    name="client_id"
                    id="client_id"
                    placeholder="year"
                    options={colourOptions}
                  />
                </div>
                <div className="form-group col">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={colourOptions[1]}
                    isSearchable
                    isClearable
                    isDisabled={false}
                    name="client_id"
                    id="client_id"
                    placeholder="Select Leave Type"
                    options={colourOptions}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className='leaves-wrp'>
            <div  onWheel={handleScroll}  ref={horizontalScrollElement} className='leaves-inner-wrp' id="leave-container">
              <div className='leaves-column'>
                <div className='leave-month-counter'>
                  <span>December</span>
                  <span className='counter'>1</span>
                </div>
                <div className='leave-item-wrp'>
                  <LeavesCard
                    leaveType="Annual Leave"
                    leaveStatus="Approved"
                    leaveDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent semper dapibus purus, non porta elit..."
                    leaveStartDate="12/05/2024"
                    leaveEndDate="12/06/2024"
                  />
                  <LeavesCard
                    leaveType="Annual Leave"
                    leaveStatus="Approved"
                    leaveDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent semper dapibus purus, non porta elit..."
                    leaveStartDate="12/05/2024"
                    leaveEndDate="12/06/2024"
                  />
                </div>
              </div>
              <div className='leaves-column long-content'>
                <div className='leave-month-counter'>
                  <span>December</span>
                  <span className='counter'>1</span>
                </div>
                <div className='leave-item-wrp' ref={verticalScrollElement}>
                  <LeavesCard
                    leaveType="Annual Leave"
                    leaveStatus="Approved"
                    leaveDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent semper dapibus purus, non porta elit..."
                    leaveStartDate="12/05/2024"
                    leaveEndDate="12/06/2024"
                  />
                  <LeavesCard
                    leaveType="Annual Leave"
                    leaveStatus="Approved"
                    leaveDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent semper dapibus purus, non porta elit..."
                    leaveStartDate="12/05/2024"
                    leaveEndDate="12/06/2024"
                  />
                  <LeavesCard
                    leaveType="Annual Leave"
                    leaveStatus="Approved"
                    leaveDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent semper dapibus purus, non porta elit..."
                    leaveStartDate="12/05/2024"
                    leaveEndDate="12/06/2024"
                  />
                  <LeavesCard
                    leaveType="Annual Leave"
                    leaveStatus="Approved"
                    leaveDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent semper dapibus purus, non porta elit..."
                    leaveStartDate="12/05/2024"
                    leaveEndDate="12/06/2024"
                  />
                  <LeavesCard
                    leaveType="Annual Leave"
                    leaveStatus="Approved"
                    leaveDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent semper dapibus purus, non porta elit..."
                    leaveStartDate="12/05/2024"
                    leaveEndDate="12/06/2024"
                  />
                  <button className='scroll-down-button' onClick={handleClickDown} disabled={scrollDownButtonDIsable}>
                    Scroll Down
                  </button>
                  <button className='scroll-up-button' onClick={handleClickUp} disabled={scrollUpButtonDIsable}>
                    Scroll Up
                  </button>
                </div>
              </div>
              <div className='leaves-column'>
                <div className='leave-month-counter'>
                  <span>December</span>
                  <span className='counter'>1</span>
                </div>
                <div className='leave-item-wrp'>
                  <LeavesCard
                    leaveType="Annual Leave"
                    leaveStatus="Approved"
                    leaveDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent semper dapibus purus, non porta elit..."
                    leaveStartDate="12/05/2024"
                    leaveEndDate="12/06/2024"
                  />
                  <LeavesCard
                    leaveType="Annual Leave"
                    leaveStatus="Approved"
                    leaveDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent semper dapibus purus, non porta elit..."
                    leaveStartDate="12/05/2024"
                    leaveEndDate="12/06/2024"
                  />
                </div>
              </div>
              <div className='leaves-column'>
                <div className='leave-month-counter'>
                  <span>December</span>
                  <span className='counter'>1</span>
                </div>
                <div className='leave-item-wrp'>
                  <LeavesCard
                    leaveType="Annual Leave"
                    leaveStatus="Approved"
                    leaveDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent semper dapibus purus, non porta elit..."
                    leaveStartDate="12/05/2024"
                    leaveEndDate="12/06/2024"
                  />
                  <LeavesCard
                    leaveType="Annual Leave"
                    leaveStatus="Approved"
                    leaveDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent semper dapibus purus, non porta elit..."
                    leaveStartDate="12/05/2024"
                    leaveEndDate="12/06/2024"
                  />
                </div>
              </div>
              <div className='leaves-column'>
                <div className='leave-month-counter'>
                  <span>December</span>
                  <span className='counter'>1</span>
                </div>
                <div className='leave-item-wrp'>
                  <LeavesCard
                    leaveType="Annual Leave"
                    leaveStatus="Approved"
                    leaveDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent semper dapibus purus, non porta elit..."
                    leaveStartDate="12/05/2024"
                    leaveEndDate="12/06/2024"
                  />
                  <LeavesCard
                    leaveType="Annual Leave"
                    leaveStatus="Approved"
                    leaveDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent semper dapibus purus, non porta elit..."
                    leaveStartDate="12/05/2024"
                    leaveEndDate="12/06/2024"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Leaves;