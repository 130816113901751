import React, { useState, useRef } from "react";
import parse from 'html-react-parser';
import icon from '../../assets/icons/logo.svg';
import Avatar from '../../components/common/Avatar';
import { getTimeDifferenceLabel } from "../../utils/UtilityFunctions";
import FormWYSIWYG from "./form/FormWYSIWYG";

export default function Comment({ comment, addReply, deleteReply, canReply }) {
  const [replyText, setReplyText] = useState("");
  const [showReplyBox, setShowReplyBox] = useState(false);
  const inputEl = useRef(null);
  const [replyBoxError, setReplyBoxError] = useState("");

  const handleReply = (value) => {
    setReplyText(value);
  }

  const handleSubmitReply = (e) => {
    e.preventDefault();
    if (replyText !== "") {
      addReply(comment.id, replyText);
      setShowReplyBox(false);
      setReplyText("");
      setReplyBoxError("");
    } else {
      setReplyBoxError("This Field is Required");
    }
  }

  const handleCancelReply = (e) => {
    e.preventDefault();
    setShowReplyBox(false);
    setReplyText("");
    setReplyBoxError("");
  }

  const handleShowReply = (e) => {
    e.preventDefault();
    setShowReplyBox(true);
    setTimeout(() => inputEl.current.focus());
  }

  const handleDeleteReply = (e) => {
    e.preventDefault();
    deleteReply(comment.id);
  }

  return (
    <div className="d-flex flex-start pt-2" key={comment.id}>
      <Avatar
        className='avatar-small'
        src={comment.displayImage !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${comment.displayImage}` : icon}
        alt="Bees"
        name={comment.displayName}
      />
      <div className="flex-grow-1 flex-shrink-1">
          <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="mb-2 ms-2">{comment.displayName} <span className="small">- {getTimeDifferenceLabel(comment.displayDate)}</span></div>
                {!showReplyBox && (
                  <div className="row pb-2 comment-buttons-wrp">
                    <div className="d-flex justify-content-end">
                      {canReply && 
                        <button type="button" className='btn btn-secondary p-2 comment-btn' onClick={handleShowReply} title="Reply">
                          <i className="fas fa-reply fa-xs"></i><span className="small"></span>
                        </button>
                      }
                      {(parseInt(localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_id`)) === parseInt(comment.userId)) && comment.children.length === 0 &&
                        <button type="button" className='btn btn-danger p-2 comment-btn delete ms-2' onClick={handleDeleteReply} title="Delete">
                          <i className="fas fa-trash fa-xs"></i><span className="small"></span>
                        </button>
                      }
                    </div>
                  </div>
                )}
              </div>
              <div className="html-desc small mb-0">{parse(comment.displayText)}</div>
          </div>
          {showReplyBox && (
            <>
              <div className="row py-2">
                <div className="d-flex justify-content-between gap-2">
                  <Avatar
                    className='avatar-small'
                    src={localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_image`) !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_image`)}` : icon}
                    alt="Bees"
                    name={localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_name`)}
                  />
                  <div style={{ width: '100%' }}>
                    <FormWYSIWYG
                      ref={inputEl}
                      value=""
                      onChange={handleReply}
                      id={`sub_task_comment_reply_${comment.id}`}
                      name="sub_task_comment_reply"
                    />
                    {replyBoxError && <div><small className='text-danger'>{replyBoxError}</small></div>}
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-end gap-2 comment-buttons-wrp">
                  <button type="button" className='btn btn-primary p-2 comment-btn set-2' onClick={handleSubmitReply}>Save</button>
                  <button type="button" className='btn btn-danger p-2 comment-btn set-2' onClick={handleCancelReply}>Cancel</button>
                </div>
              </div>
            </>
          )}
          {comment.children.length > 0 && canReply && (
            <>
              {comment.children.map((childComment) => (
                <Comment
                  key={childComment.id}
                  comment={childComment}
                  addReply={addReply}
                  deleteReply={deleteReply}
                  canReply={false}
                />
              ))}
            </>
          )}
      </div>
    </div>
  );
}
