import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import logo from './../../../assets/icons/logo.svg';

const Login = () => {
    const history = useHistory();
    const [pending, setPending] = useState(false);
    const [loginInput, setLogin] = useState({
        name: '',
        password: '',
        error_list: []
    });

    const handleInput = (e) => {
        setLogin({ ...loginInput, [e.target.name]: e.target.value });
    }

    const loginSubmit = (e) => {
        e.preventDefault();
        setPending(true);

        const data = {
            email: loginInput.email,
            password: loginInput.password
        }

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/login`, data).then(res => {
                if (res.data.status === 200) {
                    localStorage.setItem(`${process.env.REACT_APP_NAME}_auth_id`, res.data.user_id);
                    localStorage.setItem(`${process.env.REACT_APP_NAME}_auth_token`, res.data.token);
                    localStorage.setItem(`${process.env.REACT_APP_NAME}_auth_name`, res.data.username);
                    localStorage.setItem(`${process.env.REACT_APP_NAME}_auth_role`, res.data.role);
                    localStorage.setItem(`${process.env.REACT_APP_NAME}_auth_designation`, res.data.designation);
                    localStorage.setItem(`${process.env.REACT_APP_NAME}_auth_image`, res.data.profile_picture ? res.data.profile_picture : '');
                    localStorage.setItem(`${process.env.REACT_APP_NAME}_auth_client_logo`, res.data.client_logo ? res.data.client_logo : '');
                    swal({
                      title: "Success",
                      text: res.data.message,
                      icon: "success",
                      className: "success-box",
                      timer: 3000,
                      buttons: false,
                    });
                    setPending(false);
                    history.push('/my-tasks');
                    // if (res.data.role === 'admin') {
                    //     history.push('/admin');
                    // } else if (res.data.role === 'client') {
                    //     history.push('/my-projects');
                    // } else {
                    //     history.push('/');
                    // }
                } else if (res.data.status === 401) {
                    swal("Warning", res.data.message, "warning");
                    setPending(false);
                } else {
                    setLogin({ ...loginInput, error_list: res.data.validation_errors });
                    setPending(false);
                }
            });
        });
    }

    return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className='login-wrp'>
                <div className="logo text-center">
                  <img src={logo} alt="Bees" />
                </div>
                <div className="card overflow-hidden">
                  <div className="card-body">
                    <h2 className="card-title text-center">Let’s get into the hive</h2>
                    <form onSubmit={loginSubmit}>
                      <div className="form-floating">
                        <input type='email' id="floatingInputEmail" placeholder="name@example.com" name='email' onChange={handleInput} value={loginInput.email} className='form-control' />
                        <label htmlFor="floatingInputEmail">Email address</label>
                        {loginInput.error_list.email && <label className='error-message'>{loginInput.error_list.email}</label>}
                      </div>
                      <div className="form-floating">
                        <input id="floatingPassword" placeholder="Password" type='password' name='password' onChange={handleInput} value={loginInput.password} className='form-control' />
                        <label htmlFor="floatingPassword">Password</label>
                        {loginInput.error_list.password && <label className='error-message'>{loginInput.error_list.password}</label>}
                      </div>
                      <div className="btn-wrp">
                        <button disabled = {pending ? 'disabled' : ''} className="btn btn-lg btn-primary btn-login fw-bold text-uppercase" type="submit">
                          <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                          Log In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='reset-link-wrp'>
                  <div className='reset-link text-center'>
                    <Link className="mt-2" to="/register">Don't Have an Account? Register</Link>
                  </div>
                  <div className='reset-link text-center'>
                    <Link className="mt-2" to="/forgot-password">Forgot Password</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}

export default Login;