import React from 'react';
import icon from './../../assets/icons/logo.svg';
import Avatar from '../common/Avatar';

function CardFooter({ userPhoto, userName }) {
  return (
    <div className='card-footer'>
      <div className='po-image-wrp'>
        <Avatar
          src={userPhoto ? `${process.env.REACT_APP_BACKEND_URL}/${userPhoto}` : icon}
          alt={userName}
        />
      </div>
      <div className='po-name'>{ userName }</div>
    </div>
  )
}

export default CardFooter