import React, { useEffect, useState, useCallback } from 'react';

import Modal from "react-modal";
import Header from '../../../layouts/Header';
import ProjectCard from "../../common/ProjectCard";
import axios from "axios";

import EditClient from './editClient';
import FloatingTimer from '../../common/FloatingTimer';


const ViewClient = (props) => {
  const [projects, setProjects] = useState([]);
  const [client, setClient] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectPhases, setProjectPhases] = useState([]);
  const [projectCategories, setProjectCategories] = useState([]);
  const [projectModalIsOpen, setProjectModalIsOpen] = useState(false);
  const clientId = props.match.params.id;

  const customStyles = {
    content: {
      top: "0",
      left: "auto",
      right: "0",
      bottom: "0",
      margin: "0",
      zIndex: "9999",
      border: "none",
      borderRadius: "0",
      padding: "0",
      position: "absolute",
      background: "rgb(255, 255, 255)",
      overflow: "auto",
      outline: "none",
    },
  };

  const getProjects = useCallback(async () => {
    setLoading(true);
    let isMounted = true;

    if (isMounted) {
      axios.get(`/api/view-client/${clientId}`).then(res => {
        if (res.data.status === 200) {
          setProjects(res.data.clientProjects);
          setClient(res.data.client);
        }
        setLoading(false);
      });
    }
    return () => {
      isMounted = false
    }
  }, [clientId]);

  const setPageData = useCallback(async () => {
    setLoading(true);
    let isMounted = true;
    if (isMounted) {
      let endpoints = [
        `/api/all-project-phases`,
        `/api/all-project-categories`
      ];
      Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
      .then(([
        {data: projectPhases},
        {data: projectCategories}
      ]) => {
        if (projectPhases.status === 200) {
          setProjectPhases(projectPhases.projectPhases);
        }
        if (projectCategories.status === 200) {
          setProjectCategories(projectCategories.projectCategories);
        }
        setLoading(false);
      });
    }
    return () => {
        isMounted = false
    }
  }, []);

  useEffect(() => {
    setPageData();
    getProjects();
  }, [getProjects, setPageData]);

  function openProjectModal(e) {
    setProjectModalIsOpen(true);
  }

  function closeProjectModal() {
    setProjectModalIsOpen(false);
  }

  return (
    <div className={`${loading ? 'page-loading' : ''}`}>
      <Header
        title={client.name}
        type="variation-4"
        headerButton={true}
        headerButtonType="modal"
        headerButtonTitle="Edit Project"
        headerButtonUrl={`/edit-client/${clientId}`}
        clientTier={client.client_tier}
        openModal={openProjectModal}
      />
      <div className='main-content all-projects-wrp'>
        <div className='middle-content'>
          <div className='card-wrp'>
            {
              projects && projects.map(project => {
                const phases = project.phases && project.phases.length > 0 ?
                  projectPhases.filter(item => project.phases.includes(item.id)).map(phase => {
                    return ({
                      id: phase.id,
                      label: phase.name
                    })
                  }) : [];

                const categories = project.categories && project.categories.length > 0 ?
                  projectCategories.filter(item => project.categories.includes(item.id)).map(category => {
                    return ({
                      id: category.id,
                      label: category.name
                    })
                  }) : [];

                const totalSpent = project.total_spent ? project.total_spent : 0;
                const totalEstimate = project.total_estimate ? project.total_estimate : 0;
                const totalTasks = project.total_tasks ? project.total_tasks : 0;
                const totalCompletedTasks = project.total_completed_tasks ? project.total_completed_tasks : 0;
                const daysLeft = project.days_left ? project.days_left : 0;

                return (
                  <div key={project.id} className='card-item-wrp'>
                    <ProjectCard
                      project={project}
                      phases={phases}
                      categories={categories}
                      totalSpent={totalSpent}
                      totalEstimate={totalEstimate}
                      daysLeft={daysLeft}
                      totalTasks={totalTasks}
                      totalCompletedTasks={totalCompletedTasks}
                    />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

      <Modal
        isOpen={projectModalIsOpen}
        onRequestClose={closeProjectModal}
        style={customStyles}
        ariaHideApp={false}
        closeTimeoutMS={500}
      >
        <div>
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              onClick={closeProjectModal}
            ></button>
          </div>
          <div className="modal-body">
            <div className="view-project">
              <div className="form-wrp">
                <EditClient
                  clientId={clientId}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <FloatingTimer />
    </div>
  );
}

export default ViewClient;