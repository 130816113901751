import React from 'react';
import { Route } from 'react-router-dom';
import Layout from './layouts/Layout';
// import Header from './layouts/Header';
import AllRoutes from './routes/AllRoutes';
import LeftSideBar from './layouts/LeftSideBar';
// import RightSideBar from './layouts/RightSideBar';

const AppRoute = ({...rest}) => {
    return (
        <Route {...rest}
            render = {({props, location}) =>
                (location.pathname === "/" || location.pathname === "/login" || location.pathname === "/register" || location.pathname === "/forgot-password" || AllRoutes.every(obj => obj.path !== location.pathname)) && !localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_token`) ? 
                <Layout {...props} /> :
                <>
                    {/* <Header /> */}
                    <div className="container-fluid p-0">
                        <div className="main-content-wrp">
                            <LeftSideBar />
                            <div className="right-panel">
                                <Layout {...props} />
                            </div>
                            {/* <RightSideBar /> */}
                        </div>
                    </div>
                </>
            }
        />
    );
}

export default AppRoute;