import React from 'react';

function LinksCard({ project }) {
  return (
    <div className='card'>
      <div className='card-url'>
        <a href={project.staging_url} target="_blank" rel="noopener noreferrer" className='card-url-anchor'>
          <span className='title'>Staging URL</span>
          <span className='link'>{project.staging_url ? project.staging_url : 'No Content'}</span>
        </a>
      </div>
      <div className='card-url'>
        <a href={project.production_url} target="_blank" rel="noopener noreferrer" className='card-url-anchor'>
          <span className='title'>Production URL</span>
          <span className='link'>{project.production_url ? project.production_url : 'No Content'}</span>
        </a>
      </div>
      <div className='card-buttons'>
        <a href={project.spec_url} target="_blank" rel="noopener noreferrer" className='button-link'>
          Specification
        </a>
        <a href={project.design_url} target="_blank" rel="noopener noreferrer" className='button-link'>
          Design Link
        </a>
      </div>
    </div>
  )
}

export default LinksCard