import React from 'react'

function SettingsAccordion({ title, items, type, openCreateModal, openEditModal, deleteItem }) {
  return (
    <div className={`setting-block ${type}-wrp`}>
        <div className='setting-block-inner-wrp'>
            <div className='title-wrp'>
                <h2>{title}</h2>
                <div key={`${type}`} className='content-wrp'>
                    <div className={`add-${type}-wrp btn btn-table large`} onClick={() => openCreateModal(type)} data-bs-toggle=''>Add</div>
                </div>
            </div>
            <div className='items-content-wrp'>
                <div className="accordion" id={`${type}-group`}>
                    {items.map(item => (
                        <div key={item.id} className={`accordion-item ${item.value}`}>
                            <div className="accordion-header" id={`${type}-${item.id}`}>
                                <div className='header collapsed' data-bs-toggle='collapse' data-bs-target={`#collapse-${type}-${item.id}`} aria-expanded="false" aria-controls={`collapse-${type}-${item.id}`}>
                                    <div className='content-wrp'>
                                        <span className='accordion-icon'></span>
                                        <div className='name'>{item.name}</div>
                                    </div>
                                </div>
                            </div>
                            <div id={`collapse-${type}-${item.id}`} className="accordion-collapse collapse" aria-labelledby={`${type}-${item.id}`} data-bs-parent={`#${type}-group`}>
                                <div className="accordion-body">
                                    <div className='content-row'><span>Name :</span> {item.name}</div>
                                    <div className='content-row'><span>Value :</span> {item.value}</div>
                                    <div className='content-row'><span>Level :</span> {item.level}</div>
                                    <div className='content-row'><span>Color :</span> {item.color}</div>
                                    <div className='content-row'><span>Archive :</span> {item.archive}</div>
                                    <div key={`${type}`} className='btn-wrp'>
                                        <div className={`delete-${type}-wrp colored-btn warning with-icon only-icon`} onClick={(e) => deleteItem(e, type, item.id)} data-bs-toggle=''></div>
                                        <div className={`edit-${type}-wrp btn btn-table large`} onClick={() => openEditModal(type, item.id)} data-bs-toggle=''>Edit</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default SettingsAccordion