import React from 'react';
import { Link } from 'react-router-dom';
import icon from './../../assets/icons/logo.svg';
import gold from './../../assets/icons/gold-tier.svg';
import silver from './../../assets/icons/silver-tier.svg';
import bronze from './../../assets/icons/bronze-tier.svg';
import Avatar from '../common/Avatar';

const ClientCard = ({ clientLogo, clientName, clientTier, clientDescription, clientSince, clientStatus, clientTierLogo, clientId }) => {
  if (clientTier === 'gold') {
    clientTierLogo = gold;
  } else if(clientTier === 'silver'){
    clientTierLogo = silver;
  } else if (clientTier === 'bronze') {
    clientTierLogo = bronze;
  }

  return (
    <Link to={`/view-client/${clientId}`} key={clientId} className='card'>
      <div className='card-header'>
        <div className='client-logo'>
          <Avatar
            className=""
            src={clientLogo ? `${process.env.REACT_APP_BACKEND_URL}/${clientLogo}` : icon}
            alt={clientName}
          />
        </div>
        <div className='client-name large-para ex-bold'>
          {clientName}
        </div>
        <div className='client-tier'>
          <img src={clientTierLogo} alt="" />
        </div>
      </div>
      <div className='card-description'>
        <div className='card-description-inner-wrp'>
          {clientDescription}
        </div>
      </div>
      <div className='card-footer'>
        <div className='client-since'>
          <span>Client Since :</span><span className='year'> {clientSince}</span>
        </div>
        <div className='client-status'>
          {clientStatus ?
            <span className="status green ex-small">Active</span>
            :
            <span className="status red ex-small">Inactive</span>
          }
        </div>
      </div>
    </Link>
  );
}

export default ClientCard;