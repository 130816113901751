import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Modal from 'react-modal';

import Header from '../../layouts/Header';
import SettingsAccordion from '../common/SettingsAccordion';
import { Colors } from '../../utils/Colors';
import FloatingTimer from '../common/FloatingTimer';

const customStyles = {
    content: {
        top: '0',
        left: 'auto',
        right: '0',
        bottom: '0',
        margin: '0',
        zIndex: '9999',
        border: 'none',
        borderRadius: '0',
        padding: '0',
        position: 'absolute',
        background: 'rgb(255, 255, 255)',
        overflow: 'auto',
        outline: 'none'
    },
};

const Settings = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [projectStatuses, setProjectStatuses] = useState([]);
    const [projectPriorities, setProjectPriorities] = useState([]);
    const [projectSupportPlans, setProjectSupportPlans] = useState([]);
    const [projectPhases, setProjectPhases] = useState([]);
    const [projectCategories, setProjectCategories] = useState([]);
    const [taskPriorities, setTaskPriorities] = useState([]);
    const [taskStatuses, setTaskStatuses] = useState([]);
    const [taskTypes, setTaskTypes] = useState([]);
    const [clientTiers, setClientTiers] = useState([]);

    const [editForm, setEditForm] = useState([]);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [editErrorList, setEditErrorList] = useState([]);

    const [createForm, setCreateForm] = useState([]);
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [createErrorList, setCreateErrorList] = useState([]);

    const setEditData = useCallback(async (type, id) => {
        setLoading(true);
        setEditErrorList([]);
        setEditForm([]);

        axios.get(`/api/edit-${type}/${id}`).then(res => {
            if (res.data.status === 200) {
                setEditForm(res.data.data);
                setEditForm({ ...res.data.data, formType: type });
            } else if (res.data.status === 404) {
                swal({
                    title: "Error",
                    text: res.data.message,
                    icon: "error",
                    className: "error-box",
                    timer: 3000,
                    buttons: false,
                });
                history.push(`/settings`);
            }
            setLoading(false);
        });
    }, [history]);

    const setPageData = useCallback(async () => {
        setLoading(true);
        let isMounted = true;
        if (isMounted) {
            let endpoints = [
                `/api/view-project-statuses`,
                `/api/view-project-priorities`,
                `/api/view-project-support-plans`,
                `/api/view-project-phases`,
                `/api/view-project-categories`,
                `/api/view-task-priorities`,
                `/api/view-task-statuses`,
                `/api/view-task-types`,
                `/api/all-client-tiers`
            ];
            Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
            .then(([
                {data: projectStatuses},
                {data: projectPriorities},
                {data: projectSupportPlans},
                {data: projectPhases},
                {data: projectCategories},
                {data: taskPriorities},
                {data: taskStatuses},
                {data: taskTypes},
                {data: clientTiers}
            ]) => {
                if (projectStatuses.status === 200) {
                    setProjectStatuses(projectStatuses.projectStatuses);
                }
                if (projectPriorities.status === 200) {
                    setProjectPriorities(projectPriorities.projectPriorities);
                }
                if (projectSupportPlans.status === 200) {
                    setProjectSupportPlans(projectSupportPlans.projectSupportPlans);
                }
                if (projectPhases.status === 200) {
                    setProjectPhases(projectPhases.projectPhases);
                }
                if (projectCategories.status === 200) {
                    setProjectCategories(projectCategories.projectCategories);
                }
                if (taskPriorities.status === 200) {
                    setTaskPriorities(taskPriorities.taskPriorities);
                }
                if (taskStatuses.status === 200) {
                    setTaskStatuses(taskStatuses.taskStatuses);
                }
                if (taskTypes.status === 200) {
                    setTaskTypes(taskTypes.taskTypes);
                }
                if (clientTiers.status === 200) {
                  setClientTiers(clientTiers.allClientTiers);
                }
                setLoading(false);
            });
        }
        return () => {
            isMounted = false
        }
    }, []);

    useEffect(() => {
        setPageData();
    }, [setPageData]);

    function openCreateModal(type) {
        setCreateForm({ ...createForm, formType: type });
        setCreateModalIsOpen(true);
    }

    function openEditModal(type, id) {
        setEditData(type, id);
        setEditModalIsOpen(true);
    }

    function closeCreateModal() {
        setCreateModalIsOpen(false);
    }

    function closeEditModal() {
        setEditModalIsOpen(false);
    }

    const handleCreateInput = (e) => {
        setCreateForm({ ...createForm, [e.target.name]: e.target.value });
    }

    const handleEditInput = (e) => {
        setEditForm({ ...editForm, [e.target.name]: e.target.value });
    }

    const createItem = (e) => {
        e.preventDefault();
        setPending(true);

        const formData = new FormData();
        formData.append('name', createForm.name);
        formData.append('value', createForm.value);
        formData.append('level', createForm.level);
        formData.append('color', createForm.color);

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/store-${createForm.formType}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if (res.data.status === 200) {
                    swal({
                        title: "Success",
                        text: res.data.message,
                        icon: "success",
                        className: "success-box",
                        timer: 3000,
                        buttons: false,
                    });
                    setPageData();
                    setCreateForm([]);
                    setCreateErrorList([]);
                    closeCreateModal();
                } else if (res.data.status === 422) {
                    swal({
                        title: "Error",
                        text: "All Fields are mandatory",
                        icon: "error",
                        className: "error-box",
                        timer: 3000,
                        buttons: false,
                    });
                    setCreateErrorList(res.data.errors);
                } else if (res.data.status === 404) {
                    swal({
                        title: "Error",
                        text: res.data.message,
                        icon: "error",
                        className: "error-box",
                        timer: 3000,
                        buttons: false,
                    });
                    history.push(`/settings`);
                }
                setPending(false);
            });
        });
    }

    const updateItem = (e) => {
        e.preventDefault();
        setPending(true);

        const formData = new FormData();
        formData.append('id', editForm.id);
        formData.append('name', editForm.name);
        formData.append('value', editForm.value);
        formData.append('level', editForm.level);
        formData.append('color', editForm.color);
        formData.append('_method', 'PUT');

        axios.get('/sanctum/csrf-cookie').then(response => { 
            axios.post(`/api/update-${editForm.formType}/${editForm.id}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if (res.data.status === 200) {
                    swal({
                        title: "Success",
                        text: res.data.message,
                        icon: "success",
                        className: "success-box",
                        timer: 3000,
                        buttons: false,
                    });
                    setPageData();
                    setEditForm([]);
                    setEditErrorList([]);
                    closeEditModal();
                } else if (res.data.status === 422) {
                    swal({
                        title: "Error",
                        text: "All Fields are mandatory",
                        icon: "error",
                        className: "error-box",
                        timer: 3000,
                        buttons: false,
                    });
                    setEditErrorList(res.data.errors);
                } else if (res.data.status === 404) {
                    swal({
                        title: "Error",
                        text: res.data.message,
                        icon: "error",
                        className: "error-box",
                        timer: 3000,
                        buttons: false,
                    });
                    history.push(`/settings`);
                }
                setPending(false);
            });
        });
    }

    const deleteItem = (e, type, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record",
            className: "delete-box",
            buttons: {
                delete : {
                    text : "Yes, Delete now",
                    className : 'colored-btn warning'
                },
                archive: {
                    text : 'Archive Instead',
                    className : 'colored-btn gentle'
                }
            },
            dangerMode: true,
        }).then((result) => {
            if (result === 'delete') {
                axios.delete(`/api/delete-${type}/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal({
                            title: "Success",
                            text: res.data.message,
                            icon: "success",
                            className: "success-box",
                            timer: 3000,
                            buttons: false,
                        });
                        setPageData();
                    } else if (res.data.status === 404) {
                        swal({
                            title: "Error",
                            text: res.data.message,
                            icon: "error",
                            className: "error-box",
                            timer: 3000,
                            buttons: false,
                        });
                    } else if (res.data.status === 405) {
                        swal({
                            title: "Error",
                            text: res.data.message,
                            icon: "error",
                            className: "error-box",
                            timer: 3000,
                            buttons: false,
                        });
                    }
                });
            } else if (result === 'archive') {
                axios.delete(`/api/archive-${type}/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal({
                            title: "Success",
                            text: res.data.message,
                            icon: "success",
                            className: "success-box",
                            timer: 3000,
                            buttons: false,
                        });
                        setPageData();
                    } else if (res.data.status === 404) {
                        swal({
                            title: "Error",
                            text: res.data.message,
                            icon: "error",
                            className: "error-box",
                            timer: 3000,
                            buttons: false,
                        });
                    } else if (res.data.status === 405) {
                        swal({
                            title: "Error",
                            text: res.data.message,
                            icon: "error",
                            className: "error-box",
                            timer: 3000,
                            buttons: false,
                        });
                    }
                });
            }
        });
    }

    return (
        <div className={`${loading ? 'page-loading' : ''}`}>
            <Header
                title="Settings"
                type="variation-1"
            />
            <div className='main-content settings'>
                <div className='project-blocks-wrp'>
                    <SettingsAccordion
                        title="Project Statuses"
                        items={projectStatuses}
                        type="project-status"
                        openCreateModal={openCreateModal}
                        openEditModal={openEditModal}
                        deleteItem={deleteItem}
                    />
                    <SettingsAccordion
                        title="Project Categories"
                        items={projectCategories}
                        type="project-category"
                        openCreateModal={openCreateModal}
                        openEditModal={openEditModal}
                        deleteItem={deleteItem}
                    />
                    <SettingsAccordion
                        title="Project Phases"
                        items={projectPhases}
                        type="project-phase"
                        openCreateModal={openCreateModal}
                        openEditModal={openEditModal}
                        deleteItem={deleteItem}
                    />
                    <SettingsAccordion
                        title="Project Priorities"
                        items={projectPriorities}
                        type="project-priority"
                        openCreateModal={openCreateModal}
                        openEditModal={openEditModal}
                        deleteItem={deleteItem}
                    />
                    <SettingsAccordion
                        title="Project Support Plans"
                        items={projectSupportPlans}
                        type="project-support-plan"
                        openCreateModal={openCreateModal}
                        openEditModal={openEditModal}
                        deleteItem={deleteItem}
                    />
                </div>
                <div className='task-blocks-wrp'>
                    <SettingsAccordion
                        title="Task Priorities"
                        items={taskPriorities}
                        type="task-priority"
                        openCreateModal={openCreateModal}
                        openEditModal={openEditModal}
                        deleteItem={deleteItem}
                    />
                    <SettingsAccordion
                        title="Task Statuses"
                        items={taskStatuses}
                        type="task-status"
                        openCreateModal={openCreateModal}
                        openEditModal={openEditModal}
                        deleteItem={deleteItem}
                    />
                    <SettingsAccordion
                        title="Task Types"
                        items={taskTypes}
                        type="task-type"
                        openCreateModal={openCreateModal}
                        openEditModal={openEditModal}
                        deleteItem={deleteItem}
                    />
                    <SettingsAccordion
                        title="Client Tiers"
                        items={clientTiers}
                        type="client-tier"
                        openCreateModal={openCreateModal}
                        openEditModal={openEditModal}
                        deleteItem={deleteItem}
                    />
                </div>
            </div>
            <Modal className="setting-modal" isOpen={createModalIsOpen} onRequestClose={closeCreateModal} style={customStyles} ariaHideApp={false} closeTimeoutMS={500}>
                <div>
                    <div className="modal-body">
                        <div className='settings-form'>
                            <div className="form-wrp">
                                <form encType='multipart/form-data' onSubmit={createItem} id="SETTINGS_FORM">
                                    <div className='form-inner-wrp'>
                                        <div className='form-content-wrp'>
                                            <div className='form-left-col'>
                                                <div className="form-group">
                                                    <label htmlFor="name" className="form-label">Name</label>
                                                    <input type="text" name="name" onChange={handleCreateInput} value={createForm.name} className='form-control' id='name' autoComplete="off" />
                                                    <small className='text-danger'>{createErrorList.name}</small>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="value" className="form-label">Value</label>
                                                    <input type="text" name="value" onChange={handleCreateInput} value={createForm.value} className='form-control' id='value' autoComplete="off" />
                                                    <small className='text-danger'>{createErrorList.value}</small>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="level" className="form-label">Level</label>
                                                    <input type="text" name="level" onChange={handleCreateInput} value={createForm.level} className='form-control' id='level' autoComplete="off" />
                                                    <small className='text-danger'>{createErrorList.level}</small>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="color" className="form-label">Color</label>
                                                    <select name='color' onChange={handleCreateInput} value={createForm.color} className='form-select' id='color'>
                                                        <option value="">Select Color</option>
                                                        {Colors.map((item, idx) => <option key={idx} value={item.value}>{item.label}</option>)}
                                                    </select>
                                                    <small className='text-danger'>{createErrorList.color}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='button-wrp'>
                                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary'>
                                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal className="setting-modal" isOpen={editModalIsOpen} onRequestClose={closeEditModal} style={customStyles} ariaHideApp={false} closeTimeoutMS={500}>
                <div>
                    <div className="modal-body">
                        <div className='settings-form'>
                            <div className="form-wrp">
                                <form encType='multipart/form-data' onSubmit={updateItem} id="SETTINGS_FORM">
                                    <div className='form-inner-wrp'>
                                        <div className='form-content-wrp'>
                                            <div className='form-left-col'>
                                                <div className="form-group">
                                                    <label htmlFor="name" className="form-label">Name</label>
                                                    <input type="text" name="name" onChange={handleEditInput} value={editForm.name} className='form-control' id='name' autoComplete="off" />
                                                    <small className='text-danger'>{editErrorList.name}</small>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="value" className="form-label">Value</label>
                                                    <input type="text" name="value" onChange={handleEditInput} value={editForm.value} className='form-control' id='value' autoComplete="off" />
                                                    <small className='text-danger'>{editErrorList.value}</small>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="level" className="form-label">Level</label>
                                                    <input type="text" name="level" onChange={handleEditInput} value={editForm.level} className='form-control' id='level' autoComplete="off" />
                                                    <small className='text-danger'>{editErrorList.level}</small>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="color" className="form-label">Color</label>
                                                    <select name='color' onChange={handleEditInput} value={editForm.color} className='form-select' id='color'>
                                                        <option value="">Select Color</option>
                                                        {Colors.map((item, idx) => <option key={idx} value={item.value}>{item.label}</option>)}
                                                    </select>
                                                    <small className='text-danger'>{editErrorList.color}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='button-wrp'>
                                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary'>
                                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <FloatingTimer />
        </div>
    );
}

export default Settings;