const HoursToTime = (timeInHours) => {
  let timeInSeconds = timeInHours * 60 * 60;

  let hours = Math.floor((timeInSeconds / (60 * 60)));
  timeInSeconds = timeInSeconds - (hours * 60 * 60);
  let minutes = Math.floor((timeInSeconds / 60));
  timeInSeconds = timeInSeconds - (minutes * 60);
  let seconds = Math.round(timeInSeconds); 

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  let returnTime = `${hours}h : ${minutes}m`;
  return returnTime;
}

export default HoursToTime;