import React from 'react';
import RoundNumber from '../../utils/RoundNumber';

function CardStats({ totalSpent, totalEstimate, daysLeft }) {
  return (
    <div className='card-stats'>
      <div className='stat-item'><span className='count-box' title={`${RoundNumber(totalSpent)} Spent`}>{ RoundNumber(totalSpent) }</span>Spent</div>
      <div className='stat-item'><span className='count-box' title={`${RoundNumber(totalEstimate)} Estimate`}>{ RoundNumber(totalEstimate) }</span>Estimate</div>
      <div className='stat-item'><span className='count-box' title={`${daysLeft} Days Left`}>{daysLeft}</span>Days Left</div>
    </div>
  )
}

export default CardStats