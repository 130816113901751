import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import swal from 'sweetalert';
import axios from 'axios';
import dateFormat from "dateformat";
import parse from 'html-react-parser';

// import Avatar from '../common/Avatar';
import icon from "./../../assets/icons/logo.svg";
import Header from '../../layouts/Header';

const initialColumns = {
    notStarted: {
        id: 'notStarted',
        columnClass:"not-Started",
        name: 'Not-Started',
        list: []
    },
    inProgress: {
        id: 'inProgress',
        columnClass:"in-progress",
        name: 'In-Progress',
        list: []
    },
    onHold: {
        id: 'onHold',
        columnClass:"on-hold",
        name: 'On-Hold',
        list: []
    },
    completed: {
        id: 'completed',
        columnClass:"completed",
        name: 'Completed',
        list: []
    }
}

const Home = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState(initialColumns);
    // const [projectList, setProjectList] = useState([]);

    if (!localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_token`)) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_role`))) {
        history.push('/403');
    }

    const getProjects = useCallback(async () => {
        setLoading(true);

        axios.get(`/api/get-projects-by-status`).then(res => {
            if (res.data.status === 200) {
                setColumns(state => ({
                    ...state,
                    notStarted: {
                        id: 'notStarted',
                        columnClass:"not-Started",
                        name: 'Not-Started',
                        list: res.data.notStartedProjects
                    },
                    inProgress: {
                        id: 'inProgress',
                        columnClass:"in-progress",
                        name: 'In-Progress',
                        list: res.data.inProgressProjects
                    },
                    onHold: {
                        id: 'onHold',
                        columnClass:"on-hold",
                        name: 'On-Hold',
                        list: res.data.onHoldProjects
                    },
                    completed: {
                        id: 'completed',
                        columnClass:"completed",
                        name: 'Completed',
                        list: res.data.completedProjects
                    }
                }))
            } else if (res.data.status === 404) {
                swal({
                    title: "Error",
                    text: res.data.message,
                    icon: "error",
                    className: "error-box",
                    timer: 3000,
                    buttons: false,
                });
            }  else if (res.data.status === 405) {
                swal({
                    title: "Error",
                    text: res.data.message,
                    icon: "error",
                    className: "error-box",
                    timer: 3000,
                    buttons: false,
                });
            }
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        getProjects();
    }, [getProjects]);

    const onDragEnd = ({ source, destination }) => {
        // Make sure we have a valid destination
        if (destination === undefined || destination === null) return null
    
        // Make sure we're actually moving the item
        if (source.droppableId === destination.droppableId && destination.index === source.index) return null
    
        // Set start and end variables
        const start = columns[source.droppableId]
        const end = columns[destination.droppableId]
    
        // If start is the same as end, we're in the same column
        if (start === end) {
            // Move the item within the list
            // Start by making a new list without the dragged item
            const newList = start.list.filter(
                (_, idx) => idx !== source.index
            )
    
            // Then insert the item at the right location
            // newList.splice(destination.index, 0, start.list[source.index])
    
            // Then create a new copy of the column object
            const newCol = {
                id: start.id,
                list: newList
            }
            console.log('newCol', newCol);
    
            // Update the state
            // setColumns(state => ({ ...state, [newCol.id]: newCol }))
            return null
        } else {
            // If start is different from end, we need to update multiple columns
            // Filter the start list like before
            const newStartList = start.list.filter(
                (_, idx) => idx !== source.index
            )
    
            // Create a new start column
            const newStartCol = {
                id: start.id,
                list: newStartList
            }
    
            // Make a new end list array
            const newEndList = end.list
    
            // Insert the item into the end list
            // newEndList.splice(destination.index, 0, start.list[source.index])
    
            // Create a new end column
            const newEndCol = {
                id: end.id,
                list: newEndList
            }
            console.log('newStartCol', newStartCol);
            console.log('newEndCol', newEndCol);
    
            // Update the state
            // setColumns(state => ({
            //     ...state,
            //     [newStartCol.id]: newStartCol,
            //     [newEndCol.id]: newEndCol
            // }))
            return null
        }
    }

    return (
        localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_token`) ?
        <>
            <div className={`${loading ? 'page-loading' : ''}`}>
                <Header
                    title="Dashboard"
                    type="variation-1"
                />
                <div className='main-content my-tasks'>
                    <div className='middle-content'>
                        <div className='tasks-main-wrp'>
                            <DragDropContext onDragEnd={onDragEnd}>
                                {Object.values(columns).map((col) => (
                                    <Droppable droppableId={col.id} key={col.id}>
                                    {(provided) => (
                                        <div className={`tasks-column ${col.columnClass} ${loading ? "card-loading" : ""}`}>
                                            <div className="tasks-title-wrp">
                                                {col.name}
                                                <span className="task-count">{col.list.length}</span>
                                            </div>
                                            <div className="tasks-wrp" {...provided.droppableProps} ref={provided.innerRef}>
                                                {col.list &&
                                                col.list.map((project, index) => {
                                                    return (
                                                        <Draggable draggableId={`${project.id}`} index={index} key={index}>
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    key={project.id}
                                                                    className="tasks-inner-wrp"
                                                                    onClick={(e) => history.push(`/view-project/${project.id}`)}
                                                                >
                                                                    <div className="card">
                                                                        <div className="card-description">
                                                                            <div className="logo-title-wrp">
                                                                                <div className="logo-wrp">
                                                                                    <img src={project.logo ? `${process.env.REACT_APP_BACKEND_URL}/${project.logo}` : icon} alt="Project Name" />
                                                                                </div>
                                                                                <div className="title-wrp">
                                                                                    {`${project.name}`}
                                                                                </div>
                                                                            </div>
                                                                            <div className="description-wrp">
                                                                                <div className="description html-desc">
                                                                                    {project.description && parse(project.description)}
                                                                                </div>
                                                                                { project.priority &&
                                                                                    <div className="tagline-wrp">
                                                                                        <span className={`status ${project.priority.color} ex-small`}>
                                                                                            {project.priority.name}
                                                                                        </span>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="date-time-wrp">
                                                                            <div className="date-wrp">
                                                                                {dateFormat(project.start_date, "mmm dd")} - {dateFormat(project.deadline, "mmm dd")}
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className='card-footer'>
                                                                            {project.project_manager && 
                                                                                <>
                                                                                    <div className='po-image-wrp'>
                                                                                        <Avatar
                                                                                        src={project.project_manager.profile_picture ? `${process.env.REACT_APP_BACKEND_URL}/${project.project_manager.profile_picture}` : icon}
                                                                                        alt={project.project_manager.name}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='po-name'>{ project.project_manager.name }</div>
                                                                                </>
                                                                            }
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        </div>
                                    )}
                                    </Droppable>
                                ))}
                            </DragDropContext>
                        </div>
                    </div>
                </div>
            </div>
        </> 
        :
        <Redirect to='/login' />
    );
}

export default Home;