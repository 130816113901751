import React, {useState} from 'react';
import Header from '../../../layouts/Header';
import DailyTimeLogs from './DailyTimeLogs';
import WeeklyTimeLogs from './WeeklyTimeLogs';
import TabNavItem from '../../common/TabNavItem';
import TabContent from '../../common/TabContent';
import FloatingTimer from '../../common/FloatingTimer';

const TimeLogs = () => {
  const [activeTab, setActiveTab] = useState("daily-time-logs");
  const [assignee, setAssignee] = useState(localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_id`));

  const handleAssignee = (assignee) => {
    setAssignee(assignee.value);
  }

  return (
    <div>
      <Header
        title="Time Logs"
        type="variation-6"
        headerButton={true}
        headerButtonType="button"
        headerButtonUrl="/#"
        headerButtonTitle="+ Add Time Log"
        assignee={assignee}
        handleAssignee={handleAssignee}
      />
      <div className='main-content my-time-logs'>
        <div className='middle-content'>
          <div className="form-wrp top-form-wrp flex">
            <div className='tab-section-wrp'>
              <>
                <ul className='tabbed-btn-wrp'>
                  <TabNavItem title="Daily Time Logs" id="daily-time-logs" activeTab={activeTab} setActiveTab={setActiveTab}/>
                  <TabNavItem title="Weekly Time Logs" id="weekly-time-logs" activeTab={activeTab} setActiveTab={setActiveTab}/>
                </ul>
                <TabContent id="daily-time-logs" activeTab={activeTab}>
                  <DailyTimeLogs assignee={assignee} />
                </TabContent>
                <TabContent id="weekly-time-logs" activeTab={activeTab}>
                  <WeeklyTimeLogs assignee={assignee} />
                </TabContent>
              </>
            </div>
          </div>
        </div>
      </div>
      <FloatingTimer />
    </div>
  );
}

export default TimeLogs;