import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DragDropContext } from 'react-beautiful-dnd';
import swal from "sweetalert";
import { format } from 'date-fns';

import Header from '../../../layouts/Header';
import SubTaskCard from '../../common/SubTaskCard';

const initialColumns = {
  backlog: {
      id: 'backlog',
      columnClass:"backlog",
      name: 'Backlog',
      list: []
  },
  // overdue: {
  //     id: 'overdue',
  //     columnClass:"overdue",
  //     name: 'Overdue',
  //     list: []
  // },
  inProgress: {
      id: 'inProgress',
      columnClass:"in-progress",
      name: 'In-Progress',
      list: []
  },
  onHold: {
      id: 'onHold',
      columnClass:"on-hold",
      name: 'On-Hold',
      list: []
  },
  qaReady: {
    id: 'qaReady',
    columnClass:"qa-ready",
    name: 'QA Ready',
    list: []
  },
  completed: {
      id: 'completed',
      columnClass:"completed",
      name: 'Completed',
      list: []
  }
}

function MyAssigneeTasks() {
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState(initialColumns);
  const [assignee, setAssignee] = useState("all");
  const [project, setProject] = useState("all");

  const setPageData = useCallback(async (assigneeId, projectId) => {
      setLoading(true);
      let isMounted = true;

      if (isMounted) {
        axios.get(`/api/my-assignee-tasks-report/${assigneeId}/${projectId}`).then(res => {
          if (res.data.status === 200) {
            setColumns(state => ({
              ...state,
              backlog: {
                  id: 'backlog',
                  columnClass:"backlog",
                  name: 'Backlog',
                  list: res.data.backlogSubtasks
              },
              // overdue: {
              //     id: 'overdue',
              //     columnClass:"overdue",
              //     name: 'Overdue',
              //     list: res.data.overdueSubtasks
              // },
              inProgress: {
                  id: 'inProgress',
                  columnClass:"in-progress",
                  name: 'In-Progress',
                  list: res.data.inprogressSubtasks
              },
              onHold: {
                  id: 'onHold',
                  columnClass:"on-hold",
                  name: 'On-Hold',
                  list: res.data.onHoldSubtasks
              },
              qaReady: {
                id: 'qaReady',
                columnClass:"qa-ready",
                name: 'QA Ready',
                list: res.data.qaSubtasks
              },
              completed: {
                  id: 'completed',
                  columnClass:"completed",
                  name: 'Completed',
                  list: res.data.completedSubtasks
              }
            }))
          }
          setLoading(false);
        });
      }
      return () => {
        isMounted = false
      }
  }, []);

  useEffect(() => {
      setPageData(assignee, project);
  }, [setPageData, assignee, project]);

  const submitTimeLog = (timeLogInput, taskStatus) => {
    setLoading(true);
    let taskStatusId = null;
    if (taskStatus === "backlog") {
      taskStatusId = 4;
    } else if (taskStatus === "inProgress") {
      taskStatusId = 2;
    } else if (taskStatus === "onHold") {
      taskStatusId = 6;
    } else if (taskStatus === "qaReady") {
      taskStatusId = 3;
    } else if (taskStatus === "completed") {
      taskStatusId = 5;
    }
    const formData = new FormData();
    formData.append("sub_task_id", timeLogInput.id);
    formData.append("parent_task_id", timeLogInput.parent_task_id);
    formData.append("task_status_id", taskStatusId);
    formData.append("note", "");
    formData.append("date", format(new Date(), "yyyy-MM-dd"));
    formData.append("spent_time", 0);
    formData.append("non_billable_time", 0);

    axios({
      method: "post",
      url: `/api/add-time-log-data`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      if (res.data.status === 200) {
          swal({
            title: "Success",
            text: "Task updated successfully",
            icon: "success",
            className: "success-box",
            timer: 3000,
            buttons: false,
          });
          // resetForm();
          setPageData(assignee, project);

      } else if (res.data.status === 422) {
          swal({
            title: "Error",
            text: "All Fields are mandatory",
            icon: "error",
            className: "error-box",
            timer: 3000,
            buttons: false,
          });
      }
      setLoading(false);
    });
  };

  const onDragEnd = ({ source, destination }) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null

    // Make sure we're actually moving the item
    if (source.droppableId === destination.droppableId && destination.index === source.index) return null

    // Set start and end variables
    const start = columns[source.droppableId]
    const end = columns[destination.droppableId]

    // If start is the same as end, we're in the same column
    if (start === end) {
        // Move the item within the list
        // Start by making a new list without the dragged item
        const newList = start.list.filter(
            (_, idx) => idx !== source.index
        )

        // Then insert the item at the right location
        newList.splice(destination.index, 0, start.list[source.index])

        // Then create a new copy of the column object
        // const newCol = {
        //     id: start.id,
        //     list: newList
        // }
        // console.log('newCol', newCol);

        // Update the state
        // setColumns(state => ({ ...state, [newCol.id]: newCol }))
        return null
    } else {
        // If start is different from end, we need to update multiple columns
        // Filter the start list like before
        // const newStartList = start.list.filter(
        //     (_, idx) => idx !== source.index
        // )

        // Create a new start column
        // const newStartCol = {
        //     id: start.id,
        //     list: newStartList
        // }

        // Make a new end list array
        const newEndList = end.list
        // console.log('newStartCol', newStartCol);
        // console.log('newEndCol', newEndCol);

        // Insert the item into the end list
        newEndList.splice(destination.index, 0, start.list[source.index])

        submitTimeLog(start.list[source.index], end.id);

        // Create a new end column
        // const newEndCol = {
        //     id: end.id,
        //     list: newEndList
        // }
        // console.log('newStartCol', newStartCol);
        // console.log('newEndCol', newEndCol);

        // Update the state
        // setColumns(state => ({
        //     ...state,
        //     [newStartCol.id]: newStartCol,
        //     [newEndCol.id]: newEndCol
        // }))
        return null
    }
  }

  const handleAssignee = (assignee) => {
    setAssignee(assignee.value);
  }

  const handleProject = (project) => {
    setProject(project.value);
  }

  return (
    <div className={`${loading ? 'page-loading' : ''}`}>
      <Header
        title="My Assignee Tasks"
        type="variation-5"
        assignee={assignee}
        handleAssignee={handleAssignee}
        selectedProject={project}
        handleProject={handleProject}
      />
      <div className='main-content my-tasks'>
        <div className='middle-content'>
          <div className='tasks-main-wrp'>
            <DragDropContext onDragEnd={onDragEnd}>
              <SubTaskCard columns={columns} setMyTasksPageData={setPageData} type="summary" />
            </DragDropContext>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyAssigneeTasks