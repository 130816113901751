import React, { useState, useRef, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
// import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Header from "../../../layouts/Header";
import upload from "./../../../assets/icons/logo-upload.svg";
import FormDropdown from "../../common/form/FormDropdown";
import FloatingTimer from "../../common/FloatingTimer";

const AddClient = () => {
  const hiddenFileInput = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [errorList, setError] = useState([]);
  const [pending, setPending] = useState(false);
  const [clientData, setClientData] = useState({
    slug: "",
    name: "",
    description: "",
    url: "",
    status: "",
    email: "",
    phone: "",
    start_year: "",
    client_tier: "",
  });
  const [selectedTier, setSelectedTier] = useState([]);

  const setPageData = useCallback(async () => {
    setLoading(true);
    let isMounted = true;
    if (isMounted) {
        let endpoints = [
            `/api/all-client-tiers`,
        ];
        Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
        .then(([
            {data: allClientTiers},
        ]) => {
            if (allClientTiers.status === 200) {
              setSelectedTier(allClientTiers.allClientTiers);
            }
            setLoading(false);
        });
    }
    return () => {
        isMounted = false
    }
  }, []);

  useEffect(() => {
      setPageData();
  }, [setPageData]);


  const resetForm = () => {
    setClientData({
      slug: "",
      name: "",
      description: "",
      url: "",
      status: "",
      email: "",
      phone: "",
      start_year: "",
      client_tier: "",
    });
    setImage([]);
  };

  const handleInput = (e) => {
    setClientData({ ...clientData, [e.target.name]: e.target.value });
  };

  const handleLogo = (e) => {
    setImage({ logo: e.target.files[0] });
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleUploadClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleCheckbox = (e) => {
    const { value, checked } = e.target;
    setClientData({ ...clientData, [value]: checked });
  };

  const submitClient = (e) => {
    e.preventDefault();
    setPending(true);

    let isValid = true;

    if (clientData.name.length === 0) {
      setError({
        ...errorList,
        name: "Please Select a Name for Client",
      });
      isValid = false;
      setPending(false);
    } else if (clientData.slug.length === 0) {
      setError({
        ...errorList,
        slug: "Please Select a Slug for Client",
      });
      isValid = false;
      setPending(false);
    }

    const formData = new FormData();
    formData.append("slug", clientData.slug);
    formData.append("name", clientData.name);
    formData.append("description", clientData.description);
    formData.append("url", clientData.url);
    formData.append("logo", image.logo);
    formData.append("status", clientData.status ? 1 : 0);
    formData.append("email", clientData.email);
    formData.append("phone", clientData.phone);
    formData.append("start_year", clientData.start_year ? new Date(clientData.start_year).getFullYear() : "");
    formData.append("client_tier", clientData.client_tier);

    if (isValid) {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios({
          method: "post",
          url: `/api/store-client`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
          if (res.data.status === 200) {
            swal({
              title: "Success",
              text: res.data.message,
              icon: "success",
              className: "success-box",
              timer: 3000,
              buttons: false,
            });
            resetForm();
            setError([]);
            history.push("/all-clients");
          } else if (res.data.status === 422) {
            swal({
              title: "Error",
              text: "All Fields are mandatory",
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
            setError(res.data.errors);
          }
        });
        setPending(false);
      });
    }
  };

  const handleDropdown = (item, action) => {
    setClientData({ ...clientData, [action.name]: item.value });
  }

  const getDropdownOptions = (dataList) => {
    let options = dataList.map(item => ({
      value: item.value,
      label: item.name
    }))
    return options;
  }

  return (
    <div className={`add-new-client-wrp ${loading ? 'page-loading' : ''}`}>
      <Header title="Add a new Client" type="variation-1" />
      <div className="main-content add-project">
        <div className="form-wrp">
          <form
            encType="multipart/form-data"
            onSubmit={submitClient}
            id="PROJECT_FORM"
          >
            <div className="form-inner-wrp">
              <div className="form-content-wrp">
                <div className="form-left-col">
                  <div className="form-group">
                    <label htmlFor="logo" className="form-label">
                      Logo
                    </label>
                    <div
                      className={`logo-wrp ${
                        selectedImage ? "project-logo-wrp" : ""
                      }`}
                      onClick={handleUploadClick}
                    >
                      <img
                        src={
                          selectedImage
                            ? URL.createObjectURL(selectedImage)
                            : upload
                        }
                        className={selectedImage ? "project-logo" : ""}
                        alt=""
                      />
                    </div>
                    <input
                      type="file"
                      name="logo"
                      ref={hiddenFileInput}
                      onChange={handleLogo}
                      style={{ display: "none" }}
                      className="form-control"
                      id="logo"
                    />
                    <small className="text-danger">{errorList.logo}</small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="client_name" className="form-label">
                      Client Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      onChange={handleInput}
                      value={clientData.name}
                      className="form-control"
                      id="client_name"
                    />
                    <small className="text-danger">
                      {errorList.name}
                    </small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="contact_no" className="form-label">
                      Contact no
                    </label>
                    <input
                      type="text"
                      name="phone"
                      onChange={handleInput}
                      value={clientData.phone}
                      className="form-control"
                      id="contact_no"
                    />
                    <small className="text-danger">
                      {errorList.phone}
                    </small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="client_email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleInput}
                      value={clientData.email}
                      className="form-control"
                      id="client_email"
                    />
                    <small className="text-danger">
                      {errorList.email}
                    </small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="client_slug" className="form-label">
                      Slug
                    </label>
                    <input
                      type="text"
                      name="slug"
                      onChange={handleInput}
                      value={clientData.slug}
                      className="form-control"
                      id="client_slug"
                    />
                    <small className="text-danger">
                      {errorList.slug}
                    </small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="start_year" className="form-label">
                      Client Joined Year
                    </label>
                    <DatePicker
                      selected={clientData.start_year}
                      onChange={(date) => setClientData({ ...clientData, "start_year": date })}
                      showYearPicker
                      dateFormat="yyyy"
                      className="form-control"
                    />
                    <small className="text-danger">
                      {errorList.start_year}
                    </small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="client_tier" className="form-label">
                      Client Tier
                    </label>
                    <FormDropdown
                      value={clientData.client_tier}
                      name="client_tier"
                      id="client_tier"
                      options={getDropdownOptions(selectedTier)}
                      isSearchable={true}
                      isDisabled={pending}
                      onChange={handleDropdown}
                      placeholder="Client Tier"
                    />
                    <small className="text-danger">
                      {errorList.client_tier}
                    </small>
                  </div>
                </div>
                <div className="form-right-col">
                  <div className="title-wrp">
                    <h2>Client Details</h2>
                    <p>General Information about the client</p>
                  </div>
                  <div className="project-detail-row pd-first cd-first">
                    <div className="form-group col col-12 description-col">
                      <div className="form-group">
                        <label htmlFor="description" className="form-label">
                          Brief Description about the Project
                        </label>
                        <textarea
                          name="description"
                          onChange={handleInput}
                          value={clientData.description}
                          className="form-control"
                          id="description"
                          style={{ height: "245px" }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="project-detail-row pd-second cd-second">
                    <div className="form-group col col-12 col-xl-7 px-3">
                      <div className="title-wrp">
                        <h2>Client Details</h2>
                        <p>General Information about the client</p>
                      </div>
                      <div className="form-group">
                        <div className="input-label-left-variation">
                          <label htmlFor="client_url" className="form-label">
                            Website URL
                          </label>
                          <input
                            type="text"
                            name="url"
                            onChange={handleInput}
                            value={clientData.url}
                            className="form-control"
                            id="client_url"
                          />
                          <small className="text-danger">
                            {errorList.staging_url}
                          </small>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-check">
                          <input type="checkbox" name="phase" onChange={handleCheckbox} value="status" className="form-check-input" id="status" />
                          <label htmlFor="status" className="form-check-label">Status</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-wrp">
                <button
                  disabled={pending ? "disabled" : ""}
                  type="submit"
                  className="btn btn-primary"
                >
                  <span
                    className={`spinner-border spinner-border-sm me-3 ${
                      !pending ? "d-none" : ""
                    }`}
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Create Client
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <FloatingTimer />
    </div>
  );
};

export default AddClient;
