import Home from '../components/pages/Home';
import About from '../components/pages/About';
import Contact from '../components/pages/Contact';
import Profile from '../components/pages/Profile';
import Styleguide from '../components/pages/Styleguide';
import AllProjects from '../components/pages/project/AllProjects';
import AddProject from '../components/pages/project/AddProject';
import ViewProject from '../components/pages/project/ViewProject';
import MyTasks from '../components/pages/tasks/MyTasks';
import Page401 from '../components/pages/errors/Page401';
import Page403 from '../components/pages/errors/Page403';
import Page404 from '../components/pages/errors/Page404';
import Login from '../components/pages/auth/Login';
import Register from '../components/pages/auth/Register';
import ForgotPassword from '../components/pages/auth/ForgotPassword';
import ResetPassword from '../components/pages/auth/ResetPassword';
import ChangePassword from '../components/pages/auth/ChangePassword';
import MyTasksUI from '../components/pages/MyTasksUI';
import Settings from '../components/pages/Settings';
import AddClient from '../components/pages/client/AddClient';
import AllClients from '../components/pages/client/AllClients';
import ViewClient from '../components/pages/client/ViewClient';
import MyTimeLogs from '../components/pages/time/MyTimeLogs';
import TimeLogs from '../components/pages/time/TimeLogs';
import Leaves from '../components/pages/leaves/leaves';
import Assignee from '../components/pages/tasks/Assignee';
import MyAssigneeTasks from '../components/pages/tasks/MyAssigneeTasks';

const AllRoutes = [
    { path: '/', exact: true, name: 'Home', component: Home, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/about', exact: true, name: 'About', component: About, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/contact', exact: true, name: 'Contact', component: Contact, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/styleguide', exact: true, name: 'Styleguide', component: Styleguide, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/my-tasks-ui', exact: true, name: 'MyTasksUI', component: MyTasksUI, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/profile', exact: true, name: 'Profile', component: Profile, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/all-projects', exact: true, name: 'AllProjects', component: AllProjects, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/my-tasks', exact: true, name: 'MyTasks', component: MyTasks, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/assignee', exact: true, name: 'Assignee', component: Assignee, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/my-assignee-tasks', exact: true, name: 'Assignee', component: MyAssigneeTasks, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/add-project', exact: true, name: 'AddProject', component: AddProject, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/view-project/:id', exact: true, name: 'ViewProject', component: ViewProject, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/settings', exact: true, name: 'Settings', component: Settings, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/add-client', exact: true, name: 'AddClient', component: AddClient, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/all-clients', exact: true, name: 'AllClients', component: AllClients, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/view-client/:id', exact: true, name: 'ViewClient', component: ViewClient, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/my-time-logs', exact: true, name: 'MyTimeLogs', component: MyTimeLogs, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/time-logs', exact: true, name: 'TimeLogs', component: TimeLogs, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/leaves', exact: true, name: 'Leaves', component: Leaves, allowedRoles: ['admin', 'user', 'client', 'qa'] },

    { path: '/change-password', exact: true, name: 'ChangePassword', component: ChangePassword, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/401', exact: true, name: 'Page401', component: Page401, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/403', exact: true, name: 'Page403', component: Page403, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/404', exact: true, name: 'Page404', Page404: Page404, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/login', exact: true, name: 'Login', component: Login, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/register', exact: true, name: 'Register', component: Register, allowedRoles: ['admin', 'user', 'qa'] },
    { path: '/forgot-password', exact: true, name: 'ForgotPassword', component: ForgotPassword, allowedRoles: ['admin', 'user', 'client', 'qa'] },
    { path: '/reset-password/:token/:email', exact: true, name: 'ResetPassword', component: ResetPassword, allowedRoles: ['admin', 'user', 'client', 'qa'] },
];

export default AllRoutes;