import React, {useState} from 'react';
import Header from '../../../layouts/Header';
import DailyTimeLogs from './DailyTimeLogs';
import WeeklyTimeLogs from './WeeklyTimeLogs';
import TabNavItem from '../../common/TabNavItem';
import TabContent from '../../common/TabContent';
import FloatingTimer from '../../common/FloatingTimer';

const MyTimeLogs = () => {
  const [activeTab, setActiveTab] = useState("daily-time-logs");

  return (
    <div>
      <Header
        title="My Time Logs"
        type="variation-2"
        headerButton={true}
        headerButtonType="button"
        headerButtonUrl="/#"
        headerButtonTitle="+ Add Time Log"
      />
      <div className='main-content my-time-logs'>
        <div className='middle-content'>
          <div className="form-wrp top-form-wrp flex">
            <div className='tab-section-wrp'>
              <>
                <ul className='tabbed-btn-wrp'>
                  <TabNavItem title="Daily Time Logs" id="daily-time-logs" activeTab={activeTab} setActiveTab={setActiveTab}/>
                  <TabNavItem title="Weekly Time Logs" id="weekly-time-logs" activeTab={activeTab} setActiveTab={setActiveTab}/>
                </ul>
                <TabContent id="daily-time-logs" activeTab={activeTab}>
                  <DailyTimeLogs/>
                </TabContent>
                <TabContent id="weekly-time-logs" activeTab={activeTab}>
                  <WeeklyTimeLogs/>
                </TabContent>
              </>
            </div>
          </div>
        </div>
      </div>
      <FloatingTimer />
    </div>
  );
}

export default MyTimeLogs;