import React, { useState } from 'react';
import TabNavItem from "../components/common/TabNavItem";
import TabContent from "../components/common/TabContent";
import StatsCard from '../components/common/StatsCard';
import LinksCard from '../components/common/LinksCard';
import TasksTimeline from '../components/common/TasksTimeline';

function RightSideBar({ project, tasks, totalSpent, totalEstimate, daysLeft, totalTasks, totalCompletedTasks }) {
  const [activeTab, setActiveTab] = useState("project-links");

  return (
    <div className="right-sidebar">
      <div className='right-sidebar-inner-wrp'>
        <div className='right-sidebar-content'>
          {project &&
          <>
            <ul className='tabbed-btn-wrp'>
              <TabNavItem title="Project Links" id="project-links" activeTab={activeTab} setActiveTab={setActiveTab}/>
              <TabNavItem title="Stats" id="project-stats" activeTab={activeTab} setActiveTab={setActiveTab}/>
            </ul>
            <TabContent id="project-links" activeTab={activeTab}>
              <div className='content-box project-wrp'>
                <LinksCard project={project} />
              </div>
            </TabContent>
            <TabContent id="project-stats" activeTab={activeTab}>
              <div className='content-box stats-wrp'>
                <StatsCard
                  project={project}
                  totalSpent={totalSpent}
                  totalEstimate={totalEstimate}
                  daysLeft={daysLeft}
                  totalTasks={totalTasks}
                  totalCompletedTasks={totalCompletedTasks}
                />
              </div>
            </TabContent>
            {tasks.length > 0 &&
              <div className='content-box'>
                <TasksTimeline tasks={tasks} />
              </div>
            }
          </>
        }
        </div>
      </div>
    </div>
  )
}

export default RightSideBar