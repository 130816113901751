import React from 'react';
import Header from '../../layouts/Header';
// import logo from './../../assets/icons/logo.svg';
import roots from './../../assets/icons/roots.png';

function MyTasksUI() {
  return (
    <>
      <Header
        title="My Tasks"
        type="variation-1"
      />
      <div className='main-content my-tasks'>
        <div className='middle-content'>
          <div className='tasks-main-wrp'>
            <div className='tasks-column'>
              <div className='tasks-title-wrp'>
                Backlog
                <span className='task-count'>3</span>
              </div>
              <div className='tasks-wrp'>
                <div className='tasks-inner-wrp'>
                  <div className='card'>
                    <div className='card-description'>
                      <div className='logo-title-wrp'>
                        <div className='logo-wrp'>
                          <img src={roots} alt="Project Name" />
                        </div>
                        <div className='title-wrp'>Project Setup</div>
                      </div>
                      <div className='description-wrp'>
                        <div className='description'>
                          Develop the UI Design for the website Home Page. Consider the following...
                        </div>
                        <div className='tagline-wrp'>
                          <span className='status yellow ex-small'>Define Fitness</span>
                          <span className='status yellow ex-small'>UX/UI</span>
                        </div>
                      </div>
                    </div>
                    <div className='date-time-wrp'>
                      <div className='date-wrp'>June 18 - 20</div>
                      <div className='time-wrp'>
                        <span className='spent'>2h:30m</span>
                        <span className='estimated'>(4h : 30m)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='tasks-column overdue'>
              <div className='tasks-title-wrp'>
                Overdue
                <span className='task-count'>4</span>
              </div>
              <div className='tasks-wrp'>
                <div className='tasks-inner-wrp'>
                  <div className='card'>
                    <div className='card-description'>
                      <div className='logo-title-wrp'>
                        <div className='logo-wrp'>
                          <img src={roots} alt="Project Name" />
                        </div>
                        <div className='title-wrp'>Project Setup</div>
                      </div>
                      <div className='description-wrp'>
                        <div className='description'>
                          Develop the UI Design for the website Home Page. Consider the following...
                        </div>
                        <div className='tagline-wrp'>
                          <span className='status yellow ex-small'>Define Fitness</span>
                          <span className='status yellow ex-small'>UX/UI</span>
                        </div>
                      </div>
                    </div>
                    <div className='date-time-wrp'>
                      <div className='date-wrp'>June 18 - 20</div>
                      <div className='time-wrp'>
                        <span className='spent'>2h:30m</span>
                        <span className='estimated'>(4h : 30m)</span>
                      </div>
                    </div>
                  </div>
                  <div className='card'>
                    <div className='card-description'>
                      <div className='logo-title-wrp'>
                        <div className='logo-wrp'>
                          <img src={roots} alt="Project Name" />
                        </div>
                        <div className='title-wrp'>Project Setup</div>
                      </div>
                      <div className='description-wrp'>
                        <div className='description'>
                          Develop the UI Design for the website Home Page. Consider the following...
                        </div>
                        <div className='tagline-wrp'>
                          <span className='status yellow ex-small'>Define Fitness</span>
                          <span className='status yellow ex-small'>UX/UI</span>
                        </div>
                      </div>
                    </div>
                    <div className='date-time-wrp'>
                      <div className='date-wrp'>June 18 - 20</div>
                      <div className='time-wrp'>
                        <span className='spent'>2h:30m</span>
                        <span className='estimated'>(4h : 30m)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='tasks-column in-progress'>
              <div className='tasks-title-wrp'>
                In-Progress
                <span className='task-count'>3</span>
              </div>
              <div className='tasks-wrp'>
                <div className='tasks-inner-wrp'>
                  <div className='card'>
                    <div className='card-description'>
                      <div className='logo-title-wrp'>
                        <div className='logo-wrp'>
                          <img src={roots} alt="Project Name" />
                        </div>
                        <div className='title-wrp'>Project Setup</div>
                      </div>
                      <div className='description-wrp'>
                        <div className='description'>
                          Develop the UI Design for the website Home Page. Consider the following...
                        </div>
                        <div className='tagline-wrp'>
                          <span className='status yellow ex-small'>Define Fitness</span>
                          <span className='status yellow ex-small'>UX/UI</span>
                        </div>
                      </div>
                    </div>
                    <div className='date-time-wrp'>
                      <div className='date-wrp'>June 18 - 20</div>
                      <div className='time-wrp'>
                        <span className='spent'>2h:30m</span>
                        <span className='estimated'>(4h : 30m)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='tasks-column on-hold'>
              <div className='tasks-title-wrp'>
                On-Hold
                <span className='task-count'>2</span>
              </div>
              <div className='tasks-wrp'>
                <div className='tasks-inner-wrp'>
                  <div className='card'>
                    <div className='card-description'>
                      <div className='logo-title-wrp'>
                        <div className='logo-wrp'>
                          <img src={roots} alt="Project Name" />
                        </div>
                        <div className='title-wrp'>Project Setup</div>
                      </div>
                      <div className='description-wrp'>
                        <div className='description'>
                          Develop the UI Design for the website Home Page. Consider the following...
                        </div>
                        <div className='tagline-wrp'>
                          <span className='status yellow ex-small'>Define Fitness</span>
                          <span className='status yellow ex-small'>UX/UI</span>
                        </div>
                      </div>
                    </div>
                    <div className='date-time-wrp'>
                      <div className='date-wrp'>June 18 - 20</div>
                      <div className='time-wrp'>
                        <span className='spent'>2h:30m</span>
                        <span className='estimated'>(4h : 30m)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='tasks-column completed'>
              <div className='tasks-title-wrp'>
                Completed
                <span className='task-count'>5</span>
              </div>
              <div className='tasks-wrp'>
                <div className='tasks-inner-wrp'>
                  <div className='card'>
                    <div className='card-description'>
                      <div className='logo-title-wrp'>
                        <div className='logo-wrp'>
                          <img src={roots} alt="Project Name" />
                        </div>
                        <div className='title-wrp'>Project Setup</div>
                      </div>
                      <div className='description-wrp'>
                        <div className='description'>
                          Develop the UI Design for the website Home Page. Consider the following...
                        </div>
                        <div className='tagline-wrp'>
                          <span className='status yellow ex-small'>Define Fitness</span>
                          <span className='status yellow ex-small'>UX/UI</span>
                        </div>
                      </div>
                    </div>
                    <div className='date-time-wrp'>
                      <div className='date-wrp'>June 18 - 20</div>
                      <div className='time-wrp'>
                        <span className='spent'>2h:30m</span>
                        <span className='estimated'>(4h : 30m)</span>
                      </div>
                    </div>
                  </div>
                  <div className='card'>
                    <div className='card-description'>
                      <div className='logo-title-wrp'>
                        <div className='logo-wrp'>
                          <img src={roots} alt="Project Name" />
                        </div>
                        <div className='title-wrp'>Project Setup</div>
                      </div>
                      <div className='description-wrp'>
                        <div className='description'>
                          Develop the UI Design for the website Home Page. Consider the following...
                        </div>
                        <div className='tagline-wrp'>
                          <span className='status yellow ex-small'>Define Fitness</span>
                          <span className='status yellow ex-small'>UX/UI</span>
                        </div>
                      </div>
                    </div>
                    <div className='date-time-wrp'>
                      <div className='date-wrp'>June 18 - 20</div>
                      <div className='time-wrp'>
                        <span className='spent'>2h:30m</span>
                        <span className='estimated'>(4h : 30m)</span>
                      </div>
                    </div>
                  </div>
                  <div className='card'>
                    <div className='card-description'>
                      <div className='logo-title-wrp'>
                        <div className='logo-wrp'>
                          <img src={roots} alt="Project Name" />
                        </div>
                        <div className='title-wrp'>Project Setup</div>
                      </div>
                      <div className='description-wrp'>
                        <div className='description'>
                          Develop the UI Design for the website Home Page. Consider the following...
                        </div>
                        <div className='tagline-wrp'>
                          <span className='status yellow ex-small'>Define Fitness</span>
                          <span className='status yellow ex-small'>UX/UI</span>
                        </div>
                      </div>
                    </div>
                    <div className='date-time-wrp'>
                      <div className='date-wrp'>June 18 - 20</div>
                      <div className='time-wrp'>
                        <span className='spent'>2h:30m</span>
                        <span className='estimated'>(4h : 30m)</span>
                      </div>
                    </div>
                  </div>
                  <div className='card'>
                    <div className='card-description'>
                      <div className='logo-title-wrp'>
                        <div className='logo-wrp'>
                          <img src={roots} alt="Project Name" />
                        </div>
                        <div className='title-wrp'>Project Setup</div>
                      </div>
                      <div className='description-wrp'>
                        <div className='description'>
                          Develop the UI Design for the website Home Page. Consider the following...
                        </div>
                        <div className='tagline-wrp'>
                          <span className='status yellow ex-small'>Define Fitness</span>
                          <span className='status yellow ex-small'>UX/UI</span>
                        </div>
                      </div>
                    </div>
                    <div className='date-time-wrp'>
                      <div className='date-wrp'>June 18 - 20</div>
                      <div className='time-wrp'>
                        <span className='spent'>2h:30m</span>
                        <span className='estimated'>(4h : 30m)</span>
                      </div>
                    </div>
                  </div>
                  <div className='card'>
                    <div className='card-description'>
                      <div className='logo-title-wrp'>
                        <div className='logo-wrp'>
                          <img src={roots} alt="Project Name" />
                        </div>
                        <div className='title-wrp'>Project Setup</div>
                      </div>
                      <div className='description-wrp'>
                        <div className='description'>
                          Develop the UI Design for the website Home Page. Consider the following...
                        </div>
                        <div className='tagline-wrp'>
                          <span className='status yellow ex-small'>Define Fitness</span>
                          <span className='status yellow ex-small'>UX/UI</span>
                        </div>
                      </div>
                    </div>
                    <div className='date-time-wrp'>
                      <div className='date-wrp'>June 18 - 20</div>
                      <div className='time-wrp'>
                        <span className='spent'>2h:30m</span>
                        <span className='estimated'>(4h : 30m)</span>
                      </div>
                    </div>
                  </div>
                  <div className='card'>
                    <div className='card-description'>
                      <div className='logo-title-wrp'>
                        <div className='logo-wrp'>
                          <img src={roots} alt="Project Name" />
                        </div>
                        <div className='title-wrp'>Project Setup</div>
                      </div>
                      <div className='description-wrp'>
                        <div className='description'>
                          Develop the UI Design for the website Home Page. Consider the following...
                        </div>
                        <div className='tagline-wrp'>
                          <span className='status yellow ex-small'>Define Fitness</span>
                          <span className='status yellow ex-small'>UX/UI</span>
                        </div>
                      </div>
                    </div>
                    <div className='date-time-wrp'>
                      <div className='date-wrp'>June 18 - 20</div>
                      <div className='time-wrp'>
                        <span className='spent'>2h:30m</span>
                        <span className='estimated'>(4h : 30m)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyTasksUI