import React, { useEffect, useState } from 'react';
import axios from "axios";
import swal from "sweetalert";
import Comment from "./Comment";
import { debounce } from "../../utils/UtilityFunctions";
import FormWYSIWYG from './form/FormWYSIWYG';

const CommentsSection = ({pending, allComments, parentTaskId, subTaskId}) => {
  const [commentInput, setCommentInput] = useState("");
  const [comments, setComments] = useState(allComments);
  const [commentUpdated, setCommentUpdated] = useState(false);
  const [commentBoxError, setCommentBoxError] = useState("");
  const [commentPending, setCommentPending] = useState(pending);

  const addReply = (commentId, replyText) => {
    let commentsWithNewReply = [...comments];
    insertComment(commentsWithNewReply, commentId, replyText);
    setComments(commentsWithNewReply);
    setCommentUpdated(true);
  }

  const deleteReply = (commentId) => {
    swal({
    title: "Are you sure?",
    text: "Once deleted, you will not be able to recover this Record",
    icon: "warning",
    buttons: true,
    dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let commentsWithNewReply = [...comments];
        let newCommentArray = deleteComment(commentsWithNewReply, commentId);
        setComments(newCommentArray);
        setCommentUpdated(true);
      }
    });
  }

  const newComment = (text) => {
    return {
      id: new Date().getTime(),
      userId: localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_id`),
      displayText: text,
      displayName: localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_name`),
      displayImage: localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_image`),
      displayDate: new Date(),
      children: [],
    };
  }

  const insertComment = (comments, parentId, text) => {
    for (let i = 0; i < comments.length; i++) {
      let comment = comments[i];
      if (comment.id === parentId) {
        comment.children.unshift(newComment(text));
      }
    }

    for (let i = 0; i < comments.length; i++) {
      let comment = comments[i];
      insertComment(comment.children, parentId, text);
    }
  }

  const deleteComment = (comments, parentId) =>
    comments.reduce(
      (acc, obj) => obj.id === parentId ? acc : [...acc, {...obj, ...(obj.children && {children: deleteComment(obj.children, parentId)})}]
    ,[]
  );

  useEffect(() => {
    if (commentUpdated) {
      updateComment(comments);
      setCommentUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  const updateComment = (comments) => {
    setCommentPending(true);
    axios.get("/sanctum/csrf-cookie").then((response) => {
    const formData = new FormData();
    formData.append("parent_task_id", parentTaskId);
    formData.append("comments", JSON.stringify(comments));
    formData.append("_method", "PUT");

    axios.post(`/api/update-comments-sub-task/${subTaskId}`, formData, { headers: { "Content-Type": "multipart/form-data" },})
      .then((res) => {
        if (res.data.status === 200) {
          swal({
            title: "Success",
            text: "Comments Updated",
            icon: "success",
            className: "success-box",
            timer: 3000,
            buttons: false,
          });
          setCommentInput("");
          setCommentPending(false);
        } else if (res.data.status === 422) {
          swal("All fields are mandatory", "", "error");
          setCommentPending(false);
        } else if (res.data.status === 404) {
          swal("Error", res.data.message, "error");
          setCommentPending(false);
        }
      });
    });
  };

  const handleComment = (value) => {
    debounce(setCommentInput(value));
  };

  const handleSubmitComment = (e) => {
    e.preventDefault();
    if (commentInput !== "") {
      setComments([newComment(commentInput), ...comments]);
      setCommentInput("");
      setCommentUpdated(true);
      setCommentBoxError("");
    } else {
      setCommentBoxError("This Field is Required");
    }
  };

  return (
    <div className="container my-4">
      <div className="row d-flex justify-content-center">
        <div className="col-12">
          <div className="card" style={{ maxWidth: "100%" }}>
            <div className="card-body p-4">
              <h5>Comments</h5>
              <div className="row">
                <div className="col-12 position-relative">
                  <FormWYSIWYG
                    value={commentInput ? commentInput : ''}
                    onChange={handleComment}
                    id={`sub_task_comment_${subTaskId}`}
                    name="sub_task_comment"
                  />
                  {commentBoxError && (
                    <div className="comment-error">
                      <small className="text-danger">
                        {commentBoxError}
                      </small>
                    </div>
                  )}
                </div>
                <div className="col-12 mt-4 text-end">
                  <button disabled={commentPending ? "disabled" : ""} className="btn btn-primary" onClick={handleSubmitComment}>
                    <span className={`spinner-border spinner-border-sm me-1 ${!commentPending ? "d-none" : ""}`} role="status" aria-hidden="true"></span>
                    Submit
                  </button>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12">
                  {comments && comments.length > 0 && comments.map(
                    (comment) => (
                      <Comment
                        key={comment.id}
                        comment={comment}
                        addReply={addReply}
                        deleteReply={deleteReply}
                        canReply={true}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommentsSection