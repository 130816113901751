import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStopwatch } from "react-timer-hook";
import swal from "sweetalert";

import playIcon from './../../assets/icons/play.svg';
import pauseIcon from './../../assets/icons/pause.svg';

function FloatingTimer() {
  const [startVisible, setStartVisible] = useState(true);
  // const [stopVisible, setStopVisible] = useState(false);
  const [activeSubTask, setActiveSubTask] = useState("");
  const [activeParentTask, setActiveParentTask] = useState("");

  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: false,
  });

  useEffect(() => {
    let activeParentTask = localStorage.getItem(
      `${process.env.REACT_APP_NAME}_current_parent_task_id`
    );
    let activeSubTask = localStorage.getItem(
      `${process.env.REACT_APP_NAME}_current_sub_task_id`
    );

    if (activeParentTask) {
      setActiveParentTask(activeParentTask);
    }
    if (activeSubTask) {
      setActiveSubTask(activeSubTask);
    }

    // Checking for local variables and resume/paused
    if (localStorage.getItem(`${process.env.REACT_APP_NAME}_start_time`)) {
      let timePassed;
      const stopwatchOffset = new Date();
      const nowTime = stopwatchOffset.getTime();
      const pauseTime = parseInt(
        localStorage.getItem(`${process.env.REACT_APP_NAME}_pause_time`)
      );
      const startedTime = parseInt(
        localStorage.getItem(`${process.env.REACT_APP_NAME}_start_time`)
      );
      const totalPauseTime = parseInt(
        localStorage.getItem(`${process.env.REACT_APP_NAME}_total_paused_time`)
      );
      let timeToRemove;
      // Check if there it was paused during the timer
      if (totalPauseTime) {
        timeToRemove = totalPauseTime;
      } else {
        timeToRemove = 0;
      }
      // Check if timer is paused when reloading
      if (pauseTime) {
        timePassed =
          (nowTime - startedTime - (nowTime - pauseTime)) / 1000 - timeToRemove;
        stopwatchOffset.setSeconds(
          stopwatchOffset.getSeconds() + Math.round(timePassed)
        );
        reset(stopwatchOffset, false);
        setStartVisible(true);
        // setStopVisible(true);
        // Run this if timer not paused
      } else {
        timePassed = (nowTime - startedTime) / 1000 - timeToRemove;
        stopwatchOffset.setSeconds(
          stopwatchOffset.getSeconds() + Math.round(timePassed)
        );
        reset(stopwatchOffset, true);
        setStartVisible(false);
        // setStopVisible(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to "Start/Pause" the Timer
  const startTimer = () => {
    if (activeSubTask && activeParentTask) {
      setStartVisible((current) => !current);
      // setStopVisible(true);

      if (!localStorage.getItem(`${process.env.REACT_APP_NAME}_start_time`)) {
        const startTime = new Date();
        localStorage.setItem(
          `${process.env.REACT_APP_NAME}_start_time`,
          startTime.getTime()
        );
      }

      if (startVisible) {
        start();
        if (localStorage.getItem(`${process.env.REACT_APP_NAME}_pause_time`)) {
          const resume = new Date();
          const resumeTime = resume.getTime();
          const pausedTotalTimeTemp =
            (resumeTime -
              parseInt(
                localStorage.getItem(`${process.env.REACT_APP_NAME}_pause_time`)
              )) /
            1000;
          let pausedTotalTime;
          if (
            localStorage.getItem(
              `${process.env.REACT_APP_NAME}_total_paused_time`
            )
          ) {
            pausedTotalTime =
              pausedTotalTimeTemp +
              parseInt(
                localStorage.getItem(
                  `${process.env.REACT_APP_NAME}_total_paused_time`
                )
              );
          } else {
            pausedTotalTime = pausedTotalTimeTemp;
          }
          localStorage.setItem(
            `${process.env.REACT_APP_NAME}_total_paused_time`,
            pausedTotalTime
          );
          localStorage.removeItem(`${process.env.REACT_APP_NAME}_pause_time`);
        }
      } else {
        pause();
        const pauseTime = new Date();
        localStorage.setItem(
          `${process.env.REACT_APP_NAME}_pause_time`,
          pauseTime.getTime()
        );
      }
    } else {
      swal({
        title: "Error",
        text: "Please Select a Task",
        icon: "error",
        className: "error-box",
        timer: 3000,
        buttons: false,
      });
    }
  };

  // Function to "Stop" the timer
  // const stopTimer = (e) => {
  //   e.preventDefault();
  //   setStopVisible(false);
  //   setStartVisible(true);
  //   if (stopVisible) {
  //       reset(0,false);
  //       localStorage.removeItem(`${process.env.REACT_APP_NAME}_start_time`);
  //       localStorage.removeItem(`${process.env.REACT_APP_NAME}_pause_time`);
  //       localStorage.removeItem(`${process.env.REACT_APP_NAME}_current_sub_task_id`);
  //       localStorage.removeItem(`${process.env.REACT_APP_NAME}_current_parent_task_id`);
  //       localStorage.removeItem(`${process.env.REACT_APP_NAME}_total_paused_time`);
  //   }
  // }

  // const viewTask = (e) => {
  //   e.preventDefault();
  //   localStorage.setItem(`${process.env.REACT_APP_NAME}_view_task`, true);
  //   history.push(`/my-tasks`);
  //   window.location.reload(true);
  // }

  const minutesFunction = (minutes) => {
    let newMinutes = minutes;
    if (newMinutes < 10) {
      newMinutes = "0" + newMinutes;
    }
    return newMinutes;
  };

  // Function to add 0 to seconds where less than 10
  const secondsFunction = (seconds) => {
    let newSeconds = seconds;
    if (newSeconds < 10) {
      newSeconds = "0" + newSeconds;
    }
    return newSeconds;
  };

  return (
    <>
      {localStorage.getItem(`${process.env.REACT_APP_NAME}_start_time`) && (
        <div className="floating-time-wrp">
          <div className="floating-time-inner-wrp">
            <div className="form-group position-relative">
              <div className="input-group time-tracker">
                <span className="tt-spent-time">{`${hours} : ${minutesFunction(minutes)} : ${secondsFunction(
                  seconds
                )}`}</span>
                {/* <input
                  type="number"
                  name="spent_time_hours"
                  value={hours}
                  min="0"
                  max="999"
                  step="1"
                  id="spent_time_hours"
                  className="form-control text-end"
                  placeholder="Hours"
                />
                <span className='d-flex justify-content-center align-items-center middle-dots'>:</span>
                <input
                  type="number"
                  name="spent_time_minutes"
                  value={minutes}
                  min="0"
                  max="59"
                  step="1"
                  id="spent_time_minutes"
                  className="form-control"
                  placeholder="Minutes"
                  aria-describedby="start-pause-button"
                /> */}
                {/* onKeyDown={(e) => e.preventDefault()} */}
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="start-pause-button"
                  onClick={() => startTimer()}
                >
                  {startVisible ? <img src={playIcon} alt="play" /> : <img src={pauseIcon} alt="pause" />}
                </button>
              </div>
            </div>
            <div className="button-wrp">
              <div className="del-update-btn-wrp">
                {/* <button type='button' onClick={(e) => stopTimer(e)} className='colored-btn warning delete with-icon'>
                            Stop Timer
                        </button> */}
                <Link to={`/my-tasks`} className="btn btn-table large">
                  View Task
                </Link>
                {/* <button type='button' onClick={(e) => viewTask(e)} className='btn btn-table large'>
                            View Task
                        </button> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FloatingTimer;
