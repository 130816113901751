import React, { useState } from 'react'
import TasksAccordion from '../../common/TasksAccordion';

const TaskPhaseSection = ({projectPhases, phase, tasks, openParentTaskCreateModal, openTaskEditModal}) => {
  const [open, setOpen] = useState(true);
  return (
    <div className="pb-4">
      <h2 className="pb-2 d-flex justify-content-between">
        {projectPhases.find((item) => item.id === phase).name}
        { open ? 
          <button className='btn btn-outline' onClick={() => setOpen(!open)}>Collapse</button> : 
          <button className='btn btn-secondary' onClick={() => setOpen(!open)}>Show</button>
        }
      </h2>
      { open &&
        <TasksAccordion
          tasks={tasks.filter(
            (item) => item.parentTask.task_phase_id === phase
          )}
          openParentTaskCreateModal={openParentTaskCreateModal}
          openTaskEditModal={openTaskEditModal}
        />
      }
    </div>
  )
}

export default TaskPhaseSection