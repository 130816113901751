import React, { useState, useCallback, useEffect } from 'react';
import axios from "axios";
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import Select from 'react-select';
import HoursToTime from '../../../utils/HoursToTime';

import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { WeekPicker } from '../../../utils/weekpicker/WeekPicker';
import { startOfWeek, lastDayOfWeek } from 'date-fns';

function WeeklyTimeLogs({ assignee }) {
  let todayDate = new Date();
  let firstDayWeek = startOfWeek(todayDate);
  let lastDayWeek = lastDayOfWeek(todayDate);
  const [loading, setLoading] = useState(false);
  const [uniqueTasks, setUniqueTasks] = useState([]);
  const [weeklyTimelogData, setWeeklyTimelogData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState({
    firstDay: firstDayWeek,
    lastDay: lastDayWeek
  });
  const [assigneeId, setAssigneeId] = useState(localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_id`));

  const getTimelogs = useCallback(async () => {
    setLoading(true);
    let isMounted = true;

    if (isMounted) {
      let weeklySubtaskIDs = [];
      let weeklySubtasks = [];
      let weekTimelogsArray = [];
      axios.get(`/api/my-weekly-timelogs/${assigneeId}/${format(selectedWeek.firstDay , 'Y-MM-d')}/${format(selectedWeek.lastDay , 'Y-MM-d')}`).then(res => {
        if (res.data.status === 200) {
          res.data.timelogs.forEach(timelogs => {
            if (typeof(selectedProject) === 'number') {
              if (!weeklySubtaskIDs.includes(timelogs.task_id) && timelogs.sub_task.parent_task.project.id === selectedProject) {
                weeklySubtaskIDs.push(timelogs.task_id);
                weeklySubtasks.push(timelogs.sub_task);
              }
            } else {
              if (!weeklySubtaskIDs.includes(timelogs.task_id)) {
                weeklySubtaskIDs.push(timelogs.task_id);
                weeklySubtasks.push(timelogs.sub_task);
              }
            }
          });
          for (let index = 0; index < weeklySubtasks.length; index++) {
            weekTimelogsArray.push([]);
            for (let index2 = 0; index2 < 7; index2++) {
              weekTimelogsArray[index].push(0);
            }
          }
          weeklySubtaskIDs.forEach((currentElement, index) => {
            res.data.timelogs.forEach((timelogdata) => {
              if (timelogdata.task_id === currentElement) {
                let dayOfWeek = DayOfTheWeek(timelogdata.date);
                weekTimelogsArray[index][dayOfWeek] += parseFloat(timelogdata.spent_time);
              }
            });
          });
          setUniqueTasks(weeklySubtasks);
          setWeeklyTimelogData(weekTimelogsArray);
        }
      });
      setLoading(false);
    }
    return () => {
      isMounted = false
    }
  }, [selectedWeek, assigneeId, selectedProject]);

  const getAllProjects = useCallback(async () => {
    setLoading(true);
    let isMounted = true;

    if (isMounted) {
      axios.get(`/api/view-projects`).then(res => {
        if (res.data.status === 200) {
          setProjects(res.data.projects);
        }
      });
      setLoading(false);
    }
    return () => {
      isMounted = false
    }
  }, []);

  const getDropdownOptions = (dataList) => {
    let options = dataList.map(item => ({
      value: item.id,
      label: item.name
    }))
    return options;
  }

  const handleTypeSelect = (item) => {
    setSelectedOption(item.value);
    setSelectedProject(item.value);
  }

  const DayOfTheWeek = (item) => {
    let testdate = new Date(item);
    let dayOfWeek = testdate.getDay()-1;
    if (dayOfWeek === -1) {
      dayOfWeek = 6;
    }

    return dayOfWeek;
  }

  const sumOfArray = (array) => {
    let sumOfThis = 0;
    array.forEach(element => {
      sumOfThis += element;
    });

    return sumOfThis;
  }

  const dailyTotalTime = (array, position) => {
    let sumOfThis = 0;
    array.forEach(element => {
      sumOfThis += element[position];
    });

    return sumOfThis;
  }

  const handleChange = (week) => {
    setSelectedWeek(week);
  };

  const weeklyTotalTime = (totalTimes) => {
    let sumOfThis = 0;

    totalTimes.forEach(element => {
      element.forEach(singleItem => {
        sumOfThis += singleItem;
      });
    });

    return sumOfThis;
  }

  useEffect(() => {
    getAllProjects();
    getTimelogs();
  // eslint-disable-next-line
  }, [selectedWeek, assigneeId, selectedProject]);

  useEffect(() => {
    if (assignee) {
      setAssigneeId(assignee);
    }
    // eslint-disable-next-line
  }, [assignee]);

  return (
    <div className={`${loading ? 'page-loading' : ''} weekly-timelog-wrapper`}>
      <form>
        <div className="form-row">
          <div className="form-group col">
            <WeekPicker
              onChange={handleChange}
            />
          </div>
          <div className="form-group col">
            <Select
              defaultValue={selectedOption}
              onChange={handleTypeSelect}
              options={getDropdownOptions(projects)}
            />
          </div>
        </div>
      </form>

      <div className='weekly-time-logs'>
        <h2 className='pb-2'>Weekly Time Logs</h2>
        <div className='daily-time-logs-table-wrp'>
          <div className='daily-time-logs-table'>
            <div className="tasks-heading-wrp">
              <div className="project">Project</div>
              {/* <div className="task">Parent Task</div> */}
              <div className="task">Sub Task</div>
              <div className="date">{`Monday ${format(selectedWeek.firstDay, 'dd-MM-yyyy')}`}</div>
              <div className="date">{`Tuesday ${format(addDays(selectedWeek.firstDay, 1), 'dd-MM-yyyy')}`}</div>
              <div className="date">{`Wednesday ${format(addDays(selectedWeek.firstDay, 2), 'dd-MM-yyyy')}`}</div>
              <div className="date">{`Thursday ${format(addDays(selectedWeek.firstDay, 3), 'dd-MM-yyyy')}`}</div>
              <div className="date">{`Friday ${format(addDays(selectedWeek.firstDay, 4), 'dd-MM-yyyy')}`}</div>
              <div className="date">{`Saturday ${format(addDays(selectedWeek.firstDay, 5), 'dd-MM-yyyy')}`}</div>
              <div className="date">{`Sunday ${format(selectedWeek.lastDay, 'dd-MM-yyyy')}`}</div>
              <div className="date total">Total</div>
            </div>
            <div className='daily-tl-item-wrp'>
              {
                uniqueTasks && uniqueTasks.map((task, index) =>
                  <div key={index} className='daily-tl-item'>
                    <div className="project">{task.parent_task.project.name}</div>
                    {/* <div className="task">{task.parent_task.task_name}</div> */}
                    <div className="task">{task.sub_task_name}</div>
                    <div className={`date text-center ${weeklyTimelogData[index][0] === 0 ? 'opacity-50' : ''}`}>{weeklyTimelogData[index][0] === 0 ? '-' : HoursToTime(weeklyTimelogData[index][0])}</div>
                    <div className={`date text-center ${weeklyTimelogData[index][1] === 0 ? 'opacity-50' : ''}`}>{weeklyTimelogData[index][1] === 0 ? '-' : HoursToTime(weeklyTimelogData[index][1])}</div>
                    <div className={`date text-center ${weeklyTimelogData[index][2] === 0 ? 'opacity-50' : ''}`}>{weeklyTimelogData[index][2] === 0 ? '-' : HoursToTime(weeklyTimelogData[index][2])}</div>
                    <div className={`date text-center ${weeklyTimelogData[index][3] === 0 ? 'opacity-50' : ''}`}>{weeklyTimelogData[index][3] === 0 ? '-' : HoursToTime(weeklyTimelogData[index][3])}</div>
                    <div className={`date text-center ${weeklyTimelogData[index][4] === 0 ? 'opacity-50' : ''}`}>{weeklyTimelogData[index][4] === 0 ? '-' : HoursToTime(weeklyTimelogData[index][4])}</div>
                    <div className={`date text-center ${weeklyTimelogData[index][5] === 0 ? 'opacity-50' : ''}`}>{weeklyTimelogData[index][5] === 0 ? '-' : HoursToTime(weeklyTimelogData[index][5])}</div>
                    <div className={`date text-center ${weeklyTimelogData[index][6] === 0 ? 'opacity-50' : ''}`}>{weeklyTimelogData[index][6] === 0 ? '-' : HoursToTime(weeklyTimelogData[index][6])}</div>
                    <div className="date text-center total">{HoursToTime(sumOfArray(weeklyTimelogData[index]))}</div>
                  </div>
                )
              }
            </div>
            <div className='daily-tl-item-wrp'>
              <div className='daily-tl-item'>
                <div className="project">Total</div>
                {/* <div className="task">Total</div> */}
                <div className="task">Total</div>
                <div className={`date text-center ${dailyTotalTime(weeklyTimelogData,0) === 0 ? 'opacity-50' : ''}`}>{dailyTotalTime(weeklyTimelogData,0) === 0 ? '-' : HoursToTime(dailyTotalTime(weeklyTimelogData,0))}</div>
                <div className={`date text-center ${dailyTotalTime(weeklyTimelogData,1) === 0 ? 'opacity-50' : ''}`}>{dailyTotalTime(weeklyTimelogData,1) === 0 ? '-' : HoursToTime(dailyTotalTime(weeklyTimelogData,1))}</div>
                <div className={`date text-center ${dailyTotalTime(weeklyTimelogData,2) === 0 ? 'opacity-50' : ''}`}>{dailyTotalTime(weeklyTimelogData,2) === 0 ? '-' : HoursToTime(dailyTotalTime(weeklyTimelogData,2))}</div>
                <div className={`date text-center ${dailyTotalTime(weeklyTimelogData,3) === 0 ? 'opacity-50' : ''}`}>{dailyTotalTime(weeklyTimelogData,3) === 0 ? '-' : HoursToTime(dailyTotalTime(weeklyTimelogData,3))}</div>
                <div className={`date text-center ${dailyTotalTime(weeklyTimelogData,4) === 0 ? 'opacity-50' : ''}`}>{dailyTotalTime(weeklyTimelogData,4) === 0 ? '-' : HoursToTime(dailyTotalTime(weeklyTimelogData,4))}</div>
                <div className={`date text-center ${dailyTotalTime(weeklyTimelogData,5) === 0 ? 'opacity-50' : ''}`}>{dailyTotalTime(weeklyTimelogData,5) === 0 ? '-' : HoursToTime(dailyTotalTime(weeklyTimelogData,5))}</div>
                <div className={`date text-center ${dailyTotalTime(weeklyTimelogData,6) === 0 ? 'opacity-50' : ''}`}>{dailyTotalTime(weeklyTimelogData,6) === 0 ? '-' : HoursToTime(dailyTotalTime(weeklyTimelogData,6))}</div>
                <div className="date text-center total">{HoursToTime(weeklyTotalTime(weeklyTimelogData))}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeeklyTimeLogs