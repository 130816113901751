import React from 'react';
import { Tooltip } from "react-tooltip";
import { v4 } from "uuid";

function Avatar({ className, src, alt, onClick, closeButton, name="" }) {
  const uniqueId = v4();
  return (
    <div className='avatar-outer-wrp'>
      <div className={`avatar-wrp ${className? className : ''} border`}>
        <img
          alt={ alt }
          src={ src }
          data-tooltip-id={uniqueId}
        />
        <Tooltip id={uniqueId}>{name}</Tooltip>
      </div>
      {closeButton &&
        <button onClick={onClick} className='close-icon'></button>
      }
    </div>
  )
}

export default Avatar