import React from 'react';
import { Link } from 'react-router-dom';

function LeavesCard({ leaveType, leaveStatus, leaveDescription, leaveStartDate, leaveEndDate }) {
  return (
    <Link to="#22" className='card'>
      <div className='card-header'>
        <div className='leave-title large-para ex-bold'>
          {leaveType}
        </div>
        <div className='leave-status'>
          <span className="status green ex-small">{leaveStatus}</span>
        </div>
      </div>
      <div className='card-description'>
        <div className='leave-description-inner-wrp'>
          {leaveDescription}
        </div>
      </div>
      <div className='card-footer'>
        <div className='start-date'>
          <span>Start Date:</span> {leaveStartDate}
        </div>
        <div className='end-date'>
          <span>End Date:</span> {leaveEndDate}
        </div>
      </div>
    </Link>
  )
}

export default LeavesCard