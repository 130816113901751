import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import icon from './../assets/icons/logo.svg';
import Avatar from './../components/common/Avatar';

function LeftSideBar() {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const getName = () => {
    let fullName = localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_name`);
    let firstName = fullName.substring(0, fullName.indexOf(' '));
    if (firstName.length === 0) {
      firstName = fullName;
    }
    return firstName;
  }

  const logoutSubmit = (e) => {
    e.preventDefault();

    axios.post(`/api/logout`).then(res => {
      localStorage.removeItem(`${process.env.REACT_APP_NAME}_auth_id`);
      localStorage.removeItem(`${process.env.REACT_APP_NAME}_auth_token`);
      localStorage.removeItem(`${process.env.REACT_APP_NAME}_auth_name`);
      localStorage.removeItem(`${process.env.REACT_APP_NAME}_auth_image`);
      localStorage.removeItem(`${process.env.REACT_APP_NAME}_auth_client_logo`);
      localStorage.removeItem(`${process.env.REACT_APP_NAME}_auth_role`);
      localStorage.removeItem(`${process.env.REACT_APP_NAME}_current_sub_task_id`);
      localStorage.removeItem(`${process.env.REACT_APP_NAME}_current_parent_task_id`);
      localStorage.removeItem(`${process.env.REACT_APP_NAME}_start_time`);
      localStorage.removeItem(`${process.env.REACT_APP_NAME}_pause_time`);
      if (res.data.status === 200) {
        swal({
          title: "Success",
          text: res.data.message,
          icon: "success",
          className: "success-box",
          timer: 3000,
          buttons: false,
        });
        history.push('/login');
      } else if (res.data.status === 402) {
        swal({
          title: "Success",
          text: res.data.message,
          icon: "success",
          className: "success-box",
          timer: 3000,
          buttons: false,
        });
        history.push('/login');
      }
    });
  }

  return (
    <div className="left-sidebar">
      <div className='logo-wrp'>
        <Link className="navbar-brand" to="/"> <img className='bees-page-icon' src={icon} alt="Bees" /> </Link>
      </div>
      <div className='left-sidebar-inner-wrp'>
        <div className='main-navigation'>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item client">
              <h2 className="accordion-header" id="headingZero">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseZero" aria-expanded="true" aria-controls="collapseOne">
                  CLIENT
                </button>
              </h2>
              <div id="collapseZero" className="accordion-collapse collapse" aria-labelledby="headingZero" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div className="menu-item-wrp">
                    <Link className={`menu-item ${splitLocation[1] === "all-clients" ? "active" : ""}`} to="/all-clients">All Clients</Link>
                  </div>
                  <div className="menu-item-wrp">
                    <Link className={`menu-item ${splitLocation[1] === "add-client" ? "active" : ""}`} to="/add-client">Add Client</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item project">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  PROJECTS
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div className="menu-item-wrp">
                    <Link className={`menu-item ${splitLocation[1] === "all-projects" ? "active" : ""}`} to="/all-projects">All Projects</Link>
                  </div>
                  <div className="menu-item-wrp">
                    <a href="#23" className='menu-item'>Estimates</a>
                  </div>
                  <div className="menu-item-wrp">
                    <Link className={`menu-item ${splitLocation[1] === "add-project" ? "active" : ""}`} to="/add-project">Create a Project</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item tasks">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  TASKS
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div className="menu-item-wrp">
                    <Link className={`menu-item ${splitLocation[1] === "my-tasks" ? "active" : ""}`} to="/my-tasks">My Tasks</Link>
                  </div>
                  <div className="menu-item-wrp">
                    <Link className={`menu-item ${splitLocation[1] === "assignee" ? "active" : ""}`} to="/assignee">Assignee</Link>
                  </div>
                  <div className="menu-item-wrp">
                    <Link className={`menu-item ${splitLocation[1] === "my-assignee-tasks" ? "active" : ""}`} to="/my-assignee-tasks">My Assignee Tasks</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item time">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  TIME
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div className="menu-item-wrp">
                    <a href="/my-time-logs" className='menu-item'>My Time Logs</a>
                  </div>
                  <div className="menu-item-wrp">
                    <a href="/time-logs" className='menu-item'>Time Logs</a>
                  </div>
                  <div className="menu-item-wrp">
                    <a href="#24" className='menu-item'>Add a Time Log</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='logout-wrp'>
          <div className='single-menu-item-wrp'>
            <div className='single-menu-item report'>
              <a href="#44">REPORTS</a>
            </div>
            <div className='single-menu-item users'>
              <a href="#45">USERS</a>
            </div>
            <div className='single-menu-item bees-settings'>
              <Link className={`${splitLocation[1] === "settings" ? "active" : ""}`} to="/settings">SETTINGS</Link>
            </div>
            <div className='single-menu-item logout'>
              <a href="#45" onClick={logoutSubmit}>LOGOUT</a>
            </div>
          </div>

          <div className='logout-item'>
            <div className='image-wrp'>
              <Avatar
                className='avatar-small'
                src={localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_image`) !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_image`)}` : icon}
                alt="Bees"
                name={localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_name`)}
              />
            </div>
            <div className='user-name'>
              {getName()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftSideBar