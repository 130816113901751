import React, { useCallback, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useStopwatch } from "react-timer-hook";
import axios from "axios";
import swal from "sweetalert";
import Modal from "react-modal";
import dateFormat from "dateformat";
import { Draggable, Droppable } from "react-beautiful-dnd";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { differenceInDays, format } from 'date-fns';
import parse from 'html-react-parser';

import icon from "./../../assets/icons/logo.svg";
import playIcon from "./../../assets/icons/play.svg";
import pauseIcon from "./../../assets/icons/pause.svg";
import Avatar from "../common/Avatar";
import HoursToTime from "../../utils/HoursToTime";
import FormDropdown from "./form/FormDropdown";
import CommentsSection from "./CommentsSection";
import LogoTitle from "../common/LogoTitle";

const customStyles = {
    content: {
        top: "0",
        left: "auto",
        right: "0",
        bottom: "0",
        margin: "0",
        zIndex: "9999",
        border: "none",
        borderRadius: "0",
        padding: "0",
        position: "absolute",
        background: "rgb(255, 255, 255)",
        overflow: "auto",
        outline: "none",
    },
};

const SubTaskCard = ({ columns, setMyTasksPageData, type }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [taskStatuses, setTaskStatuses] = useState([]);
    const [timeLogModalIsOpen, setTimeLogModalIsOpen] = useState(false);
    const [parentTask, setParentTask] = useState([]);
    const [subTasks, setSubTasks] = useState([]);
    const [activeSubTask, setActiveSubTask] = useState("");
    const [activeParentTask, setActiveParentTask] = useState("");
    const [startVisible, setStartVisible] = useState(true);
    const [stopVisible, setStopVisible] = useState(false);
    const [showTimeLogForm, setShowTimeLogForm] = useState(false);
    const [timeLogErrorList, setTimeLogErrorList] = useState([]);
    const [timeLogInput, setTimeLog] = useState({
        sub_task_id: "",
        parent_task_id: "",
        task_status_id: "",
        note: "",
        date: new Date().toISOString().slice(0, 10),
        spent_time_hours: 0,
        spent_time_minutes: 0,
        spent_time_seconds: 0,
        non_billable_time_hours: 0,
        non_billable_time_minutes: 0,
        sub_task: [],
    });
    const todayDate = new Date();
    const [timelogDate, setTimelogDate] = useState(todayDate);

    const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
        autoStart: false,
    });

    const resetForm = () => {
        setTimeLog({
        sub_task_id: "",
        parent_task_id: "",
        task_status_id: "",
        note: "",
        date: new Date().toISOString().slice(0, 10),
        spent_time_hours: 0,
        spent_time_minutes: 0,
        spent_time_seconds: 0,
        non_billable_time_hours: 0,
        non_billable_time_minutes: 0,
        sub_task: [],
        });
    };

    const setPageData = useCallback(async () => {
        setLoading(true);
        let isMounted = true;
        if (isMounted) {
        let endpoints = [`/api/view-task-statuses`];
        Promise.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
            ([{ data: taskStatuses }]) => {
            if (taskStatuses.status === 200) {
                setTaskStatuses(taskStatuses.taskStatuses);
            }
            setLoading(false);
            }
        );
        }
        return () => {
        isMounted = false;
        };
    }, []);

    const setTaskData = useCallback(
        async (parentTaskId, subTaskId) => {
        setLoading(true);

        let currentParentTask = localStorage.getItem(
            `${process.env.REACT_APP_NAME}_current_parent_task_id`
        );
        let currentSubTask = localStorage.getItem(
            `${process.env.REACT_APP_NAME}_current_sub_task_id`
        );
        let currentTimeLogStartTime = localStorage.getItem(
            `${process.env.REACT_APP_NAME}_start_time`
        );

        if (currentParentTask && currentSubTask && currentTimeLogStartTime) {
            if (parseInt(currentSubTask) !== parseInt(subTaskId)) {
            swal({
                title: "Error",
                text: "Please Complete your Time Log",
                icon: "error",
                className: "error-box",
                timer: 3000,
                buttons: false,
            });
            }
            parentTaskId = currentParentTask;
            subTaskId = currentSubTask;
        }

        axios.get(`/api/edit-parent-task/${parentTaskId}`).then((res) => {
            if (res.data.status === 200) {
            let subTask = res.data.subTasks.find(
                (item) => item.id === parseInt(subTaskId)
            );
            setParentTask(res.data.parentTask);
            setSubTasks(res.data.subTasks);
            setActiveSubTask(subTaskId);
            setActiveParentTask(parentTaskId);
            resetForm();
            setTimeLog({
                sub_task_id: subTaskId,
                parent_task_id: parentTaskId,
                sub_task: subTask,
                task_status_id: subTask.task_status_id,
                note: "",
                date: new Date().toISOString().slice(0, 10),
                spent_time_hours: 0,
                spent_time_minutes: 0,
                spent_time_seconds: 0,
                non_billable_time_hours: 0,
                non_billable_time_minutes: 0,
            });
            setShowTimeLogForm(true);
            setTimeLogModalIsOpen(true);
            } else if (res.data.status === 404) {
            swal({
                title: "Error",
                text: res.data.message,
                icon: "error",
                className: "error-box",
                timer: 3000,
                buttons: false,
            });
            history.push(`/my-tasks`);
            }
            setLoading(false);
        });
        },
        [history]
    );

    // const viewTask = (parentTaskId, subTaskId) => {
    //     setTaskData(parentTaskId, subTaskId);
    // }

    useEffect(() => {
        setPageData();

        // Checking for local variables and resume/paused
        if (localStorage.getItem(`${process.env.REACT_APP_NAME}_start_time`)) {
        let timePassed;
        const stopwatchOffset = new Date();
        const nowTime = stopwatchOffset.getTime();
        const pauseTime = parseInt(
            localStorage.getItem(`${process.env.REACT_APP_NAME}_pause_time`)
        );
        const startedTime = parseInt(
            localStorage.getItem(`${process.env.REACT_APP_NAME}_start_time`)
        );
        const totalPauseTime = parseInt(
            localStorage.getItem(`${process.env.REACT_APP_NAME}_total_paused_time`)
        );
        let timeToRemove;
        // Check if there it was paused during the timer
        if (totalPauseTime) {
            timeToRemove = totalPauseTime;
        } else {
            timeToRemove = 0;
        }
        // Check if timer is paused when reloading
        if (pauseTime) {
            timePassed =
            (nowTime - startedTime - (nowTime - pauseTime)) / 1000 - timeToRemove;
            stopwatchOffset.setSeconds(
            stopwatchOffset.getSeconds() + Math.round(timePassed)
            );
            reset(stopwatchOffset, false);
            setStartVisible(true);
            setStopVisible(true);
            // Run this if timer not paused
        } else {
            timePassed = (nowTime - startedTime) / 1000 - timeToRemove;
            stopwatchOffset.setSeconds(
            stopwatchOffset.getSeconds() + Math.round(timePassed)
            );
            reset(stopwatchOffset, true);
            setStartVisible(false);
            setStopVisible(true);
        }
        }

        let currentParentTask = localStorage.getItem(
        `${process.env.REACT_APP_NAME}_current_parent_task_id`
        );
        let currentSubTask = localStorage.getItem(
        `${process.env.REACT_APP_NAME}_current_sub_task_id`
        );
        if (currentParentTask && currentSubTask) {
        setTaskData(currentParentTask, currentSubTask);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setActiveTimeLogSubTask = (e, parentTaskId, subTask) => {
        e.preventDefault();
        let currentParentTask = localStorage.getItem(
        `${process.env.REACT_APP_NAME}_current_parent_task_id`
        );
        let currentSubTask = localStorage.getItem(
        `${process.env.REACT_APP_NAME}_current_sub_task_id`
        );
        let currentTimeLogStartTime = localStorage.getItem(
        `${process.env.REACT_APP_NAME}_start_time`
        );

        if (currentParentTask && currentSubTask && currentTimeLogStartTime) {
        if (parseInt(currentSubTask) !== parseInt(subTask.id)) {
            swal({
            title: "Error",
            text: "Please Complete your Time Log",
            icon: "error",
            className: "error-box",
            timer: 3000,
            buttons: false,
            });
            setShowTimeLogForm(false);
        } else {
            setActiveSubTask(currentSubTask);
            setActiveParentTask(currentParentTask);
            resetForm();
            setTimeLog({
            sub_task_id: subTask.id,
            parent_task_id: parentTaskId,
            sub_task: subTask,
            task_status_id: subTask.task_status_id,
            note: "",
            date: new Date().toISOString().slice(0, 10),
            spent_time_hours: 0,
            spent_time_minutes: 0,
            spent_time_seconds: 0,
            non_billable_time_hours: 0,
            non_billable_time_minutes: 0,
            });
            setShowTimeLogForm(true);
        }
        } else {
        setActiveSubTask(subTask.id);
        setActiveParentTask(parentTaskId);
        resetForm();
        setTimeLog({
            sub_task_id: subTask.id,
            parent_task_id: parentTaskId,
            sub_task: subTask,
            task_status_id: "",
            note: "",
            date: new Date().toISOString().slice(0, 10),
            spent_time_hours: 0,
            spent_time_minutes: 0,
            spent_time_seconds: 0,
            non_billable_time_hours: 0,
            non_billable_time_minutes: 0,
        });
        setShowTimeLogForm(true);
        }
    };

    const roundUpMinutes = (minutes) => {
        let roundupMinutes = 15;
        if (minutes === 0) {
        roundupMinutes = 0;
        } else if (minutes > 15 && minutes <= 30) {
        roundupMinutes = 30;
        } else if (minutes > 30 && minutes <= 45) {
        roundupMinutes = 45;
        } else if (minutes > 45 && minutes <= 59) {
        roundupMinutes = 60;
        }
        return roundupMinutes;
    };

    const submitTimeLog = (e) => {
        e.preventDefault();
        setPending(true);
        let hours = timeLogInput.spent_time_hours ? parseInt(timeLogInput.spent_time_hours) : 0;
        let minutes = timeLogInput.spent_time_minutes ? parseInt(timeLogInput.spent_time_minutes) : 0;
        let spentTime = hours + roundUpMinutes(minutes) / 60;
        
        let nonBillableHours = timeLogInput.non_billable_time_hours ? parseInt(timeLogInput.non_billable_time_hours) : 0;
        let nonBillableMinutes = timeLogInput.non_billable_time_minutes ? parseInt(timeLogInput.non_billable_time_minutes) : 0;
        let nonBillableTime = nonBillableHours + roundUpMinutes(nonBillableMinutes) / 60;
        
        const formData = new FormData();
        formData.append("sub_task_id", timeLogInput.sub_task_id);
        formData.append("parent_task_id", timeLogInput.parent_task_id);
        formData.append("task_status_id", timeLogInput.task_status_id);
        formData.append("note", timeLogInput.note);
        formData.append("date", format(timelogDate, "yyyy-MM-dd"));
        formData.append("spent_time", spentTime);
        formData.append("non_billable_time", nonBillableTime);

        axios({
        method: "post",
        url: `/api/add-time-log-data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
        if (res.data.status === 200) {
            swal({
            title: "Success",
            text: res.data.message,
            icon: "success",
            className: "success-box",
            timer: 3000,
            buttons: false,
            });
            // resetForm();
            setTimeLogErrorList([]);
            setTaskData(timeLogInput.parent_task_id, timeLogInput.sub_task_id);
            setMyTasksPageData();

            // remove variables from local storage when submit is successfull
            localStorage.removeItem(`${process.env.REACT_APP_NAME}_start_time`);
            localStorage.removeItem(`${process.env.REACT_APP_NAME}_pause_time`);
            localStorage.removeItem(
            `${process.env.REACT_APP_NAME}_current_sub_task_id`
            );
            localStorage.removeItem(
            `${process.env.REACT_APP_NAME}_current_parent_task_id`
            );
            localStorage.removeItem(
            `${process.env.REACT_APP_NAME}_total_paused_time`
            );
        } else if (res.data.status === 422) {
            swal({
            title: "Error",
            text: "All Fields are mandatory",
            icon: "error",
            className: "error-box",
            timer: 3000,
            buttons: false,
            });
            setTimeLogErrorList(res.data.errors);
        }
        setPending(false);
        });
    };

    // Function to "Start/Pause" the Timer
    const startTimer = () => {
        if (activeSubTask && activeParentTask) {
        localStorage.setItem(
            `${process.env.REACT_APP_NAME}_current_sub_task_id`,
            activeSubTask
        );
        localStorage.setItem(
            `${process.env.REACT_APP_NAME}_current_parent_task_id`,
            activeParentTask
        );
        setStartVisible((current) => !current);
        setStopVisible(true);

        if (!localStorage.getItem(`${process.env.REACT_APP_NAME}_start_time`)) {
            const startTime = new Date();
            localStorage.setItem(
            `${process.env.REACT_APP_NAME}_start_time`,
            startTime.getTime()
            );
        }

        if (startVisible) {
            start();
            if (localStorage.getItem(`${process.env.REACT_APP_NAME}_pause_time`)) {
            const resume = new Date();
            const resumeTime = resume.getTime();
            const pausedTotalTimeTemp =
                (resumeTime -
                parseInt(
                    localStorage.getItem(`${process.env.REACT_APP_NAME}_pause_time`)
                )) /
                1000;
            let pausedTotalTime;
            if (
                localStorage.getItem(
                `${process.env.REACT_APP_NAME}_total_paused_time`
                )
            ) {
                pausedTotalTime =
                pausedTotalTimeTemp +
                parseInt(
                    localStorage.getItem(
                    `${process.env.REACT_APP_NAME}_total_paused_time`
                    )
                );
            } else {
                pausedTotalTime = pausedTotalTimeTemp;
            }
            localStorage.setItem(
                `${process.env.REACT_APP_NAME}_total_paused_time`,
                pausedTotalTime
            );
            localStorage.removeItem(`${process.env.REACT_APP_NAME}_pause_time`);
            }
        } else {
            pause();
            const pauseTime = new Date();
            localStorage.setItem(
            `${process.env.REACT_APP_NAME}_pause_time`,
            pauseTime.getTime()
            );
        }
        } else {
        swal({
            title: "Error",
            text: "Please Select a Task",
            icon: "error",
            className: "error-box",
            timer: 3000,
            buttons: false,
        });
        }
    };

    // Function to "Stop" the timer
    const stopTimer = (e) => {
        e.preventDefault();
        setStopVisible(false);
        setStartVisible(true);
        if (stopVisible) {
        reset(0, false);
        setTimeLog({
            ...timeLogInput,
            spent_time_hours: hours,
            spent_time_minutes: minutes,
            spent_time_seconds: seconds,
        });
        localStorage.removeItem(`${process.env.REACT_APP_NAME}_start_time`);
        localStorage.removeItem(`${process.env.REACT_APP_NAME}_pause_time`);
        localStorage.removeItem(
            `${process.env.REACT_APP_NAME}_current_sub_task_id`
        );
        localStorage.removeItem(
            `${process.env.REACT_APP_NAME}_current_parent_task_id`
        );
        localStorage.removeItem(
            `${process.env.REACT_APP_NAME}_total_paused_time`
        );
        }
    };

    const handleTimeLogInput = (e) => {
        setTimeLog({ ...timeLogInput, [e.target.name]: e.target.value });
    };

    const handleTimeLogDropdown = (item, action) => {
        setTimeLog({ ...timeLogInput, [action.name]: item.value });
    };

    const handleDateChange = (date) => {
        setTimelogDate(date);
    }

    const openTimeLogModal = (e, parentTaskId, subTaskId) => {
        e.preventDefault();
        setTaskData(parentTaskId, subTaskId);
    }

    const closeTimeLogModal = () => {
        setTimeLogModalIsOpen(false);
    }

    const getDropdownOptions = (dataList) => {
        return dataList.map((item) => ({
        value: item.id,
        label: item.name,
        }));
    };

    // const minutesFunction = (minutes) => {
    //     let newMinutes = minutes;
    //     if (newMinutes < 10) {
    //     newMinutes = "0" + newMinutes;
    //     }
    //     return newMinutes;
    // };

    // // Function to add 0 to seconds where less than 10
    // const secondsFunction = (seconds) => {
    //     let newSeconds = seconds;
    //     if (newSeconds < 10) {
    //     newSeconds = "0" + newSeconds;
    //     }
    //     return newSeconds;
    // };

    const redirectToProject = (value) => {
        history.push(`/view-project/${value.parent_task.project.id}?parenttaskid=${value.parent_task_id}&subtaskid=${value.id}`);
    };

    return (
        <>
        {Object.values(columns).map((col) => (
            <Droppable droppableId={col.id} key={col.id}>
            {(provided) => (
                <div
                className={`tasks-column ${col.columnClass} ${
                    loading ? "card-loading" : ""
                }`}
                >
                <div className="tasks-title-wrp">
                    {col.name}
                    <span className="task-count">{col.list.length}</span>
                </div>
                <div
                    className="tasks-wrp"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    {col.list &&
                    col.list.map((task, index) => {
                        return (
                        <Draggable draggableId={`${task.id}`} index={index} key={`${task.id}`}>
                            {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                key={`${task.id}`}
                                className="tasks-inner-wrp"
                                onClick={(e) =>
                                    (localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_role`) === 'qa' && task.task_status.id === 3)
                                    ? redirectToProject(task) : openTimeLogModal(e, task.parent_task.id, task.id)
                                }
                            >
                                <div className={`card ${task.parent_task.task_priority ? `flag-${task.parent_task.task_priority.color}` : ''} ${differenceInDays(new Date(task.due_date), new Date()) < 0 && [1, 2, 4].includes(task.task_status_id) ? 'overdue' : ''}`}>
                                    <div className="card-description">
                                        <LogoTitle
                                            logoSrc={task.parent_task.project.logo ? `${process.env.REACT_APP_BACKEND_URL}/${task.parent_task.project.logo}` : icon}
                                            logoAlt={task.parent_task.project.name}
                                            logoName={task.parent_task.project.name}
                                            title={`${task.parent_task.task_name} - ${task.sub_task_name}`}
                                        />
                                        { type === "full" &&
                                            <div className="description-wrp">
                                                {/* <div className="description html-desc">
                                                    {task.sub_task_description && parse(task.sub_task_description)}
                                                </div> */}
                                                <div className="tagline-wrp">
                                                    <span className="status yellow ex-small">
                                                        {task.parent_task.project.name}
                                                    </span>
                                                    <span className="status yellow ex-small">
                                                        {task.task_type.name}
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    { type === "full" &&
                                        <div className="date-time-wrp">
                                            <div className="date-wrp">
                                            {dateFormat(task.start_date, "mmm dd")} -{" "}
                                            {dateFormat(task.due_date, "mmm dd")}
                                            </div>
                                            <div className="time-wrp">
                                            <span className="spent">
                                                {HoursToTime(task.total_spent_time)}
                                            </span>
                                            <span className="estimated">
                                                ({HoursToTime(task.estimate_time)})
                                            </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            )}
                        </Draggable>
                        );
                    })}
                    {provided.placeholder}
                </div>
                </div>
            )}
            </Droppable>
        ))}
        <Modal
            isOpen={timeLogModalIsOpen}
            onRequestClose={closeTimeLogModal}
            style={customStyles}
            ariaHideApp={false}
            closeTimeoutMS={500}
            className="view-task-modal"
        >
            <div>
            <div className="modal-body">
                <div className="view-task">
                <div className="form-wrp">
                    <div className="form-inner-wrp">
                    <div className="form-content-wrp">
                        <div className="form-left-col">
                        <form id="EDIT_TASK_FORM">
                            <div className="form-left-inner-wrp">
                            <div className="view-task-header">
                                <div className="task-priority">
                                <span
                                    className={`priority-flag flag-${
                                    parentTask.task_priority
                                        ? parentTask.task_priority.color
                                        : ""
                                    }`}
                                ></span>
                                </div>
                                {parentTask.project &&
                                    <Link to={`/view-project/${parentTask.project.id}`} target="_blank">
                                        <div className="image-wrp pt-2 pe-2">
                                            <Avatar
                                                src={parentTask.project.logo ? `${process.env.REACT_APP_BACKEND_URL}/${parentTask.project.logo}` : icon}
                                                alt={parentTask.project.name}
                                                className="avatar"
                                                name={parentTask.project.name}
                                            />
                                        </div>
                                    </Link>
                                }
                                <div className="task-name">
                                    {parentTask.task_name}
                                </div>
                                <div className="task-status">
                                <span
                                    className={`status ${
                                    parentTask.task_status
                                        ? parentTask.task_status.color
                                        : ""
                                    }`}
                                >
                                    {parentTask.task_status
                                    ? parentTask.task_status.name
                                    : ""}
                                </span>
                                </div>
                            </div>
                            <div className="view-task-detail">
                                <div className="assignee-wrp">
                                <div className="task-owner">
                                    <label
                                    htmlFor="owner_id"
                                    className="form-label"
                                    >
                                    Owner
                                    </label>
                                    {parentTask.owner_id && (
                                    <div className="image-wrp">
                                        <Avatar
                                            src={parentTask.owner.profile_picture ? `${process.env.REACT_APP_BACKEND_URL}/${parentTask.owner.profile_picture}` : icon}
                                            alt={parentTask.owner_id}
                                            className="avatar-small"
                                            name={parentTask.owner.name}
                                        />
                                    </div>
                                    )}
                                </div>
                                <div className="task-qa">
                                    <label
                                    htmlFor="qa_assignee_id"
                                    className="form-label"
                                    >
                                    QA
                                    </label>
                                    {parentTask.qa_assignee_id && (
                                    <div className="image-wrp">
                                        <Avatar
                                            src={
                                                parentTask.qa_assignee.profile_picture
                                                ? `${process.env.REACT_APP_BACKEND_URL}/${parentTask.qa_assignee.profile_picture}`
                                                : icon
                                            }
                                            alt={parentTask.qa_assignee_id}
                                            className="avatar-small"
                                            name={parentTask.qa_assignee.name}
                                        />
                                    </div>
                                    )}
                                </div>
                                {subTasks.length > 0 && (
                                    <div className="task-assignees">
                                    <label
                                        htmlFor="sub_task_assignees"
                                        className="form-label"
                                    >
                                        Assignees
                                    </label>
                                    <div className="image-wrp">
                                        {[...new Map(subTasks.map((item) => [item.assignee["id"], item])).values()].map((subTask) => (
                                        <Avatar
                                            src={
                                            subTask.assignee.profile_picture
                                                ? `${process.env.REACT_APP_BACKEND_URL}/${subTask.assignee.profile_picture}`
                                                : icon
                                            }
                                            alt={subTask.assignee_id}
                                            className="avatar-small"
                                            name={subTask.assignee.name}
                                        />
                                        ))}
                                    </div>
                                    </div>
                                )}
                                </div>
                                <div className="track-time-wrp">
                                <div className="track-time-inner-wrp">
                                    <div className="track-time estimate">
                                    <div className="time">
                                        {parentTask.total_estimate_time
                                        ? parentTask.total_estimate_time
                                        : 0}
                                    </div>
                                    <label
                                        htmlFor="total_estimate_time"
                                        className="form-label"
                                    >
                                        Estimate
                                    </label>
                                    </div>
                                    <div className="track-time billable">
                                    <div className="time">
                                        {parentTask.total_spent_time -
                                        parentTask.total_non_billable_time}
                                    </div>
                                    <label
                                        htmlFor="total_billable_time"
                                        className="form-label"
                                    >
                                        Billable
                                    </label>
                                    </div>
                                    <div className="track-time non-billable">
                                    <div className="time">
                                        {parentTask.total_non_billable_time
                                        ? parentTask.total_non_billable_time
                                        : 0}
                                    </div>
                                    <label
                                        htmlFor="total_non_billable_time"
                                        className="form-label"
                                    >
                                        Non-Billable
                                    </label>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="description-detail-wrp">
                                <div className="timeline-wrp">
                                <div className="date-timeline">
                                    <label
                                    htmlFor="task_timeline"
                                    className="form-label"
                                    >
                                    Timeline{" "}
                                    { parentTask.days_left >= 0 ?
                                        <span className="remaining-days">
                                            {`${parentTask.days_left} Days Remaining`}
                                        </span>
                                        :
                                        <span className="behind-days">
                                            {`${Math.abs(parentTask.days_left)} Days Behind`}
                                        </span>
                                    }
                                    </label>
                                    <div className="date">{`${dateFormat(
                                    parentTask.start_date,
                                    "dS mmmm yyyy"
                                    )} - ${dateFormat(
                                    parentTask.due_date,
                                    "dS mmmm yyyy"
                                    )}`}</div>
                                </div>
                                {parentTask.uploads &&
                                    JSON.parse(parentTask.uploads).length > 0 && (
                                    <div className="file-upload-wrp">
                                        <label
                                        htmlFor="uploads"
                                        className="form-label"
                                        >
                                        Files
                                        </label>
                                        <div className="upload-file-wrp">
                                        {JSON.parse(parentTask.uploads).map(
                                            (upload) => (
                                            <a
                                                href={`${process.env.REACT_APP_BACKEND_URL}/${upload}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="upload-file-item"
                                            >{`${upload.split("/").pop()}`}</a>
                                            )
                                        )}
                                        </div>
                                    </div>
                                    )}
                                </div>
                                {parentTask.task_description && 
                                    <div className="description-wrp">
                                    <label
                                        htmlFor="task_description"
                                        className="form-label"
                                    >
                                        Description
                                    </label>
                                    <div className="description-block html-desc">
                                        {parse(parentTask.task_description)}
                                    </div>
                                    </div>
                                }
                            </div>
                            </div>
                        </form>
                        </div>
                        <div className={`form-right-col`}>
                        <div className="title-wrp d-flex justify-between justify-content-between mb-3">
                            <h2>Sub Tasks</h2>
                            <button
                            className="btn btn-table"
                            onClick={() => setShowTimeLogForm(false)}
                            type="button"
                            disabled={!startVisible || stopVisible}
                            >
                            View All Tasks
                            </button>
                        </div>
                        <div className="sub-tasks-popup-wrp">
                            <div className="sub-tasks-popup-inner-wrp">
                            <div className={`sub-tasks-content-wrp`}>
                                <div
                                className="sub-task-accordion"
                                id="subtask-group"
                                >
                                {subTasks.map((subTask) => (
                                    <div
                                    key={subTask.id}
                                    onClick={(e) =>
                                        setActiveTimeLogSubTask(
                                        e,
                                        parentTask.id,
                                        subTask
                                        )
                                    }
                                    className={`accordion-item ${
                                        parseInt(subTask.id) ===
                                        parseInt(activeSubTask)
                                        ? "active-sub-task"
                                        : ""
                                    }`}
                                    >
                                    <div
                                        className="accordion-header"
                                        id={`sub-${subTask.id}`}
                                    >
                                        <div
                                        className="task-header collapsed"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse-sub-${subTask.id}`}
                                        aria-expanded="false"
                                        aria-controls={`collapse-sub-${subTask.id}`}
                                        >
                                        <div className="task-content-wrp">
                                            <span className="accordion-icon"></span>
                                            <div className="avatar-title-wrp">
                                            {subTask.assignee_id && (
                                                <div className="image-wrp">
                                                <Avatar
                                                    src={
                                                    subTask.assignee
                                                        .profile_picture
                                                        ? `${process.env.REACT_APP_BACKEND_URL}/${subTask.assignee.profile_picture}`
                                                        : icon
                                                    }
                                                    alt={subTask.assignee_id}
                                                    className="avatar-small"
                                                    name={subTask.assignee.name}
                                                />
                                                </div>
                                            )}
                                            <div className="task-name">
                                                {subTask.sub_task_name}
                                            </div>
                                            </div>
                                            <div className="status-wrp">
                                            <span
                                                className={`status ${subTask.task_status.color} small`}
                                            >
                                                {subTask.task_status.name}
                                            </span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                ))}
                                </div>
                                {showTimeLogForm && (
                                <div className="sub-task-wrp">
                                    <div className="accordion-item">
                                    <div
                                        className="accordion-header"
                                        id={`sub-${timeLogInput.sub_task.id}`}
                                        onClick={() =>
                                        startVisible &&
                                        !stopVisible &&
                                        setShowTimeLogForm(false)
                                        }
                                    >
                                        <div className="task-header">
                                        <div className="task-content-wrp">
                                            <span className="accordion-icon"></span>
                                            <div className="avatar-title-wrp">
                                            {timeLogInput.sub_task
                                                .assignee_id && (
                                                <div className="image-wrp">
                                                    <Avatar
                                                        src={
                                                        timeLogInput.sub_task.assignee
                                                            .profile_picture
                                                            ? `${process.env.REACT_APP_BACKEND_URL}/${timeLogInput.sub_task.assignee.profile_picture}`
                                                            : icon
                                                        }
                                                        alt={timeLogInput.sub_task.assignee_id}
                                                        className="avatar-small"
                                                        name={timeLogInput.sub_task.assignee.name}
                                                    />
                                                </div>
                                            )}
                                            <div className="task-name">
                                                {
                                                timeLogInput.sub_task
                                                    .sub_task_name
                                                }
                                            </div>
                                            </div>
                                            <div className="status-wrp">
                                            <span
                                                className={`status ${timeLogInput.sub_task.task_status.color} small`}
                                            >
                                                {
                                                timeLogInput.sub_task.task_status
                                                    .name
                                                }
                                            </span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div className="project-detail-row">
                                                <div className="form-outer-group-wrp">
                                                    <div className="form-group-wrp">
                                                        <div className="timeline-main-wrp">
                                                        <div className="timeline-priority-wrp">
                                                            <div className="timeline-wrp">
                                                            <label
                                                                htmlFor="task_timeline"
                                                                className="form-label"
                                                            >
                                                                Timeline{" "}
                                                                { timeLogInput.sub_task.days_left >= 0 ?
                                                                    <span className="remaining-days">
                                                                        {`${timeLogInput.sub_task.days_left} Days Remaining`}
                                                                    </span>
                                                                    :
                                                                    <span className="behind-days">
                                                                        {`${Math.abs(timeLogInput.sub_task.days_left)} Days Behind`}
                                                                    </span>
                                                                }
                                                            </label>
                                                            <div className="date">{`${dateFormat(
                                                                timeLogInput.sub_task
                                                                .start_date,
                                                                "dS mmmm yyyy"
                                                            )} - ${dateFormat(
                                                                timeLogInput.sub_task
                                                                .due_date,
                                                                "dS mmmm yyyy"
                                                            )}`}</div>
                                                            </div>
                                                            <div className="priority-wrp">
                                                            <label
                                                                htmlFor="task_status"
                                                                className="form-label"
                                                            >
                                                                Status
                                                            </label>
                                                            <div className="priority">
                                                                {timeLogInput.sub_task
                                                                .task_status
                                                                ? timeLogInput.sub_task
                                                                    .task_status.name
                                                                : ""}
                                                            </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className='form-group'>
                                                                                                                <label htmlFor="task_type" className="form-label">Type</label>
                                                                                                                <div>{timeLogInput.sub_task.task_type ? timeLogInput.sub_task.task_type.name : ''}</div>
                                                                                                        </div> */}
                                                        <div className="track-time-wrp">
                                                            <div className="track-time-inner-wrp">
                                                            <div className="track-time estimate">
                                                                <div className="time">
                                                                {timeLogInput.sub_task
                                                                    .estimate_time
                                                                    ? timeLogInput.sub_task
                                                                        .estimate_time
                                                                    : 0}
                                                                </div>
                                                                <label
                                                                htmlFor="total_estimate_time"
                                                                className="form-label"
                                                                >
                                                                Estimate
                                                                </label>
                                                            </div>
                                                            <div className="track-time billable">
                                                                <div className="time">
                                                                {timeLogInput.sub_task
                                                                    .total_spent_time -
                                                                    timeLogInput.sub_task
                                                                    .total_non_billable_time}
                                                                </div>
                                                                <label
                                                                htmlFor="total_billable_time"
                                                                className="form-label"
                                                                >
                                                                Billable
                                                                </label>
                                                            </div>
                                                            <div className="track-time non-billable">
                                                                <div className="time">
                                                                {timeLogInput.sub_task
                                                                    .total_non_billable_time
                                                                    ? timeLogInput.sub_task
                                                                        .total_non_billable_time
                                                                    : 0}
                                                                </div>
                                                                <label
                                                                htmlFor="total_non_billable_time"
                                                                className="form-label"
                                                                >
                                                                Non-Billable
                                                                </label>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        {/* <div className='form-group'>
                                                                                                            <label htmlFor="assignee_id" className="form-label">Assignee</label>
                                                                                                    </div> */}
                                                        {timeLogInput.sub_task.uploads &&
                                                        JSON.parse(
                                                            timeLogInput.sub_task.uploads
                                                        ).length > 0 && (
                                                            <div className="file-upload-wrp">
                                                            <label
                                                                htmlFor="uploads"
                                                                className="form-label"
                                                            >
                                                                Files
                                                            </label>
                                                            <div className="upload-file-wrp">
                                                                {JSON.parse(
                                                                timeLogInput.sub_task
                                                                    .uploads
                                                                ).map((upload) => (
                                                                <a
                                                                    href={`${process.env.REACT_APP_BACKEND_URL}/${upload}`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="upload-file-item"
                                                                >{`${upload
                                                                    .split("/")
                                                                    .pop()}`}</a>
                                                                ))}
                                                            </div>
                                                            </div>
                                                        )}
                                                        {timeLogInput.sub_task.sub_task_description && 
                                                            <div className="description-wrp">
                                                                <label
                                                                    htmlFor="sub_task_description"
                                                                    className="form-label"
                                                                >
                                                                    Description
                                                                </label>
                                                                <div className="description html-desc">
                                                                    {parse(timeLogInput.sub_task.sub_task_description)}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="time-tracker-wrp">
                                                        {parseInt(
                                                        localStorage.getItem(
                                                            `${process.env.REACT_APP_NAME}_auth_id`
                                                        )
                                                        ) ===
                                                        parseInt(
                                                            timeLogInput.sub_task
                                                            .assignee_id
                                                        ) &&
                                                        timeLogInput.sub_task.task_status
                                                            .value !== "completed" && (
                                                            <form
                                                            encType="multipart/form-data"
                                                            onSubmit={submitTimeLog}
                                                            id="ADD_TIME_LOG_FORM"
                                                            >
                                                            <div className="form-fields-wrp">
                                                                <div className="form-fields-inner-wrp">
                                                                <div className="form-group spent-time position-relative">
                                                                    <label htmlFor="spent_time_hours" className="form-label">Spent Time</label>
                                                                    {/* <span className="tt-spent-time">{`${hours} : ${minutesFunction(
                                                                    minutes
                                                                    )} : ${secondsFunction(
                                                                    seconds
                                                                    )}`}</span> */}
                                                                    <div className="input-group time-tracker">
                                                                    <input
                                                                        type="number"
                                                                        name="spent_time_hours"
                                                                        onChange={handleTimeLogInput}
                                                                        value={hours ? hours : timeLogInput.spent_time_hours}
                                                                        min="0"
                                                                        max="999"
                                                                        step="1"
                                                                        id="spent_time_hours"
                                                                        className="form-control text-center"
                                                                        placeholder="00"
                                                                    />
                                                                    <span className="d-flex justify-content-center align-items-center middle-dots">
                                                                        :
                                                                    </span>
                                                                    <input
                                                                        type="number"
                                                                        name="spent_time_minutes"
                                                                        onChange={handleTimeLogInput}
                                                                        value={minutes ? minutes : timeLogInput.spent_time_minutes}
                                                                        min="0"
                                                                        max="59"
                                                                        step="1"
                                                                        id="spent_time_minutes"
                                                                        className="form-control text-center"
                                                                        placeholder="00"
                                                                        aria-describedby="start-pause-button"
                                                                    />
                                                                    <span className="d-flex justify-content-center align-items-center middle-dots">
                                                                        :
                                                                    </span>
                                                                    <input
                                                                        type="number"
                                                                        name="spent_time_seconds"
                                                                        onChange={handleTimeLogInput}
                                                                        value={seconds ? seconds : timeLogInput.spent_time_seconds}
                                                                        min="0"
                                                                        max="59"
                                                                        step="1"
                                                                        id="spent_time_seconds"
                                                                        className="form-control text-center"
                                                                        placeholder="00"
                                                                        aria-describedby="start-pause-button"
                                                                    />
                                                                    {/* onKeyDown={(e) => e.preventDefault()} */}
                                                                    <button
                                                                        className="btn btn-outline-secondary"
                                                                        type="button"
                                                                        id="start-pause-button"
                                                                        onClick={() => startTimer()}
                                                                    >
                                                                        {startVisible ? <img src={playIcon} alt="play"/> : <img src={pauseIcon} alt="pause"/>}
                                                                    </button>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group non-billable">
                                                                    <label htmlFor="non_billable_time_hours" className="form-label">Non-billable</label>
                                                                    <div className="input-group time-tracker-non-billable">
                                                                    <input
                                                                        type="number"
                                                                        name="non_billable_time_hours"
                                                                        onChange={handleTimeLogInput}
                                                                        value={timeLogInput.non_billable_time_hours}
                                                                        min="0"
                                                                        max="999"
                                                                        step="1"
                                                                        id="non_billable_time_hours"
                                                                        className="form-control text-center"
                                                                        placeholder="Hours"
                                                                    />
                                                                    <span className="d-flex justify-content-center align-items-center middle-dots">
                                                                        :
                                                                    </span>
                                                                    <input
                                                                        type="number"
                                                                        name="non_billable_time_minutes"
                                                                        onChange={handleTimeLogInput}
                                                                        value={timeLogInput.non_billable_time_minutes}
                                                                        min="0"
                                                                        max="59"
                                                                        step="1"
                                                                        id="non_billable_time_minutes"
                                                                        className="form-control text-center"
                                                                        placeholder="Minutes"
                                                                    />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group task-status">
                                                                    <label htmlFor="task_status_id" className="form-label">Task Status</label>
                                                                    <FormDropdown
                                                                        value={timeLogInput.task_status_id}
                                                                        name="task_status_id"
                                                                        id="task_status_id"
                                                                        options={getDropdownOptions(taskStatuses)}
                                                                        isSearchable={true}
                                                                        isDisabled={pending}
                                                                        onChange={handleTimeLogDropdown}
                                                                        placeholder="Task Status"
                                                                    />
                                                                </div>
                                                                <div className="form-group date-wrap">
                                                                <DatePicker
                                                                    selected={timelogDate}
                                                                    onChange={handleDateChange}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    className="form-control"
                                                                    maxDate={todayDate}
                                                                />
                                                                </div>
                                                                </div>
                                                                <div className="form-fields-inner-wrp">
                                                                <div className="form-group track-notes">
                                                                    <label htmlFor="note" className="form-label">Time Track Notes</label>
                                                                    <input
                                                                        type="text"
                                                                        name="note"
                                                                        onChange={handleTimeLogInput}
                                                                        value={timeLogInput.note}
                                                                        className="form-control"
                                                                        id="note"
                                                                    />
                                                                </div>
                                                                <div className="form-group button-wrp">
                                                                    <div className="del-update-btn-wrp">
                                                                    {stopVisible ? (
                                                                        <button
                                                                        type="button"
                                                                        onClick={(e) =>
                                                                            stopTimer(e)
                                                                        }
                                                                        className="colored-btn warning"
                                                                        >
                                                                        Stop Timer
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                        disabled={
                                                                            pending
                                                                            ? "disabled"
                                                                            : ""
                                                                        }
                                                                        type="submit"
                                                                        className="colored-btn success"
                                                                        >
                                                                        <span
                                                                            className={`spinner-border spinner-border-sm me-1 ${
                                                                            !pending
                                                                                ? "d-none"
                                                                                : ""
                                                                            }`}
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                        Update Task
                                                                        </button>
                                                                    )}
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                {Object.values(
                                                                timeLogErrorList
                                                                ).length > 0 && (
                                                                <div className="pb-3">
                                                                    {Object.values(
                                                                    timeLogErrorList
                                                                    ).map((error) => (
                                                                    <div>
                                                                        <small className="text-danger">
                                                                        {error}
                                                                        </small>
                                                                    </div>
                                                                    ))}
                                                                </div>
                                                                )}
                                                            </div>
                                                            </form>
                                                        )}
                                                    </div>
                                                    <CommentsSection
                                                        pending={pending}
                                                        allComments={timeLogInput.sub_task.comments !== null ? JSON.parse(timeLogInput.sub_task.comments) : []}
                                                        parentTaskId={timeLogInput.sub_task.parent_task_id}
                                                        subTaskId={timeLogInput.sub_task.id}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                )}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </Modal>
        </>
    );
}

export default SubTaskCard;