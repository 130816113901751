import React from 'react';
import Select from "react-select";

const FormDropdown = ({
  value,
  name,
  id,
  options,
  isSearchable = false,
  isClearable = false,
  isDisabled = false,
  onChange,
  placeholder
}) => {
  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      value={value ? options.filter((option) => option.value === value) : null}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isDisabled={isDisabled}
      onChange={onChange}
      name={name}
      id={id}
      options={options}
      placeholder={placeholder}
      menuPlacement='auto'
    />
  )
}

export default FormDropdown