import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

import ProjectCard from "../../common/ProjectCard";
import Header from '../../../layouts/Header';
import FloatingTimer from '../../common/FloatingTimer';
import FormDropdown from '../../common/form/FormDropdown';

function AllProjects() {
  const searchTextInput = useRef(null);
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);
  const [errorList, setError] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [clients, setClients] = useState([]);
  const [projectPhases, setProjectPhases] = useState([]);
  const [projectCategories, setProjectCategories] = useState([]);
  const [searchInput, setSearch] = useState({
    search_text: '',
    client_id: '',
  });  

  const getProjects = useCallback(async () => {
    setPending(true);
    setLoading(true);
    setError([]);

    let searchText = searchInput.search_text ? searchInput.search_text : 'null';
    let clientId = searchInput.client_id ? searchInput.client_id : 'null';

    axios.get(`/api/get-all-projects/${searchText}/${clientId}`).then(res => {
      if (res.data.status === 200) {
        setProjectList(res.data.projects);
      } else if (res.data.status === 404) {
        swal({
          title: "Error",
          text: res.data.message,
          icon: "error",
          className: "error-box",
          timer: 3000,
          buttons: false,
        });
      }  else if (res.data.status === 405) {
        swal({
          title: "Error",
          text: res.data.message,
          icon: "error",
          className: "error-box",
          timer: 3000,
          buttons: false,
        });
      }
      setPending(false);
      setLoading(false);
    });
  }, [searchInput.client_id, searchInput.search_text]);

  const setPageData = useCallback(async () => {
    setLoading(true);
    let isMounted = true;
    if (isMounted) {
      let endpoints = [
        `/api/all-clients`,
        `/api/all-project-phases`,
        `/api/all-project-categories`
      ];
      Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
      .then(([
        {data: allClients},
        {data: projectPhases}, 
        {data: projectCategories}
      ]) => {
        if (allClients.status === 200) {
          setClients(allClients.clients);
        }
        if (projectPhases.status === 200) {
          setProjectPhases(projectPhases.projectPhases);
        }
        if (projectCategories.status === 200) {
          setProjectCategories(projectCategories.projectCategories);
        }
        setLoading(false);
      });
    }
    return () => {
        isMounted = false
    }
  }, []);

  useEffect(() => {
    setPageData();
    getProjects();
    if (searchTextInput.current) {
      searchTextInput.current.focus();
    }
  }, [getProjects, searchInput, setPageData]);

  const handleSearchText = (e) => {
    setSearch({ ...searchInput, search_text: e.target.value });
  }

  const handleClient = (value) => {
    setSearch({ ...searchInput, client_id: value });
  }

  const getDropdownOptions = (dataList) => {
    let options = dataList.map(item => ({
      value: item.id,
      label: item.name
    }))
    return options;
  }

  return (
    <div className={`${loading ? 'page-loading' : ''}`}>
      <Header 
        title="All Projects" 
        type="variation-2" 
        headerButton={true}
        headerButtonType="button"
        headerButtonUrl="/add-project"
        headerButtonTitle="Create a Project"
      />
      <div className='main-content all-projects-wrp'>
        <div className='middle-content'>
          <div className="form-wrp top-form-wrp">
            <form>
              <div className="form-row">
                <div className="form-group col">
                  <input
                    type="text"
                    // disabled={ pending ? 'disabled' : '' }
                    ref={searchTextInput}
                    name="search_text"
                    onChange={handleSearchText}
                    value={searchInput.search_text}
                    className='form-control'
                    id="search_text"
                    placeholder="Search"
                  />
                  <small className='text-danger'>{errorList.search_text}</small>
                </div>
                <div className="form-group col">
                  <FormDropdown
                    value={searchInput.client_id}
                    name="client_id"
                    id="client_id"
                    options={getDropdownOptions(clients)}
                    isSearchable={true}
                    isClearable={true}
                    isDisabled={pending}
                    onChange={(options) => !options ? handleClient("") : handleClient(options.value)}
                    placeholder="Client"
                  />
                  <small className='text-danger'>{errorList.client_id}</small>
                </div>
              </div>
            </form>
          </div>
          <div className='card-wrp'>
            {
              projectList && projectList.map(project => {
                const phases = project.phases && project.phases.length > 0 ? 
                  projectPhases.filter(item => project.phases.includes(item.id)).map(phase => {
                    return ({
                      id: phase.id,
                      label: phase.name
                    })
                  }) : [];
              
                const categories = project.categories && project.categories.length > 0 ? 
                  projectCategories.filter(item => project.categories.includes(item.id)).map(category => {
                    return ({
                      id: category.id,
                      label: category.name
                    })
                  }) : [];

                const totalSpent = project.total_spent ? project.total_spent : 0;
                const totalEstimate = project.total_estimate ? project.total_estimate : 0;
                const totalTasks = project.total_tasks ? project.total_tasks : 0;
                const totalCompletedTasks = project.total_completed_tasks ? project.total_completed_tasks : 0;
                const daysLeft = project.days_left ? project.days_left : 0;

                return (
                  <div className='card-item-wrp'>
                    <ProjectCard 
                      project={project} 
                      phases={phases} 
                      categories={categories}
                      totalSpent={totalSpent}
                      totalEstimate={totalEstimate}
                      daysLeft={daysLeft}
                      totalTasks={totalTasks}
                      totalCompletedTasks={totalCompletedTasks}
                    />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <FloatingTimer />
    </div>
  )
}

export default AllProjects