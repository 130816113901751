import React, { useState, useRef, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import upload from "./../../../assets/icons/logo-upload.svg";
import FormDropdown from "../../common/form/FormDropdown";

function EditClient({clientId}) {
  const hiddenFileInput = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);
  const [image, setImage] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [clientEditData, setClientEditData] = useState([]);
  const [selectedTier, setSelectedTier] = useState([]);
  const [errorList, setError] = useState([]);

  const setEditClientData = useCallback(
    async () => {
      setLoading(true);

      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.get(`/api/edit-client/${clientId}`).then((res) => {
          if (res.data.status === 200) {
            setImage({ ...image, "logo": res.data.client.logo });
            let startYear = res.data.client.start_year;
            if (res.data.client.status === 1) {
              res.data.client.status = true;
            } else {
              res.data.client.status = false;
            }
            setClientEditData({...res.data.client, start_year: startYear ? startYear.toString() : ''});
          }
          setLoading(false);
        });
      });
    },
    // eslint-disable-next-line
    [clientId]
  );

  const setPageData = useCallback(async () => {
    setLoading(true);
    let isMounted = true;
    if (isMounted) {
        let endpoints = [
            `/api/all-client-tiers`,
        ];
        Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
        .then(([
            {data: allClientTiers},
        ]) => {
            if (allClientTiers.status === 200) {
              setSelectedTier(allClientTiers.allClientTiers);
            }
            setLoading(false);
        });
    }
    return () => {
        isMounted = false
    }
  }, []);

  useEffect(() => {
    setEditClientData();
    setPageData();
  }, [setEditClientData, setPageData]);

  const updateClient = (e) => {
    e.preventDefault();
    setPending(true);
    let isValid = true;

    if (clientEditData.name.length === 0) {
      setError({
        ...errorList,
        name: "Please Select a Name for Client",
      });
      isValid = false;
      setPending(false);
    } else if (clientEditData.slug.length === 0) {
      setError({
        ...errorList,
        slug: "Please Select a Slug for Client",
      });
      isValid = false;
      setPending(false);
    }

    const formData = new FormData();
    formData.append("slug", clientEditData.slug);
    formData.append("name", clientEditData.name);
    formData.append("description", clientEditData.description);
    formData.append("url", clientEditData.url);
    formData.append("logo", image.logo);
    formData.append("status", clientEditData.status ? 1 : 0);
    formData.append("email", clientEditData.email ? clientEditData.email : "");
    formData.append("phone", clientEditData.phone ? clientEditData.phone : "");
    formData.append("start_year", clientEditData.start_year ? new Date(clientEditData.start_year).getFullYear() : "");
    formData.append("client_tier", clientEditData.client_tier ? clientEditData.client_tier : "");
    formData.append('_method', 'PUT');

    if (isValid) {
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios.post(`/api/update-client/${clientId}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
          if (res.data.status === 200) {
            swal({
              title: "Success",
              text: res.data.message,
              icon: "success",
              className: "success-box",
              timer: 3000,
              buttons: false,
            });
          } else if (res.data.status === 422) {
            swal({
                  title: "Error",
                  text: "404 Error",
                  icon: "error",
                  className: "error-box",
                  timer: 3000,
                  buttons: false,
              });
          } else if (res.data.status === 404) {
              swal({
                  title: "Error",
                  text: "404 Error",
                  icon: "error",
                  className: "error-box",
                  timer: 3000,
                  buttons: false,
              });
          }
        });
        setPending(false);
      });
    } else {
      setPending(false);
    }
  };

  const deleteClient = (e, id) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record",
      className: "delete-box",
      buttons: {
        delete: {
          text: "Yes, Delete now",
          className: "colored-btn warning",
        },
        archive: {
          text: "Archive Instead",
          className: "colored-btn gentle",
        },
      },
      dangerMode: true,
    }).then((result) => {
      if (result === "delete") {
        axios.delete(`/api/delete-client/${clientId}`).then((res) => {
          if (res.data.status === 200) {
            swal({
              title: "Success",
              text: res.data.message,
              icon: "success",
              className: "success-box",
              timer: 3000,
              buttons: false,
            });
            history.push("/all-clients");
          } else if (res.data.status === 404) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          } else if (res.data.status === 405) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          }
        });
      } else if (result === "archive") {
        axios.delete(`/api/archive-client/${clientId}`).then((res) => {
          if (res.data.status === 200) {
            swal({
              title: "Success",
              text: res.data.message,
              icon: "success",
              className: "success-box",
              timer: 3000,
              buttons: false,
            });
            history.push("/all-clients");
          } else if (res.data.status === 404) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          } else if (res.data.status === 405) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
              className: "error-box",
              timer: 3000,
              buttons: false,
            });
          }
        });
      }
    });
  };

  const handleInput = (e) => {
    setClientEditData({ ...clientEditData, [e.target.name]: e.target.value });
  };

  const handleDropdown = (item, action) => {
    setClientEditData({ ...clientEditData, [action.name]: item.value });
  }

  const getDropdownOptions = (dataList) => {
    let options = dataList.map(item => ({
      value: item.value,
      label: item.name
    }))
    return options;
  }

  const handleLogo = (e) => {
    setImage({ logo: e.target.files[0] });
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleUploadClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleCheckbox = (e) => {
    const { value, checked } = e.target;
    setClientEditData({ ...clientEditData, [value]: checked });
  };

  return (
    <form className={`${loading ? 'page-loading' : ''}`} encType="multipart/form-data" onSubmit={updateClient} id="CLIENT_FORM">
      <div className="form-inner-wrp">
        <div className="form-content-wrp">
          <div className="form-left-col">
            <div className="form-group">
              <label htmlFor="logo" className="form-label">
                Logo
              </label>
              <div
                className={`logo-wrp ${
                  selectedImage ? "project-logo-wrp" : ""
                }`}
                onClick={handleUploadClick}
              >
                <img
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : image.logo
                      ? `${process.env.REACT_APP_BACKEND_URL}/${image.logo}`
                      : upload
                  }
                  className={selectedImage ? "project-logo" : ""}
                  alt=""
                />
              </div>
              <input
                type="file"
                name="logo"
                ref={hiddenFileInput}
                onChange={handleLogo}
                style={{ display: "none" }}
                className="form-control"
                id="logo"
              />
              <small className="text-danger">
                {/* {errorList.logo} */}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="client_name" className="form-label">
                Client Name
              </label>
              <input
                type="text"
                name="name"
                onChange={handleInput}
                value={clientEditData.name}
                className="form-control"
                id="client_name"
              />
              <small className="text-danger">
                {errorList.name}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="contact_no" className="form-label">
                Contact no
              </label>
              <input
                type="text"
                name="phone"
                onChange={handleInput}
                value={clientEditData.phone}
                className="form-control"
                id="contact_no"
              />
              <small className="text-danger">
                {/* {errorList.phone} */}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="client_email" className="form-label">
                Email
              </label>
              <input
                type="email"
                name="email"
                onChange={handleInput}
                value={clientEditData.email}
                className="form-control"
                id="client_email"
              />
              <small className="text-danger">
                {/* {errorList.email} */}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="client_slug" className="form-label">
                Slug
              </label>
              <input
                type="text"
                name="slug"
                onChange={handleInput}
                value={clientEditData.slug}
                className="form-control"
                id="client_slug"
              />
              <small className="text-danger">
                {errorList.slug}
              </small>
            </div>

            <div className="form-group">
              <label htmlFor="start_year" className="form-label">
                Client Joined Year
              </label>
              <DatePicker
                selected={clientEditData.start_year}
                onChange={(date) => setClientEditData({ ...clientEditData, "start_year": date })}
                showYearPicker
                dateFormat="yyyy"
                className="form-control"
              />
              <small className="text-danger">
                {/* {errorList.start_year} */}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="client_tier" className="form-label">
                Client Tier
              </label>
              <FormDropdown
                value={clientEditData.client_tier}
                name="client_tier"
                id="client_tier"
                options={getDropdownOptions(selectedTier)}
                isSearchable={true}
                isDisabled={pending}
                onChange={handleDropdown}
                placeholder="Client Tier"
              />
              <small className="text-danger">
                {/* {errorList.client_tier} */}
              </small>
            </div>
          </div>
          <div className="form-right-col">
            <div className="title-wrp">
              <h2>Client Details</h2>
              <p>General Information about the client</p>
            </div>
            <div className="project-detail-row pd-first cd-first">
              <div className="form-group col col-12 description-col">
                <div className="form-group">
                  <label htmlFor="description" className="form-label">
                    Brief Description about the Project
                  </label>
                  <textarea
                    name="description"
                    onChange={handleInput}
                    value={clientEditData.description}
                    className="form-control"
                    id="description"
                    style={{ height: "245px" }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="project-detail-row pd-second cd-second">
              <div className="form-group col col-12 col-xl-7 px-3">
                <div className="title-wrp">
                  <h2>Client Details</h2>
                  <p>General Information about the client</p>
                </div>
                <div className="form-group">
                  <div className="input-label-left-variation">
                    <label htmlFor="client_url" className="form-label">
                      Website URL
                    </label>
                    <input
                      type="text"
                      name="url"
                      onChange={handleInput}
                      value={clientEditData.url}
                      className="form-control"
                      id="client_url"
                    />
                    <small className="text-danger">
                      {/* {errorList.staging_url} */}
                    </small>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <input type="checkbox" name="phase" onChange={handleCheckbox} value="status" checked={clientEditData.status} className="form-check-input" id="status" />
                    <label htmlFor="status" className="form-check-label">Status</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-wrp">
          <button
            type="button"
            onClick={(e) => deleteClient(e, clientId)}
            className="colored-btn warning delete with-icon"
          >
            Delete Client
          </button>
          <button
            disabled={pending ? "disabled" : ""}
            type="submit"
            className="btn btn-primary"
          >
            <span
              className={`spinner-border spinner-border-sm me-3 ${
                !pending ? "d-none" : ""
              }`}
              role="status"
              aria-hidden="true"
            ></span>
            Update Client
          </button>
        </div>
      </div>
    </form>
  )
}

export default EditClient