import React from 'react';

import CardStats from './CardStats';
import CardProgress from './CardProgress';

function StatsCard({ project, totalSpent, totalEstimate, daysLeft, totalTasks, totalCompletedTasks }) {
  return (
    <div key={project.id} className='card'>
      <CardStats
        totalSpent={totalSpent}
        totalEstimate={totalEstimate}
        daysLeft={daysLeft} 
      />
      <CardProgress
        progressLabel={`${totalCompletedTasks}/${totalTasks} Completed`}
        progressPercentage={`${((totalCompletedTasks/totalTasks)*100).toFixed(0)}`}
      />
    </div>
  )
}

export default StatsCard