import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './assets/scss/main.scss';

import axios from 'axios';
import AppRoute from './AppRoute';
import Page404 from './components/pages/errors/Page404';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config){
  const token = localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_token`);
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

function App() {
  return (
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <AppRoute path='/' name="Home" />
          <Route path="*" element={<Page404 />} />
        </Switch>
      </Router>
  );
}

export default App;
