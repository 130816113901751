import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

const ChangePassword = (props) => {
    const history = useHistory();
    const [pending, setPending] = useState(false);

    if (!localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_token`)) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem(`${process.env.REACT_APP_NAME}_auth_role`))) {
        history.push('/403');
    }
    
    const [changePasswordInput, setChangePassword] = useState({
        old_password: '',
        new_password: '',
        confirm_password: '',
        error_list: []
    });

    const handleInput = (e) => {
        setChangePassword({ ...changePasswordInput, [e.target.name]: e.target.value });
    }

    const changePasswordSubmit = (e) => {
        e.preventDefault();
        setPending(true);

        const data = {
            old_password: changePasswordInput.old_password,
            new_password: changePasswordInput.new_password,
            confirm_password: changePasswordInput.confirm_password,
        }

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.put(`/api/change-password`, data).then(res => {
                if (res.data.status === 200) {
                    localStorage.removeItem(`${process.env.REACT_APP_NAME}_auth_id`);
                    localStorage.removeItem(`${process.env.REACT_APP_NAME}_auth_token`);
                    localStorage.removeItem(`${process.env.REACT_APP_NAME}_auth_name`);
                    localStorage.removeItem(`${process.env.REACT_APP_NAME}_auth_image`);
                    localStorage.removeItem(`${process.env.REACT_APP_NAME}_auth_client_logo`);
                    localStorage.removeItem(`${process.env.REACT_APP_NAME}_auth_role`);
                    swal({
                      title: "Success",
                      text: res.data.message,
                      icon: "success",
                      className: "success-box",
                      timer: 3000,
                      buttons: false,
                    });
                    history.push('/');
                    setPending(false);
                } else if (res.data.status === 401) {
                  swal("Warning", res.data.message, "error");
                  setPending(false);
                } else if (res.data.status === 402) {
                    swal("Warning", res.data.message, "warning");
                    setPending(false);
                } else {
                    setChangePassword({ ...changePasswordInput, error_list: res.data.validation_errors });
                    setPending(false);
                }
            });
        });
    }

    return (
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-xl-9 mx-auto">
              <div className="card flex-row my-5 border-0 shadow rounded-3 overflow-hidden">
                <div className="card-img-left d-none d-md-flex">
                </div>
                <div className="card-body p-4 p-sm-5">
                  <h5 className="card-title text-center mb-5 fw-light fs-5">Change Password</h5>
                  <form  onSubmit={changePasswordSubmit}>

                    <div className="form-floating mb-3">
                      <input  id="floatingInputUsername" placeholder="Current Password" type='password' name='old_password' onChange={handleInput} value={changePasswordInput.old_password} className='form-control' />
                      <label htmlFor="floatingInputUsername">Current Password</label>
                      <label>{changePasswordInput.error_list.old_password}</label>
                    </div>

                    <div className="form-floating mb-3">
                      <input id="floatingInputEmail" placeholder="New Password" type='password' name='new_password' onChange={handleInput} value={changePasswordInput.new_password} className='form-control' />
                      <label htmlFor="floatingInputEmail">New Password</label>
                      <label>{changePasswordInput.error_list.new_password}</label>
                    </div>

                    <div className="form-floating mb-3">
                      <input id="floatingPassword" placeholder="Retype New Password" type='password' name='confirm_password' onChange={handleInput} value={changePasswordInput.confirm_password} className='form-control' />
                      <label id="floatingPassword" placeholder="Confirm New Password" htmlFor="floatingPassword">Confirm New Password</label>
                      <label>{changePasswordInput.error_list.confirm_password}</label>
                    </div>

                    <hr/>

                    <div className="d-grid mb-2">
                      <button disabled = {pending ? 'disabled' : ''} className="btn btn-lg btn-primary btn-login fw-bold text-uppercase" type="submit">
                        <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}

export default ChangePassword;