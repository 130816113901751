import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import logo from './../../../assets/icons/logo.svg';

const Register = () => {
    const history = useHistory();
    const [pending, setPending] = useState(false);
    const [registerInput, setRegister] = useState({
        name: '',
        email: '',
        password: '',
        error_list: []
    });

    const handleInput = (e) => {
        setRegister({...registerInput, [e.target.name]: e.target.value});
    }

    const registerSubmit = (e) => {
        e.preventDefault();
        setPending(true);

        const data = {
            name: registerInput.name,
            email: registerInput.email,
            password: registerInput.password,
        }

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/register`, data).then(res => {
                if (res.data.status === 200) {
                    swal({
                      title: "Success",
                      text: res.data.message,
                      icon: "success",
                      className: "success-box",
                      timer: 3000,
                      buttons: false,
                    });
                    setPending(false);
                    history.push('/');
                } else {
                    setRegister({ ...registerInput, error_list: res.data.validation_errors});
                    setPending(false);
                }
            });
        });
    }

    return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className='login-wrp'>
                <div className="logo text-center">
                  <img src={logo} alt="Bees" />
                </div>
                <div className="card overflow-hidden">
                  <div className="card-body p-4 p-sm-5">
                    <h2 className="card-title text-center">Register</h2>
                    <form  onSubmit={registerSubmit}>
                      <div className="form-floating mb-3">
                        <input id="floatingInputUsername" placeholder="Full Name" type='text' name='name' onChange={handleInput} value={registerInput.name} className='form-control' required autoFocus/>
                        <label htmlFor="floatingInputUsername">Full Name</label>
                        {registerInput.error_list.name && <label className='error-message'>{registerInput.error_list.name}</label>}
                      </div>
                      <div className="form-floating mb-3">
                        <input id="floatingInputEmail" placeholder="name@example.com" type='email' name='email' onChange={handleInput} value={registerInput.email} className='form-control' />
                        <label htmlFor="floatingInputEmail">Email address</label>
                        {registerInput.error_list.email && <label>{registerInput.error_list.email}</label>}
                      </div>
                      <div className="form-floating mb-3">
                        <input id="floatingPassword" placeholder="Password" type='password' name='password' onChange={handleInput} value={registerInput.password} className='form-control' />
                        <label htmlFor="floatingPassword">Password</label>
                        {registerInput.error_list.password && <label>{registerInput.error_list.password}</label>}
                      </div>
                      <div className="btn-wrp">
                        <button disabled = {pending ? 'disabled' : ''} className="btn btn-lg btn-primary btn-login fw-bold text-uppercase" type="submit">
                          <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                          Register
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='reset-link-wrp'>
                  <div className='reset-link text-center'>
                    <Link className="mt-2" to="/login">Already Have an Account? Log In</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}

export default Register;