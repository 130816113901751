import React, { useEffect, useState, useCallback } from 'react';
import Header from '../../../layouts/Header';
import axios from "axios";

import ClientCard from "../../common/ClientCard";
import FloatingTimer from '../../common/FloatingTimer';

const AllClients = () => {
  const [loading, setLoading] = useState(false);
  const [clientsList, setClientsList] = useState([]);

  const setPageData = useCallback(async () => {
    setLoading(true);
    let isMounted = true;

    if (isMounted) {
      axios.get(`/api/all-clients`).then(res => {
        if (res.data.status === 200) {
          setClientsList(res.data.clients);
        }
        setLoading(false);
      });
    }
    return () => {
      isMounted = false
    }
  }, []);

  useEffect(() => {
    setPageData();
  }, [setPageData]);

  return (
    <div className={`${loading ? 'page-loading' : ''}`}>
      <Header
        title="All Clients"
        type="variation-2"
        headerButton={true}
        headerButtonType="button"
        headerButtonUrl="/add-client"
        headerButtonTitle="+ Add a Client"
      />
      <div className='main-content all-clients-wrp'>
        <div className='middle-content'>
          <div className='card-wrp'>
            {
              clientsList && clientsList.map(client => {
                return (
                  <div key={client.id} className='card-item-wrp'>
                    <ClientCard
                      clientLogo={client.logo}
                      clientName={client.name}
                      clientTier={client.client_tier}
                      clientDescription={client.description}
                      clientSince={client.start_year}
                      clientStatus={client.status===1 ? true : false}
                      clientId={client.id}
                    />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <FloatingTimer />
    </div>
  );
}

export default AllClients;