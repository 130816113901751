import React from "react";

const TabNavItem = ({ id, title, activeTab, setActiveTab }) => {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <li key={id} onClick={handleClick} className={`tabbed-btn ${activeTab === id ? "active" : ""}`}>
      { title }
    </li>
  );
};
export default TabNavItem;