import React from 'react';

import icon from './../../assets/icons/logo.svg';
import HoursToTime from '../../utils/HoursToTime';
import Avatar from '../common/Avatar';

function TasksTimeline({ tasks }) {
  return (
    <div className='timeline-outer-wrp'>
      <div className='timeline-box'>
      {tasks.map(task => (
        <div key={task.parentTask.id} className='timeline-item'>
          <div className='image-wrp'>
            <Avatar
                src={task.parentTask.owner.profile_picture ? `${process.env.REACT_APP_BACKEND_URL}/${task.parentTask.owner.profile_picture}` : icon}
                alt={task.parentTask.id}
                className="avatar-small"
                name={task.parentTask.owner.name}
            />
          </div>
          <div className='content-wrp d-flex flex-column'>
            <span className='title'>
              {task.parentTask.task_name}
            </span>
            {/* <div className='description'>
              {task.parentTask.task_description}
            </div> */}
            <span className='tracked-time'>Tracked Time: {HoursToTime(task.parentTask.total_spent_time)}</span>
          </div>
        </div>
      ))}
      </div>
    </div>
  )
}

export default TasksTimeline