import React from 'react';
import { Tooltip } from "react-tooltip";
import { v4 } from "uuid";

function LogoTitle({ logoSrc, logoAlt, logoName, title, className }) {
  const uniqueId = v4();
  return (
    <div className={`logo-title-wrp ${className? className : ''}`}>
      <div className="logo-wrp border">
          <img
            src={logoSrc}
            alt={logoAlt}
            data-tooltip-id={uniqueId}
          />
          <Tooltip id={uniqueId}>{logoName}</Tooltip>
      </div>
      { title &&
        <div className="title-wrp">
          {title}
        </div>
      }
    </div>
  )
}

export default LogoTitle