import React from 'react';
import icon from './../../assets/icons/logo.svg';
import Avatar from '../common/Avatar';

function CardHeader({ logo, name, phases, categories, status, priority }) {
  return (
    <div className='card-header'>
      <div className='project-logo'>
        <Avatar
          className=""
          src={logo ? `${process.env.REACT_APP_BACKEND_URL}/${logo}` : icon}
          alt={name}
        />
      </div>
      <div className='project-content'>
        <div className='project-name large-para ex-bold'>{ name }</div>
        <div className='tagline-wrp'>
          {categories.length > 0 && <div className='categories-wrp tagline'>{ categories.map(category => <span id={category.id} className='category-item status yellow ex-small'>{category.label}</span>) }</div>}
          {phases.length > 0 && <div className='phases-wrp tagline'>{ phases.map(phase => <span id={phase.id} className='phase-item status yellow ex-small'>{phase.label}</span>) }</div>}
          {status && <span className={`status-item status ex-small ${status.color}`}>{ status.name }</span>}
          {priority && <span className={`status-item status ex-small ${priority.color}`}>{ priority.name }</span>}
        </div>
      </div>
    </div>
  )
}

export default CardHeader