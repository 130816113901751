import React from 'react';
import ProgressBar from "@ramonak/react-progress-bar";

function CardProgress({ progressLabel, progressPercentage }) {
  return (
    <div className='card-progressbar'>
      <div className='label-wrp'>
        <div className='tasks'>Tasks</div>
          { progressLabel && <div className='progress-tasks'>{progressLabel}</div> }
        </div>
      <div className='progressbar-wrp'>
        <ProgressBar
          completed={Number.isNaN(parseInt(progressPercentage)) ? 0 : parseInt(progressPercentage)}
          className="progress-wrp"
          // barContainerClassName="container"
          // completedClassName="barCompleted"
          // labelClassName="label"
        />
      </div>
    </div>
  )
}

export default CardProgress