/* eslint-disable */
import React, {useState} from 'react';
import Select from 'react-select';
import Header from '../../layouts/Header';
import RightSideBar from '../../layouts/RightSideBar';
import Avatar from '../common/Avatar';

import flagRed from './../../assets/icons/flag-red.svg';
import flagGreen from './../../assets/icons/flag-green.svg';
import flagBlue from './../../assets/icons/flag-blue.svg';
import flagYellow from './../../assets/icons/flag-yellow.svg';
import logo from './../../assets/icons/logo.svg';

const colourOptions = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];

function Styleguide() {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  return (
    <>
      <Header title="Style Guide" />
      <div className='main-content has-right-sidebar'>
        <div className='middle-content'>
          <div className='middle-content-inner-wrp'>
            <h1>Heading</h1>
            <h2>Sub Heading</h2>
            <h5>Separator Heading</h5>

            <div className='paragraph-text'>
              <p>Bees started as a time tracking application and slowly groomed into a simple project management tool. We are adding features slowly to fit with our project management process.</p>
              <p>Bees started as a time tracking application and slowly groomed into a simple project management tool. We are adding features slowly to fit with our project management process.</p>
              <p>Bees started as a time tracking application and slowly groomed into a simple project management tool. We are adding features slowly to fit with our project management process.</p>
            </div>
            <div className='buttons-wrp'>
              <a href="#22" className='btn btn-primary'>Button Text</a>
              <br/>
              <br/>
              <button className='btn btn-primary'>Button Text</button>
              <br/>
              <br/>
              <a href="#22" className='btn btn-secondary'>Button Text</a>
              <br/>
              <br/>
              <a href="#22" className='btn btn-outline'>Button Text</a>
              <br/>
              <br/>

              <div className='colored-btn-wrp'>
                <a href="#22" className='colored-btn success'>Green Button</a>
                <br/>
                <br/>
                <a href="#22" className='colored-btn gentle'>Gentle Button</a>
                <br/>
                <br/>
                <a href="#22" className='colored-btn warning'>Warning Button</a>
                <br/>
                <br/>
                <a href="#22" className='colored-btn success with-icon'>Warning Button</a>
                <br/>
                <br/>
                {/* <a href="#22" className='colored-btn warning with-icon only-icon'></a> */}
                <button className='colored-btn warning with-icon only-icon'></button>
              </div>
            </div>
              <br/>
              <br/>
            <div className='paragraph-text'>
              <p>This is general paragraph and <a href="#link">with a link</a> that will be displayed here.</p>
            </div>
            <div className='status-wrp'>
              <div className='row'>
                <div className='col-6'>
                  <span className='status general'>General Label</span><br/><br/>
                  <span className='status gentle'>Gentle Label</span><br/><br/>
                  <span className='status warning'>Warning Label</span><br/><br/>
                  <span className='status danger'>Danger Label</span><br/><br/>
                  <span className='status success'>Green Label</span><br/><br/><br/>
                </div>
                <div className='col-6'>
                  <span className='status general small'>General Label</span><br/><br/>
                  <span className='status gentle small'>Gentle Label</span><br/><br/>
                  <span className='status warning small'>Warning Label</span><br/><br/>
                  <span className='status danger small'>Danger Label</span><br/><br/>
                  <span className='status success small'>Green Label</span><br/><br/>
                </div>
              </div>
            </div>

            <div className='notification-wrp'>
              <div className='notification online'>
                We are online
              </div>
              <br/><br/>
              <div className='notification sign-off'>
                We are about to sign off
              </div>
              <br/><br/>
              <div className='notification offline'>
                We are offline
              </div>
              <br/><br/>
              <div className='notification'>
                Good morning! We will be online soon
              </div>
            </div>
            <br/><br/>
            <div className=''>
              <ul className='tabbed-btn-wrp'>
                <li className='tabbed-btn active'>All Tasks</li>
                <li className='tabbed-btn'>Not Started</li>
                <li className='tabbed-btn'>Completed</li>
              </ul>
            </div>
            <br/><br/>
            <br/><br/>
            <div className='form-wrp'>
              <form>
                <div className="form-row">
                  <div className="form-group col col-md-6">
                    <label for="inputEmail4">Email</label>
                    <input type="email" className="form-control" id="inputEmail4" placeholder="Email" disabled/>
                  </div>
                  <div className="form-group col col-md-6">
                    <label for="inputPassword4">Password</label>
                    <input type="password" className="form-control" id="inputPassword4" placeholder="Password"/>
                  </div>
                </div>
                <div className="form-group">
                  <label for="inputAddress">Address</label>
                  <input type="text" className="form-control" id="inputAddress" placeholder="1234 Main St"/>
                </div>
                <div className="form-group">
                  <label for="inputAddress2">Address 2</label>
                  <input type="text" className="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor"/>
                </div>
                <div className="form-row">
                  <div className="form-group col col-md-4">
                    <label for="inputState">State</label>

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={colourOptions[0]}
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="color"
                      options={colourOptions}
                      // isMulti
                    />

                  </div>
                  <div className="form-group col col-md-6">
                    <label for="inputCity">City</label>
                    <input type="text" className="form-control" id="inputCity"/>
                  </div>
                  <div className="form-group col col-md-2">
                    <label for="inputZip">Zip</label>
                    <input type="text" className="form-control" id="inputZip"/>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="gridCheck"/>
                    <label className="form-check-label" for="gridCheck">
                    HTML/CSS(SASS)
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="gridCheck1"/>
                    <label className="form-check-label" for="gridCheck1">
                      Javascript
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="gridCheck2"/>
                    <label className="form-check-label" for="gridCheck2">
                      PHP & MySQL
                    </label>
                  </div>
                  <div className="form-check disabled">
                    <input className="form-check-input" type="checkbox" id="gridCheck3" disabled/>
                    <label className="form-check-label" for="gridCheck3">
                      PHP & MySQL
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-radio">
                    <input className="form-check-radio" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                    <label className="form-check-label" for="exampleRadios1">
                      Default radio
                    </label>
                  </div>
                  <div className="form-radio">
                    <input className="form-check-radio" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" />
                    <label className="form-check-label" for="exampleRadios2">
                      Second default radio
                    </label>
                  </div>
                  <div className="form-radio disabled">
                    <input className="form-check-radio" type="radio" name="exampleRadios" id="exampleRadios3" value="option3" disabled />
                    <label className="form-check-label" for="exampleRadios3">
                      Disabled radio
                    </label>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='toggle-btn-wrp'>
                    <label className="switch">
                      <input type="checkbox"/>
                      <span className="slider"></span>
                    </label>
                    <br/><br/>
                    <label className="switch round">
                      <input type="checkbox"/>
                      <span className="slider"></span>
                    </label>
                    <br/><br/>
                    <label className="switch round disabled">
                      <input type="checkbox" disabled/>
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">Sign in</button>
              </form>
            </div>
            <br/><br/>
            <br/><br/>
            <div className='tasks-wrp'>
              <div className='tasks-items-wrp'>
                <div className="accordion" id="task-group">
                  <div className='tasks-heading-wrp'>
                    <div className='main-title-wrp'>
                      <div className='task-name'>Support Tasks</div>
                    </div>
                    <div className='sub-title-wrp'>
                      <div className='assignee'>Assignee</div>
                      <div className='due-date'>Due Date</div>
                      <div className='status-wrp'>Status</div>
                      <div className='estimate'>Estimate</div>
                      <div className='spent'>Spent</div>
                      <div className='priority'>Priority</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOne1">
                      <div className='task-header' data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                        <div className='task-content-wrp'>
                          <span className='accordion-icon'></span>
                          <div className='task-name-wrp'>
                            <div className='task-name'>Website speed performance</div>
                            <div className='btn-wrp'>
                              <a href="https://fonts.google.com/" target="_blank" rel="noopener noreferrer" className='btn btn-table'>View Task</a>
                            </div>
                          </div>
                          <div className='task-detail-wrp'>
                            <div className='assignee'>
                              <div className='image-wrp'>
                                <div className='image-item'>
                                  <img src={logo} alt="" />
                                </div>
                                <div className='image-item'>
                                  <img src={logo} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className='due-date'>20th June 2023</div>
                            <div className='status-wrp'>
                              <span className="status danger small">Not started</span>
                            </div>
                            <div className='estimate'>5h : 30m</div>
                            <div className='spent'>3h : 30m</div>
                            <div className='priority'>
                              <img src={flagRed} alt="priority" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="collapseOne1" className="accordion-collapse collapse show" aria-labelledby="headingOne1" data-bs-parent="#task-group">
                      <div className="accordion-body">
                        <div className='task-content-wrp'>
                          <span className='accordion-icon'></span>
                          <div className='task-name-wrp'>
                            <div className='task-name'>Website speed performance</div>
                          </div>
                          <div className='task-detail-wrp'>
                            <div className='assignee'>
                              <div className='image-wrp'>
                                <div className='image-item'>
                                  <img src={logo} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className='due-date'>20th June 2023</div>
                            <div className='status-wrp'>
                              <span className="status gentle small">In-Progress</span>
                            </div>
                            <div className='estimate'>5h : 30m</div>
                            <div className='spent'>3h : 30m</div>
                            <div className='priority'>
                              <img src={flagBlue} alt="priority" />
                            </div>
                          </div>
                        </div>
                        <div className='task-content-wrp'>
                          <span className='accordion-icon'></span>
                          <div className='task-name-wrp'>
                            <div className='task-name'>Website speed performance</div>
                          </div>
                          <div className='task-detail-wrp'>
                            <div className='assignee'>
                              <div className='image-wrp'>
                                <div className='image-item'>
                                  <img src={logo} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className='due-date'>20th June 2023</div>
                            <div className='status-wrp'>
                              <span className="status success small">Green Label</span>
                            </div>
                            <div className='estimate'>5h : 30m</div>
                            <div className='spent'>3h : 30m</div>
                            <div className='priority'>
                              <img src={flagGreen} alt="priority" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOne2">
                      <div className='task-header collapsed' data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                        <div className='task-content-wrp'>
                          <span className='accordion-icon'></span>
                          <div className='task-name-wrp'>
                            <div className='task-name'>Website speed performance</div>
                            <div className='btn-wrp'>
                              <a href="https://fonts.google.com/" target="_blank" rel="noopener noreferrer" className='btn btn-table'>View Task</a>
                            </div>
                          </div>
                          <div className='task-detail-wrp'>
                            <div className='assignee'>
                              <div className='image-wrp'>
                                <div className='image-item'>
                                  <img src={logo} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className='due-date'>20th June 2023</div>
                            <div className='status-wrp'>
                              <span className="status danger small">Not started</span>
                            </div>
                            <div className='estimate'>5h : 30m</div>
                            <div className='spent'>3h : 30m</div>
                            <div className='priority'>
                              <img src={flagYellow} alt="priority" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="collapseOne2" className="accordion-collapse collapse" aria-labelledby="headingOne2" data-bs-parent="#task-group">
                      <div className="accordion-body">
                        <div className='task-content-wrp'>
                          <span className='accordion-icon'></span>
                          <div className='task-name-wrp'>
                            <div className='task-name'>Website speed performance</div>
                          </div>
                          <div className='task-detail-wrp'>
                            <div className='assignee'>
                              <div className='image-wrp'>
                                <div className='image-item'>
                                  <img src={logo} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className='due-date'>20th June 2023</div>
                            <div className='status-wrp'>
                              <span className="status gentle small">In-Progress</span>
                            </div>
                            <div className='estimate'>5h : 30m</div>
                            <div className='spent'>3h : 30m</div>
                            <div className='priority'>
                              <img src={flagBlue} alt="priority" />
                            </div>
                          </div>
                        </div>
                        <div className='task-content-wrp'>
                          <span className='accordion-icon'></span>
                          <div className='task-name-wrp'>
                            <div className='task-name'>Website speed performance</div>
                          </div>
                          <div className='task-detail-wrp'>
                            <div className='assignee'>
                              <div className='image-wrp'>
                                <div className='image-item'>
                                  <img src={logo} alt="" />
                                </div>
                              </div>
                            </div>
                            <div className='due-date'>20th June 2023</div>
                            <div className='status-wrp'>
                              <span className="status success small">Green Label</span>
                            </div>
                            <div className='estimate'>5h : 30m</div>
                            <div className='spent'>3h : 30m</div>
                            <div className='priority'>
                              <img src={flagGreen} alt="priority" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br/><br/>
            <div className='d-flex'>
              <div className='mx-3'>
                <div className='mb-1'>Default</div>
                  <Avatar
                    src={logo}
                    alt="logo"
                  />
              </div>
              <div className='mx-3'>
                <div className='mb-1'>Large</div>
                <Avatar
                  src={logo}
                  alt="logo"
                  className="avatar-large"
                />
              </div>
              <div className='mx-3'>
                <div className='mb-1'>Small</div>
                <Avatar
                  src={logo}
                  alt="logo"
                  className="avatar-small"
                />
              </div>
            </div>
            <br/><br/><br/><br/>
            <br/><br/>
          </div>
        </div>
        <RightSideBar />
      </div>
    </>
  )
}

export default Styleguide