import React from 'react';
import { Link } from 'react-router-dom';
import CardHeader from './CardHeader';
import CardStats from './CardStats';
import CardProgress from './CardProgress';
import CardFooter from './CardFooter';

function ProjectCard({ project, phases, categories, totalSpent, totalEstimate, daysLeft, totalTasks, totalCompletedTasks }) {
  return (
    <Link to={`/view-project/${project.id}`} key={project.id} className='card'>
      <CardHeader
        logo={project.logo}
        name={project.name}
        phases={phases}
        categories={categories}
        status={project.project_status}
        priority={project.priority}
      />
      <CardStats
        totalSpent={totalSpent}
        totalEstimate={totalEstimate}
        daysLeft={daysLeft} 
      />
      <CardProgress
        progressLabel={`${totalCompletedTasks}/${totalTasks} Completed`}
        progressPercentage={`${((totalCompletedTasks/totalTasks)*100).toFixed(0)}`}
      />
      {project.project_manager &&
        <CardFooter
          userPhoto={project.project_manager.profile_picture}
          userName={project.project_manager.name}
        />
      }
    </Link>
  )
}

export default ProjectCard